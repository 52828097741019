import {
  actionTypes
} from '../constants/actionTypes';
import telephoneNumberActions from "../constants/telephoneNumberType";

const initialState = {
    currentQuotation: {
        id: 0,
        name: '',
        customer: null,
        customerData: null,
        contractDuration: '',
        connectCost: '',
        installationCost: '',
        oneTimeCost: '',
        projectManagamentCost: '',
        consultancyCost: '',
        migrationCost: '',
        materialCost: '',
        remainingCost: '',
        flight_case: '',
        flight_case_amount: 0,
        opportunityNumber: '',
        opportunityName: '',
        bendVerkoopkansId: '',
        crmNumber: '',
        ckrNumber: '',
        mobilePerLocation: false,
        mobileNeeded: false,
        usingExistingData: false,
        voicePerLocation: false,
        firstAgreement: false,
        secondAgreement: false,
        thirdAgreement: false,
        contactEmail: false,
        contactPhone: false,
        contactSms: false,
        contactNone: false,
        transferService: null,
        transferServiceReason: null,
        provider: 0,
        quotationValidity: null,
        specificValidityDate: null,
        useTechnicalContactForOrder: null,
        enabledModules: [],
        IRMACredentials: [],
        companySource: null,
        isSentToGrexx: false,
        inputsDisabled: false,
        isApiMade: false,
        isWebLead: false,
        fortiGateActive: false,
        isExpired: false,
        accessPortfolio: '',
        upgradingData: [],
        hostedVoiceOptions: {
            location: null,
            callChargeType: 0,
            callMeNow: 0,
            departmentVoicemail: 0,
            faxToEmail: 0,
            hostedVoiceActive: false,
            businessConnectActive: false,
            id: 0,
            interactiveVoiceRespond: 0,
            publicSwitches: 0,
            userTool: 0,
            dayNightSchedule: 0,
            voiceMail: 0,
            app: 0,
            fixedUserOption1: 0,
            fixedUserOption2: 0,
            fixedUserOption3: 0,
            fixedUserOption4: 0,
            fixedUserOption5: 0,
            receptionistOption1: 0,
            receptionistOption2: 0,
            receptionistOption3: 0,
            receptionistOption4: 0,
            crmOption1: 0,
            crmOption2: 0,
            crmOption3: 0,
            crmOption4: 0,
            crmOption5: 0,
            crmOption6: 0,
            crmOption7: 0,
            crmCaraSoloOption1: 0,
            crmCaraSoloOption2: 0,
            crmCaraSoloOption3: 0,
            centerOption1: 0,
            centerOption2: 0,
            centerOption3: 0,
            centerOption4: 0,
            endPointLicenses: 0,
            endPointLicenseType: 0,
            endpointLicenceOption1: 0,
            endpointLicenceOption2: 0,
            endpointLicenceOption3: 0,
            myConsole: 0,
            contactCenterAgent: 0,
            usersPerPublicSwitch: 0,
            numbers: [
                {
                    numberType: 0,
                    blocks: 0,
                    existing: false,
                    count: 1,
                }
            ],
            officeRoleCount: 0,
            fixedMobileRoleCount: 0,
            anywhereRoleCount: 0,
            p2gUsersCount: 0,
            unlimitedUsersCount: 0,
            teamsCall: 0
        },
        telephonyOptions: {
            id: 0,
            wallboard: 0,
            faxToEmail: 0,
            reportingLight: 0,
            reportingPremium: 0,
            adConnect: 0,
            acdUsers: 0,
            acdLightOfPremium: 0,
            huntGroups: 0,
            crmUsers: 0,
            audioConferenceNumbers: 0,
            extraConferenceParticipants: 0,
            ivr: 0,
            ivrLayers: 0,
            allInReceptionAgents: 0,
            numbers: [
                {
                    numberType: 0,
                    blocks: 0,
                    existing: false,
                    count: 1,
                }
            ],
        },
        communicationOptions: {
            id: 0,
            communicationActive: 0,
            solution: 0,
            tetra: 0,
            dmr: 0,
            pushToTalk: 0,
            nexedge: 0,
            hyteraUsers: 0,
            hyteraPurchaseType: 0,
            newSites: 0,
            existingSites: 0,
            conversationEavesdropping: 0,
            analogBoot: 0,
            xptActive: 0,
            xptSemiTrunks: 0,
            dmrTier3Upgrade: 0,
            dmrTier3Trunks: 0,
            multipleSites: 0,
            ipAddresses: 0
        },
        hostedVoiceOptionsAllLocations: [],
        mobileOptions: {
            basic: 0,
            benefitCountriesInternet: 0,
            budget: 0,
            businessMobileEu: 0,
            businessMobileOutsideEu: 0,
            countriesOutsideEu: 0,
            dataOnlyStandard: 0,
            dataOnlyPremium: 0,
            dataOnlyPremiumPlus: 0,
            enabled: false,
            fixedIp: 0,
            instap: 0,
            premium: 0,
            premiumPlus: 0,
            standard: 0,
        },
        sdLanOptions: {
            isMerakiEnabled: false,
            isUbiquitiEnabled: false,
            licenceLength: 0,
        },
        mobileOfferYear: null,
        mobileType: null,
        mobileDataType: null,
        mobileContractDuration: '',
        telephone_numbers: [],
        report: '',
        dealName: '',
        dealId: '',
        dealContractLength: '',
        isCustomerOrderApi: false,
        hasActiveAddons: true,
        hasActiveEndDateOptions: false,
        salesValueMessage: '',
    },
    allQuotations: [],
    xml: '',
    checklist: '',
    kwtXls: '',
    dealSheet: '',
    locationsXls: '',
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    mobileBulkExampleFile: '',
    fixedBulkExampleFile: '',
    dynamicsData: [],
    sendEmailToCustomer: [],
    overviewData: null,
    isLead: false,
    isSyncOpportunityEmail: true
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.createNewQuotation:
            return {
                ...state,
                currentQuotation: action.payload
            };
        case actionTypes.setSelectedCustomer:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    customer: action.payload.id
                }
            }
        case actionTypes.getQuotationSuccess:
            const quotation = action.payload;
            if (quotation.customer) {
                quotation.customerData = quotation.customer;
                quotation.customer = quotation.customer.id;
            }
            if (quotation.hostedVoiceOptions) {
                quotation.hostedVoiceOptionsAllLocations = quotation.hostedVoiceOptions;
                quotation.hostedVoiceOptions = quotation.hostedVoiceOptionsAllLocations.find((each) => {return !each.location;});
            }
            const isLeadType = quotation.isLeadType;

            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    ...quotation
                },
                isLead: isLeadType
            };
        case actionTypes.getQuotationChangesSuccess:
            return {
             ...state,
            currentQuotation: {
               ...state.currentQuotation,
               changes: action.payload
            }
        }
        case actionTypes.setQuotationId:
            return {
                ...state,
                currentQuotation: {
                    id: action.payload
                }
            }
        case actionTypes.updateInCampusSuccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    inCampus: action.payload,
                }
            }
        case actionTypes.updateHasActiveAddons:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    hasActiveAddons: action.payload,
                }
            }
        case actionTypes.updateHasActiveEndDateOptions:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    hasActiveEndDateOptions: action.payload,
                }
            }
        case actionTypes.updateQuotationProperty:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    [action.payload.propertyToChange]: action.payload.newValue,
                }
            }
        case actionTypes.setOrdering:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    ordering: action.payload,
                }
            }
        case actionTypes.getAllQuotations:
            return {
                ...state,
                allQuotations: action.payload
            };
        case actionTypes.getPhoneNumbers:
            return {
                ...state,
                existingMobileData: action.payload
            };
        case actionTypes.updatePhoneNumber:
            return {
                ...state,
                phoneNumbers: action.payload
            };
        case actionTypes.updatePhoneNumberActionStatus:
            let ordersToUpdate = action.payload.map((order) => parseInt(order));
            let updatedTelephoneNumbers = [...state.currentQuotation.telephone_numbers].map((telephoneNumber) => {
                if (ordersToUpdate.includes(telephoneNumber.orderId)) {
                    telephoneNumber['action'] = telephoneNumberActions.UPGRADE_ACTION;
                    telephoneNumber['hasUpgradeDataRetrieved'] = true;
                }

                return telephoneNumber;
            });

            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephone_numbers: updatedTelephoneNumbers
                }
            };
        case actionTypes.updateAccessProducts:
            return {
              ...state,
              accessProducts: action.payload
            };
        case actionTypes.getAccessProducts:
            return {
                ...state,
                existingAccessData: action.payload
            };
        case actionTypes.updateVoiceProducts:
            return {
              ...state,
              voiceProducts: action.payload
            };
        case actionTypes.getVoiceProducts:
            return {
              ...state,
              existingVoiceData: action.payload
            };
        case actionTypes.getUpgradingData:
            return {
              ...state,
              upgradingData: action.payload
            };
        case actionTypes.deleteQuotation:
            return {
                ...state,
                allQuotations: state.allQuotations.filter( quotation => quotation.id !== action.payload.id )
            };
        case actionTypes.getXML:
            return {
              ...state,
              xml: action.payload
            };
        case actionTypes.getQuotationLocationsFile:
            return {
                ...state,
                locationsXls: action.payload
            };
        case actionTypes.getWord:
            return {
                ...state,
                word: action.payload
            };
        case actionTypes.getDealSheet:
            return {
                ...state,
                dealSheet: action.payload
            };
        case actionTypes.getChecklist:
            return {
              ...state,
              checklist: action.payload
            };
        case actionTypes.getKwtXls:
            return {
                ...state,
                kwtXls: action.payload
            };
        case actionTypes.updateHostedVoiceOptions:
            const newHostedVoiceOptions = action.payload.hostedVoiceOptions;
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    hostedVoiceOptions: {
                        ...newHostedVoiceOptions
                    },
                    hostedVoiceOptionsAllLocations: state.currentQuotation.hostedVoiceOptionsAllLocations.map((content) => {
                        if (content.id === newHostedVoiceOptions.id) {
                            return newHostedVoiceOptions;
                        }
                        return content;
                    })
                }
            };
        case actionTypes.updateTelephonyOptions:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephonyOptions: {
                        ...action.payload.telephonyOptions
                    }
                }
            };
        case actionTypes.updateCommunicationOptions:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    communicationOptions: {
                        ...action.payload.communicationOptions
                    }
                }
            };
        case actionTypes.updateAsUnmangedAccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    access_selection: action.payload.access_selection,
                    flight_case: action.payload.flightCase,
                    flight_case_amount: action.payload.flightCaseAmount
                }
        };
        case actionTypes.updateMobileOptions:
            const {mobileOptions} = state.currentQuotation;
            mobileOptions[action.payload.name] = action.payload.value;

            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    mobileOptions: {
                        ...mobileOptions
                    }
                }
            };
        case actionTypes.updateSdLanOptions:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    sdLanOptions: {
                        ...action.payload.sdLanOptions
                    }
                }
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.updateCallChargeType.success:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    hostedVoiceOptions: {
                        ...state.currentQuotation.hostedVoiceOptions,
                        callChargeType: action.callChargeType
                    }
                }
            };
        case actionTypes.deleteAKBTelephoneNumberSuccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephone_numbers: state.currentQuotation.telephone_numbers.filter(phoneNumber =>
                        phoneNumber.id !== action.payload.id
                    )
                }
            };
        case actionTypes.saveAKBTelephoneNumberSuccess:
            const telephoneNumber = action.payload;
            let telephoneNumbers = [...state.currentQuotation.telephone_numbers];

            const foundIndex = telephoneNumbers.findIndex((elem) => elem.id === telephoneNumber.id);
            if (foundIndex !== -1) {
                let vamoOptions = telephoneNumbers[foundIndex].hasOwnProperty('vamoOptions') ? telephoneNumbers[foundIndex].vamoOptions : [];

                telephoneNumbers[foundIndex] = {...telephoneNumber};
                telephoneNumbers[foundIndex].vamoOptions = vamoOptions;
            } else {
                telephoneNumbers.push(telephoneNumber);
            }

            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephone_numbers: telephoneNumbers
                }
            };
        case actionTypes.saveAllAKBTelephoneNumbersSuccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephone_numbers: action.payload
                }
            };
        case actionTypes.bulkImportSuccess:
        case actionTypes.uploadAKBSuccess:
        case actionTypes.getTelephoneNumbersSuccess:
        case actionTypes.toggleFlagForAllAKBNumbersSuccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    telephone_numbers: action.payload
                }
            };
        case actionTypes.updateQuotationSuccess:
            return {
                ...state,
                currentQuotation: action.quotation
            };
        case actionTypes.getMobileBulkExampleFile:
            return {
                ...state,
                mobileBulkExampleFile: action.data
            };
        case actionTypes.getVoiceBulkExampleFile:
            return {
                ...state,
                fixedBulkExampleFile: action.data
            };
        case actionTypes.sendDynamicsDataSuccess:
            return {
                ...state,
                dynamicsData: action.payload
            };
        case actionTypes.sendEmailToCustomerSuccess:
            return {
                ...state,
                sendEmailToCustomer: action.payload,
            };
        case actionTypes.setSyncStatusSuccess:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    syncStatus: action.payload
                }
            }
        case actionTypes.setSalesValueMessage:
            return {
                ...state,
                currentQuotation: {
                    ...state.currentQuotation,
                    salesValueMessage: action.payload
                }
            }
        case actionTypes.updateOverviewData:
            return {
                ...state,
                overviewData: action.payload
            };
        case actionTypes.updateIsLead:
            return {
                ...state,
                isLead: action.payload
            };
        case actionTypes.setIsSyncOpportunityEmail:
            return {
                ...state,
                isSyncOpportunityEmail: action.payload,
            }
        default:
            return state;
    }
}
