import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { cloneDeep } from 'lodash';

import {Col} from "react-materialize";
import {DelayInput} from "react-delay-input";

import quotationActions from '../actions/quotationActions';
import leadActions from '../actions/leadActions';
import infoActions from '../actions/workflow/infoStepActions';
import stepsAction from '../actions/stepsActions';
import adminActions from '../actions/adminActions';
import authenticationActions from "../actions/authenticationActions";

import Pagination from '../components/pagination/pagination';
import InternalTable from '../components/internalTable';
import DashboardDialogs from '../components/dashboardDialogs';
import Menu from "../components/menu";
import RegularSelect from "../components/RegularSelect";
import Navbar from "../components/navbar";

import ObjectHelper from '../services/objectHelper';
import RefreshQuotation from "./common/refreshQuotation";
import moment from "moment";
import ProviderHelper from "../helper/providerHelper";

import Organization from "../constants/organization";
import { statusData, statusDataWithoutOrdering } from "../constants/quotationStatus";
import crmConnections from "../constants/crmConnections";

class Dashboard extends Component {
  sortInitialState = {
    name: false,
    id: false,
    companyName: false,
    author: false,
    createdAt: false,
    updatedAt: false,
    status: false
  };

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: false,
      assignUser: null,
      isOrganizationColumnOpen: false,
      isNewOpened: false,
      isEditOpened: false,
      notLatestVersionWarningOpened: false,
      mostRecentVersion: 1,
      isDeleteOpened: false,
      isUndoOpened: false,
      isTakeOverOpened: false,
      isProjectManagerOpened: false,
      selectedRow: null,
      searchPhrase: '',
      searchInternalReference: '',
      statusFilter: null,
      offerTypeFilter: null,
      existsLeadOffer: false,
      allQuotations: [],
      sorting: {
        ...this.sortInitialState
      },
      page: 1,
      isLeadOverview: false,
      isWebLeadOverview: false,
    };
  };

  componentDidMount() {
    this.getAllQuotations();
    this.props.adminActions.getUsers();
    this.props.quotationActions.resetAlerts();
  };

  getAllQuotations = (sortField, direction, page, searchQuery, statusFilter, offerTypeFilter, searchInternalReference) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'page=' + page
    }
    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'searchQuery=' + searchQuery;
    }
    if (statusFilter) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'statusFilter=' + statusFilter;
    }
    if (offerTypeFilter) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'offerTypeFilter=' + offerTypeFilter;
    }

    if (searchInternalReference) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'internalReference=' + searchInternalReference;
    }

    if (this.state.isWebLeadOverview) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'webLeadOverview=' + true;
    }

    if (this.state.isLeadOverview) {
      this.props.leadActions.getAllLeads(queryParams).then(() => {
        this.setState({
          allQuotations: this.props.quotation.allQuotations
        });
      });
      return;
    }

    this.props.quotationActions.getAllQuotations(queryParams).then(() => {
      this.setState({
        allQuotations: this.props.quotation.allQuotations
      });

      if (!offerTypeFilter) {
        this.setState({
          existsLeadOffer: !!this.props.quotation.allQuotations.find((quotation) => {return quotation.lead})
        });
      }
    });
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false && x !== 'providerName');
    const sortValue = this.state.sorting[sortField];

    this.getAllQuotations(sortField, sortValue, value, this.state.searchPhrase, this.state.statusFilter);
  };

  createNewQuotation = (type, akbRequired, provider, quotationAccessPortfolio) => {
    let defaultQuotationName = null;
    const {isVenecoOrganization} = this.props.authentication;

    if (isVenecoOrganization) {
      let name = ProviderHelper.getQuotationProviderName(provider ? provider : 0);
      if (name === 'KPN') {
        name = 'KPN_EEN';
      } else if (name === 'Vodafone') {
        name = 'VF';
      } else if (name === 'Odido') {
        name = 'ODIDO';
      } else if (name === 'Venéco') {
        name = 'VNC';
      } else if (name === 'PHC') {
        name = 'PHC';
      } else if (name === 'Yielder') {
        name = 'YLD';
      }
      let initials = ''
      if (this.props.authentication.nameInitials) {
        initials = this.props.authentication.nameInitials.substring(0, 3)
      }
      defaultQuotationName = name + "_" + initials + "_"
        + moment().format("YYMMDD") + "_"
        + "v1.0";
    }

    if (this.state.isLeadOverview) {
      this.props.leadActions.createNewLead(provider, defaultQuotationName).then(
        () => {
          let createdLeadId = this.props.quotation.currentQuotation.id;
          this.props.history.push('/lead/' + createdLeadId);
        }
      );
      return;
    }

    this.props.quotationActions.createNewQuotation(type, akbRequired, provider, defaultQuotationName, quotationAccessPortfolio).then(
      () => {
        let createdQuotationId = this.props.quotation.currentQuotation.id;
        this.props.history.push('/quotation/' + createdQuotationId);
      }
    )
  };

  goToAdminPage = () => {
    this.props.history.push('/admin');
  };

  goToOrdersOverviewPage = () => {
    this.props.history.push('/orders');
  };

  toggleOrganizationColumns = () => {
    this.setState({isOrganizationColumnOpen: ! this.state.isOrganizationColumnOpen});
  };

  logout = () => {
    this.props.authenticationActions.logout();
  };

  getTakeOverAction = (row) => {
    let currentUser = this.props.authentication.name;
    let {author, previousUser} = row;

    if ((author === currentUser && ! previousUser) || previousUser === currentUser) {
      return;
    }

    if (this.props.authentication.roles.includes('ROLE_SALES_SUPPORT')) {
      if (! previousUser) {
        return (<a href="#" onClick={() => this.openDialog(row, 'isTakeOverOpened')}>
          <i className="small material-icons left themeprimarycolor">assignment_ind</i>
        </a>);
      } else {
        return (<a href="#" onClick={() => this.openDialog(row, 'isTakeOverOpened')}>
          <i className="small material-icons left themeprimarycolor">arrow_back</i>
        </a>);
      }
    }
  };

  getProjectManagerAction = (row) => {
    if (this.props.authentication.userInsight) {
      return (
        <a href="#" onClick={() => this.openDialog(row, 'isProjectManagerOpened')}>
          <i className="small material-icons left themeprimarycolor">face</i>
        </a>
      );
    }
  };

  userActionsAvaliable = (row) => {
    return this.props.authentication.roles.includes('ROLE_SALES_SUPPORT')
      || this.props.authentication.roles.includes('ROLE_ADMIN')
      || !row.isTakenOver;
  }

  getActionsColumnForTable = (row) => {
    return this.userActionsAvaliable(row) ? (
      <div>
        {row.deleted_at ?
          <a href="#" onClick={() => this.openDialog(row, 'isUndoOpened')}>
            <i className="small material-icons left themeprimarycolor">undo</i>
          </a> :
          <div>
            <a href="#" onClick={() => this.openDialog(row, 'isEditOpened')}>
              <i className="small material-icons left themeprimarycolor">edit</i>
            </a>

            <RefreshQuotation quotationId={row.id} renderAsButton={false} />
            <a href="#" onClick={() => this.openDialog(row, 'isDeleteOpened')}>
              <i className="small material-icons left themeprimarycolor">close</i>
            </a>
            {this.getTakeOverAction(row)}
            {this.getProjectManagerAction(row)}
          </div>
        }
      </div>
    ) : (
      <div>
        <i className="small material-icons left rood">assignment_ind</i>
        Overgenomen door {row.salesSupport}
      </div>
    );
  };

  goToQuotation = (row) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    let customer;

    if (row.customer) {
      customer = ObjectHelper.enrichCustomerWithAdditionalProperties(row.customer, quotationId);
      this.props.infoActions.setSelectedCustomerInRedux(customer)
    }

    this.props.infoActions.updateIsLead(this.state.isLeadOverview);

    if (this.state.isLeadOverview) {
      this.props.history.push(`/lead/${row.id}`);
    } else {
      this.props.history.push(`/quotation/${row.id}`);
    }
  };

  createNewQuotationVersion = (row, dontShowWarning, mostRecent) => {
    const {childrens} = row;

    const mostRecentVersion = childrens.length + 1;
    const mostRecentQuotation = childrens.slice(-1)[0];

    let childrenQuotationId = mostRecentQuotation ?
      (mostRecentQuotation.indexOf('/') > -1 ?  mostRecentQuotation.slice(0, mostRecentQuotation.indexOf('/')) : mostRecentQuotation) :
      mostRecentQuotation;
    let finalQuotationId = isNaN(parseInt(childrenQuotationId)) ? row.id : parseInt(childrenQuotationId);
    let idOfQuotation = mostRecent ? finalQuotationId : row.id;

    this.setState({loading: true});

    this.props.quotationActions.duplicateQuotation(idOfQuotation)
      .then((resp) => this.goToQuotation(resp))
      .then(() => this.setState({loading: false}));
  };

  onTakeOverClick = () => {
    this.closeDialog('isTakeOverOpened');
    const {selectedRow} = this.state;
    if (this.state.isLeadOverview) {
      this.props.leadActions.takeOverLead(selectedRow).then(() => this.getAllQuotations());
    } else {
      this.props.quotationActions.takeOverQuotation(selectedRow).then(() => this.getAllQuotations());
    }
  }

  onEditClick = () => {
    this.closeDialog('isEditOpened');
    let {selectedRow} = this.state; //selected quotation from table
  }

  onDeleteClick = () => {
    this.closeDialog('isDeleteOpened');
    let {selectedRow} = this.state; //selected quotation from table
    if (this.state.isLeadOverview) {
      this.props.leadActions.deleteLead(selectedRow).then(() => this.getAllQuotations());
    } else {
      this.props.quotationActions.deleteQuotation(selectedRow).then(() => this.getAllQuotations());
    }
  };

  onUndoClick = () => {
    this.closeDialog('isUndoOpened');
    let {selectedRow} = this.state; //selected quotation from table
    this.props.quotationActions.patchQuotation(selectedRow.id, { deletedAt: null }).then(() => {
      let item = this.props.quotation.allQuotations.find((item) => item.id === selectedRow.id);
      item.deleted_at = null;

      this.setState({
        allQuotations: this.props.quotation.allQuotations
      });
    });
  }

  openDialog = (row, dialogVisibilityDiscriminator) => {
    if (dialogVisibilityDiscriminator === 'isEditOpened' && !this.props.authentication.versioning) {
      this.goToQuotation(row);
      return;
    }

    this.setState({
      [dialogVisibilityDiscriminator]: true,
      selectedRow: row
    });
  }

  closeDialog = (dialogVisibilityDiscriminator) => {
    this.setState({
      [dialogVisibilityDiscriminator]: false,
      selectedRow: null,
      assignUser: null
    });
  }

  onSelectedRowChange = ({target}) => {
    let {name, value} = target;
    this.setState({
      selectedRow: {
        ...this.state.selectedRow,
        [name]: value
      }
    });
  }

  onSearch = ({target}) => {
    let {name, value} = target;
    const internalReference = name === 'internalReference' ? value : '';
    const searchPhrase = name === 'searchPhrase' ? value : '';

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchInternalReference: internalReference})
    this.setState({searchPhrase: searchPhrase});

    this.getAllQuotations(
      sortField,
      sortValue,
      this.state.page,
      searchPhrase,
      this.state.statusFilter,
      this.state.offerTypeFilter,
      internalReference
    );
  }

  onStatusFilter = (value) => {
    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({ statusFilter: value });

    this.getAllQuotations(sortField, sortValue, 1, this.state.searchPhrase, value, this.state.offerTypeFilter);
  };

  onOfferTypeFilter = (value) => {
    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({ offerTypeFilter: value });

    this.getAllQuotations(sortField, sortValue, 1, this.state.searchPhrase, this.state.statusFilter, value);
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllQuotations(propertyName, direction, this.state.page, this.state.searchPhrase, this.state.statusFilter)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  updateRowStatus = (value, id) => {
    this.props.quotationActions.patchQuotation(id, { status: Number(value) }).then(() => {
      let item = this.props.quotation.allQuotations.find((item) => item.id === id);
      item.status = value;

      this.setState({
        allQuotations: this.props.quotation.allQuotations
      });
    });
  };

  displayStatusInRow = (value, id) => {
    const item = this.props.quotation.allQuotations.find((item) => item.id === id);
    const STATUS_SEND_VALUE = 6;
    const STATUS_WON_VALUE = 1;
    const statusesValues = [STATUS_SEND_VALUE, STATUS_WON_VALUE];
    const isSentDigitalSigningOfferte = !!(statusesValues.includes(item?.status) && item?.sentAt);
    const filteredStatuses = statusDataWithoutOrdering.filter(status => statusesValues.includes(status.value));
    const statuses = isSentDigitalSigningOfferte ? filteredStatuses : statusDataWithoutOrdering;

    return (
      <Col s={12} className="input-field inner-addon">
        <RegularSelect
          name={"status-" + id}
          onChange={(e) => this.updateRowStatus(e.target.value, id)}
          value={value}
          multiple={false}
          disabled={item && (item.deleted_at || item.isApiMade)}
        >
          {statuses.map((el) => (
            <option value={el.value}>{el.label}</option>
          ))}
        </RegularSelect>
      </Col>
    );
  };

  renderProjectManagerListOptions = () => {
    const loggedUser = this.props.authentication.username;

    return this.props.users.filter((user) => user.username !== loggedUser).map((el) => {
      return <option
        value={el ? el.id : null}
        key={el.username}
        name="username">
        {el ? el.username : 'Selecteer een gebruiker'}
      </option>
    });
  };

  onProjectManagerChange = ({target}) => {
    this.setState({
      assignUser: target.value || null,
      selectedRow: {
        ...this.state.selectedRow,
        assignUser: target.value
      }
    });
  };

  onProjectManagerSubmit = () => {
    const {selectedRow} = this.state;

    this.closeDialog('isProjectManagerOpened');
    this.props.quotationActions.setProjectManagerQuotation(selectedRow).then(() => this.state.isLeadOverview ? '' : this.getAllQuotations())
  };

  switchWebLeadOverview = (value) => {
    this.setState({isWebLeadOverview: !value});
    let queryParams = '';

    if (!value) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'webLeadOverview=' + true;
    }

    this.props.quotationActions.getAllQuotations(queryParams).then(() => {
      this.setState({
        allQuotations: this.props.quotation.allQuotations
      });
    });
  };

  switchOfferType = (value) => {
    this.setState({isLeadOverview: !value});

    if (value) {
      this.props.quotationActions.getAllQuotations('').then(() => {
        this.setState({
          allQuotations: this.props.quotation.allQuotations
        });
      });
    } else {
      this.props.leadActions.getAllLeads('').then(() => {
        this.setState({
          allQuotations: this.props.quotation.allQuotations
        });
      });
    }
  };

  // tableHeaderNames = ["Naam offerte", 'Offerte ID', 'Deal ID', "Bedrijfsnaam", "Portfolio", "Door wie gemaakt", "Aangemaakt", "Gewijzigd", "Status", 'Verzonden', 'Behandelaar', 'Project manager', "Acties", 'VP'];
  tableHeaderNames = ["Naam offerte", 'Deal ID', "Bedrijfsnaam", "Portfolio", "Door wie gemaakt", "Aangemaakt", "Gewijzigd", "Status", 'Verzonden', 'Geldigheid offerte', 'Behandelaar', 'Project manager', "Acties", 'VP'];
  tableObjectProperties = ['name', 'dealId', 'companyName', 'providerName', 'author', 'createdAt', 'updatedAt', 'status', 'quotationSentAt', 'expirationDate', 'previousUser', 'projectManager', 'vp'];
  originalTableHeaderNames = cloneDeep(this.tableHeaderNames);
  originalTableObjectProperties = cloneDeep(this.tableObjectProperties);

  switchOrganization = async ({target}) => {
    const organizationId = target.value;
    await this.props.authenticationActions.switchOrganization(organizationId);
    const {switchOrganization} = this.props.authentication;

    if (switchOrganization) {
      window.location.reload();
    }
  }

  render() {
    let actions = this.getActionsColumnForTable;
    let {isOrganizationColumnOpen, isProjectManagerOpened, isNewOpened, isEditOpened, isDeleteOpened, isUndoOpened,
      isTakeOverOpened, selectedRow, searchPhrase, searchInternalReference, allQuotations,
      notLatestVersionWarningOpened, mostRecentVersion, isLeadOverview, isWebLeadOverview} = this.state;

    const { name, company, theme, providers, isSalesValue, crmConnection, allowLead, allowWebLead, ordering, useNina,
      isAdminFromDoceri, userInsight } = this.props.authentication;
    let organization = providers && providers.length ? providers[0].organization : null;
    let allowOrdersOverview = (ordering && useNina) || isAdminFromDoceri;
    const isPitOrganization = organization ? organization.name === Organization.PIT : false;
    const isExtensionPointsOn = providers && providers.length ? providers[0].organization.extension_points : false;

    if (providers && providers.length <= 1) {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'Portfolio');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'providerName');
    }

    if (this.props.authentication.userInsight && isOrganizationColumnOpen) {
      this.tableHeaderNames = this.originalTableHeaderNames;
      this.tableObjectProperties = this.originalTableObjectProperties;
    } else {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'Behandelaar' && el !== 'Project manager');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'previousUser' && el !== 'projectManager');
    }

    if (isOrganizationColumnOpen) {
      this.tableHeaderNames = this.originalTableHeaderNames;
      this.tableObjectProperties = this.originalTableObjectProperties;
    } else {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'Geldigheid offerte');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'expirationDate');
    }

    if (isExtensionPointsOn && isOrganizationColumnOpen) {
      this.tableHeaderNames = this.originalTableHeaderNames;
      this.tableObjectProperties = this.originalTableObjectProperties;
    } else {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'VP');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'vp');
    }
  
    const excludedHeaderNames = ['Behandelaar', 'Project manager', 'VP', 'Geldigheid offerte'];
    const excludedObjectProperties = ['previousUser', 'projectManager', 'vp', 'expirationDate'];

    if (isOrganizationColumnOpen) {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'Aangemaakt');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'createdAt');
    } else {
      this.tableHeaderNames = this.originalTableHeaderNames.filter(el => !excludedHeaderNames.includes(el));
      this.tableObjectProperties = this.originalTableObjectProperties.filter(el => !excludedObjectProperties.includes(el));
    }
  
    if (crmConnection !== crmConnections.PIPEDRIVE) {
      this.tableHeaderNames = this.tableHeaderNames.filter(el => el !== 'Deal ID');
      this.tableObjectProperties = this.tableObjectProperties.filter(el => el !== 'dealId');
    }

    let overviewTitle = 'Offerte overzicht';
    if (this.state.isLeadOverview) {
      overviewTitle = 'Lead overzicht';
    }
    if (this.state.isWebLeadOverview) {
      overviewTitle = 'WebLead overzicht';
    }

    return (
      <div>
        <Navbar
          onAdminClick={this.goToAdminPage}
          onLogoutClick={this.logout}
          alerts={this.props.alerts}
          switchableOrganizationList={this.props.authentication.switchableOrganizationList}
          switchOrganizationAction={this.switchOrganization}
          allowSwitch={this.props.authentication.allowSwitch}
        />
        <div className="valign-wrapper">
          <div className="valign">
            <div className="container maincontainer">
              <div>
                <div className="section">
                  <div className="row">
                    <Menu company={company} fullName={name} theme={theme} />
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <div id="Forms" className="col s12 ratiotable paddingbottomnone">
                        <div>
                          <div>
                            <h1><span className="ratio-section-title">{overviewTitle}</span></h1>
                          </div>
                        </div>
                      </div>
                      <div className="col s12 overviewkopbg ratiotable">
                        <div className="col s12 m7">
                          <div className="col s12 m3">
                            <div className="overviewkop">
                              <form>
                                <div className="input-field margingone inner-addon">
                                  <i className="glyphicon tiny material-icons left lightgrey">search</i>
                                  <DelayInput
                                    delayTimeout={800}
                                    id="search"
                                    type="search"
                                    name="searchPhrase"
                                    value={searchPhrase}
                                    onChange={this.onSearch}
                                  />
                                </div>
                              </form>
                            </div>
                          </div>

                          <div className="col s12 m3">
                            <div className="overviewkop">
                              <form>
                                <div className="input-field margingone inner-addon">
                                  <RegularSelect
                                    name={"status-filter"}
                                    onChange={(e) => this.onStatusFilter(e.target.value)}
                                    value={this.state.statusFilter}
                                    multiple={false}
                                  >
                                    <option value="">Filter op status</option>

                                    {statusData.map((el) => (
                                      <option value={el.value}>{el.label}</option>
                                    ))}
                                  </RegularSelect>
                                </div>
                              </form>
                            </div>
                          </div>

                          {!isSalesValue &&
                            <div className="col s12 m3">
                              <div className="overviewkop">
                                <form>
                                  <div className="input-field margingone inner-addon">
                                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                                    <DelayInput
                                      delayTimeout={1500}
                                      id="internalReference"
                                      type="search"
                                      name="internalReference"
                                      value={searchInternalReference}
                                      onChange={this.onSearch}
                                      placeholder="Zoeken op interne referentie"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          }

                          <div className="col s12 m3">
                            <div className="overviewkop">
                              {this.state.existsLeadOffer &&
                                <form>
                                  <div className="input-field margingone inner-addon">
                                    <RegularSelect
                                      name={"status-filter"}
                                      onChange={(e) => this.onOfferTypeFilter(e.target.value)}
                                      value={this.state.offerTypeFilter}
                                      multiple={false}
                                    >
                                      <option value="">Filter op type offerte</option>
                                      <option value="0">Offerte</option>
                                      <option value="1">Lead</option>
                                    </RegularSelect>
                                  </div>
                                </form>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="col s12 m5">
                          <div className="overviewkop quotation-btns-inline">
                            {allowLead &&
                              <button
                                className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                                onClick={() => this.switchOfferType(isLeadOverview)}>
                                <i className="small material-icons left white-text">{isLeadOverview ? 'swap_vert' : 'free_breakfast'}</i>
                                {isLeadOverview ? 'Offerte overzicht': 'Leads'}
                              </button>
                            }

                            {allowWebLead &&
                              <button
                                className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                                onClick={() => this.switchWebLeadOverview(isWebLeadOverview)}>
                                <i className="small material-icons left white-text">swap_vert</i>
                                {isWebLeadOverview ? 'Offerte overzicht': 'WebLeads'}
                              </button>
                            }

                            {allowOrdersOverview &&
                              <button
                                  className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                                  onClick={() => this.goToOrdersOverviewPage()}>
                                <i className="small material-icons left white-text">timeline</i>
                                Order overzicht
                              </button>
                            }
                            {userInsight &&
                              <button
                                  className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                                  onClick={() => this.toggleOrganizationColumns()}>
                                <i className="small material-icons left white-text">subdirectory_arrow_right</i>
                                Lijst uitbreiden
                              </button>
                            }
                            <button
                                className="btn doceri-btn-right ratio-btn-right"
                                onClick={() => this.openDialog({}, 'isNewOpened')}
                                type="submit"
                                name="action">
                              <i className="small material-icons left white-text">add</i>
                              {isLeadOverview ? 'Nieuwe lead' : 'Nieuwe offerte'}
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      <InternalTable
                        headersNames={this.tableHeaderNames}
                        propertiesToShow={this.tableObjectProperties}
                        rows={allQuotations}
                        onHeaderClick={this.onSortChange}
                        sortingState={this.state.sorting}
                        actionsColumn={actions}
                        showOrganizationsColumn={isOrganizationColumnOpen}
                        displayStatusInRow={this.displayStatusInRow}
                        getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                      />
                      
                      {selectedRow &&
                        <DashboardDialogs
                          isNewOpened={isNewOpened}
                          isEditOpened={isEditOpened}
                          notLatestVersionWarningOpened={notLatestVersionWarningOpened}
                          mostRecentVersion={mostRecentVersion}
                          isDeleteOpened={isDeleteOpened}
                          isUndoOpened={isUndoOpened}
                          isTakeOverOpened={isTakeOverOpened}
                          isProjectManagerOpened={isProjectManagerOpened}
                          renderProjectManagerListOptions={this.renderProjectManagerListOptions}
                          onProjectManagerChange={this.onProjectManagerChange}
                          closeDialogFunc={this.closeDialog}
                          createNewQuotationFunc={this.createNewQuotation}
                          confirmDeletionFunc={this.onDeleteClick}
                          confirmUndoFunc={this.onUndoClick}
                          confirmEditionFunc={this.onEditClick}
                          confirmTakeOverFunc={this.onTakeOverClick}
                          confirmProjectManagerFunc={this.onProjectManagerSubmit}
                          selectedRow={selectedRow}
                          onSelectedRowChangeFunc={this.onSelectedRowChange}
                          isSalesValue={this.props.authentication.isSalesValue}
                          providers={this.props.authentication.providers}
                          assignUser={this.state.assignUser}
                          stepActions={this.props.stepsActions}
                          goToQuotationFunc={this.goToQuotation}
                          createNewQuotationVersionFunc={this.createNewQuotationVersion}
                          isPitOrganization={isPitOrganization}
                          authentication={this.props.authentication}
                          loading={this.state.loading}
                          isLeadOverview={isLeadOverview}
                        />
                      }
                    </div>
                    <div className="col s12 sendendform">
                      <div className="col s12 m10">
                        <Pagination page={this.props.quotation.currentPage}
                                    pages={this.props.quotation.pages}
                                    changePage={this.changePage}
                        />
                      </div>
                      <div className="col s12 m2">
                        <button className="btn doceri-btn-right ratio-btn-right"
                                onClick={() => this.openDialog({}, 'isNewOpened')}
                                type="submit"
                                name="action">
                          <i className="small material-icons left white-text">add</i>
                          {isLeadOverview ? 'Nieuwe lead' : 'Nieuwe offerte'}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({admin, customer, quotation, authentication, alerts}) => {
  return {
    users: admin.users,
    customer,
    quotation,
    authentication,
    alerts,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    leadActions: bindActionCreators(leadActions, dispatch),
    infoActions: bindActionCreators(infoActions, dispatch),
    stepsActions: bindActionCreators(stepsAction, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
