import { actionTypes } from '../constants/actionTypes';
import QuotationType from "../constants/quotationType";
import ProviderType from "../constants/providerType.js";
import OfferType from "../constants/offerType.js";

const { TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING ,TYPE_HUUR} = QuotationType;
const { KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, FLASH, LEAD, PHC, VENECO, YIELDER } = ProviderType;
const {STANDARD_TYPE_QUOTATION, LEAD_TYPE_QUOTATION} = OfferType;

const availableSteps = [
    {
        displayName: "Info",
        id: 1,
        iconType: 'business',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, FLASH, LEAD, PHC, VENECO, YIELDER ],
        availableForType: [STANDARD_TYPE_QUOTATION, LEAD_TYPE_QUOTATION]
    },
    {
        displayName: "Locaties",
        id: 2,
        iconType: 'location_on',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR  ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, ODIDO, FLASH, PHC, VENECO, YIELDER ],
        availableForType: [STANDARD_TYPE_QUOTATION, LEAD_TYPE_QUOTATION]
    },
    {
        displayName: "Applicaties",
        id: 19,
        iconType: 'settings_applications',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ FLASH ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Communicatie",
        id: 21,
        iconType: 'router',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ FLASH ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Interface",
        id: 22,
        iconType: 'insert_link',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ FLASH ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Services",
        id: 23,
        iconType: 'room_service',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ FLASH ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Access",
        id: 3,
        iconType: 'settings_input_component',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, ODIDO, PHC, VENECO ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Communicatie",
        id: 17,
        iconType: 'phone_android',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ FLASH ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Producten",
        id: 18,
        iconType: 'settings_input_component',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, FLASH, LEAD ],
        availableForType: [LEAD_TYPE_QUOTATION]
    },
    {
        displayName: 'Mobiel',
        id: 10,
        iconType: 'phone_android',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, LEAD, ODIDO, PHC, VENECO, YIELDER ],
        availableForType: [STANDARD_TYPE_QUOTATION],
        availableForWebLead: false
    },
    {
        displayName: "Voice",
        id: 8,
        iconType: 'settings_phone',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, ODIDO, PHC, VENECO, YIELDER ],
        availableForType: [STANDARD_TYPE_QUOTATION],
        availableForWebLead: false
    },
    {
        displayName: "Partner Diensten",
        id: 13,
        iconType: 'call_merge',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, PHC, VENECO ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "IT Diensten",
        id: 14,
        iconType: 'call_merge',
        displayLocations: true,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, YIELDER, PHC, VENECO ],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Korting",
        id: 5,
        iconType: 'euro_symbol',
        displayLocations: false,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, FLASH, PHC, VENECO ],
        availableForType: [STANDARD_TYPE_QUOTATION, LEAD_TYPE_QUOTATION],
        availableForWebLead: false
    },
    {
        displayName: "Overzicht",
        id: 6,
        iconType: 'playlist_add_check',
        displayLocations: false,
        next: false,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA, TYPE_KOOP, TYPE_VERGELIJKING, TYPE_HUUR ],
        availableForProvider: [ KPN, SALESVALUE, VODAFONE, DBIT, ODIDO, FLASH, PHC, VENECO, YIELDER ],
        availableForType: [STANDARD_TYPE_QUOTATION, LEAD_TYPE_QUOTATION]
    },
    {
        displayName: "Bestelwizard",
        id: 24,
        iconType: 'business',
        displayLocations: false,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ ODIDO, PHC, VENECO ],
        availableForRoles: [ 'ROLE_SALES_SUPPORT', 'ROLE_ADMIN'],
        availableForType: [STANDARD_TYPE_QUOTATION]
    },
    {
        displayName: "Orderen",
        id: 15,
        iconType: 'add_shopping_cart',
        displayLocations: false,
        availableFor: [ TYPE_STANDARD, TYPE_MIGRATION, TYPE_EXTENSION, TYPE_EXPANSION, TYPE_EXISTING, TYPE_VERLENGEN, TYPE_QA ],
        availableForProvider: [ KPN, ODIDO, PHC, VENECO ],
        availableForRoles: [ 'ROLE_SALES_SUPPORT', 'ROLE_ADMIN'],
        availableForOrdering: true,
        availableForType: [STANDARD_TYPE_QUOTATION]
    }
];

const initialState = {
    currentStepId: availableSteps[0].id,
    provider: null,
    availableSteps: availableSteps,
    availableToGoForward: false,
    quotationType: STANDARD_TYPE_QUOTATION,
    availabilityMessage: 'Niet alle verplichte velden zijn ingevuld!'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.setCurrentStep:
            return {
                ...state,
                currentStepId: action.payload
            };
        case actionTypes.setAvailableToGoForward:
            return {
                ...state,
                availableToGoForward: action.payload
            };
        case actionTypes.setQuotationProvider:
            return {
                ...state,
                provider: action.payload
            };
        case actionTypes.setQuotationType:
            return {
                ...state,
                quotationType: action.payload
            };
        case actionTypes.setAvailabilityMessage:
            return {
                ...state,
                availabilityMessage: action.payload
            };
        default:
            return state;
    }
}
