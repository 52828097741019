import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'
import {actionTypes} from "../constants/actionTypes";

const deleteTermsAndConditionsFile = (fileId) => {
    return async (dispatch) => {
      try {
        let url = apiRoutes.adminRelated.deleteTermsAndConditionsFile.replace(/{termsAndConditions}/, fileId);

        await ApiService.performRequest(RequestType.DELETE, url);

        dispatch(deleteTcFileSuccess(fileId))
      }  catch (error) {
          toastr.error("Failed to delete the selected file.", error.message);
      }
    }
}

const deleteTcFileSuccess = (fileId) => {
    return {
        type: actionTypes.deleteTermsAndConditionsFile,
        fileId
    }
};

const signTermsAndConditions = (uuid, checked) => {
    return async () => {
        try {
            let url = apiRoutes.digitalSigningLogsRelated.signTermsAndConditions;

            return await ApiService.performRequest(RequestType.POST, url, {
                'uuid': uuid,
                'value': checked
            })
        } catch (error) {
            toastr.error("Failed to sign the terms and conditions", error.message);
        }
    }
}

const signYielderTermsAndConditions = (quotationId, checked) => {
    return async () => {
        try {
            let url = apiRoutes.digitalSigningLogsRelated.signYielderTermsAndConditions;

            return await ApiService.performRequest(RequestType.POST, url, {
                'quotationId': quotationId,
                'value': checked
            })
        } catch (error) {
            toastr.error("Failed to sign the terms and conditions", error.message);
        }
    }
}

const retrieveTermsAndConditionsFiles = (uuid) => {
    return async () => {
        try {
            let url = apiRoutes.digitalSigningLogsRelated.retrieveTermsAndConditionsFiles.replace(/{uuid}/, uuid);

            return await ApiService.performRequest(RequestType.GET, url)
        } catch (error) {
            toastr.error("Failed to sign the terms and conditions", error.message);
        }
    }
}

const retrieveQuotationTermsAndConditionsFile = (quotationId) => {
    return async () => {
        try {
            let url = apiRoutes.digitalSigningLogsRelated.retrieveQuotationTermsAndConditionsFile.replace(/{quotationId}/, quotationId);

            return await ApiService.performRequest(RequestType.GET, url)
        } catch (error) {
            toastr.error("Failed to sign the terms and conditions", error.message);
        }
    }
}

const retrieveTermsAndConditionsFile = (uuid, tcFileId) => {
    return async () => {
        try {
            let url = apiRoutes.digitalSigningLogsRelated.retrieveTermsAndConditionsFile.replace(/{uuid}/, uuid).replace(/{tcFileId}/, tcFileId);

            return await ApiService.performRequest(RequestType.GETFILE, url)
        } catch (error) {
            toastr.error("Failed to sign the terms and conditions", error.message);
        }
    }
}

const enableOrganizationTermsAndConditions = (value) => {
    return async () => {
        try {
            let url = apiRoutes.adminRelated.enableOrganizationTermsAndConditions

            return await ApiService.performRequest(RequestType.POST, url, {
                'isTermsAndConditions': value
            });
        } catch (error) {
            toastr.error("Failed to enable/disable the organization terms and conditions.", error.message);
        }
    }
}

const getOrganizationTermsAndConditionsFiles = (queryParams, providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.adminRelated.getTermsAndConditionsFiles;
            url = url.replace(/{providerId}/, providerId) + queryParams;

            const response =  await ApiService.performRequest(RequestType.GET, url);

            dispatch(getOrganizationTermsAndConditionsFilesSuccess(response.tcFiles))
            dispatch(setTcPagination(response.currentPage, response.resultsCount, response.pages))
        } catch (error) {
            toastr.error("Failed to retrieve the T&C files of the organization", error.message);
        }
    }
}

const getOrganizationTermsAndConditionsFilesSuccess = (response) => {
    return {
        type: actionTypes.getOrganizationTermsAndConditionsFiles,
        response
    }
};

const setTcPagination = (currentPage, resultsCount, pages) => {
    return {
        type: actionTypes.setTcFilesPagination,
        payload: {
            pages,
            currentPage,
            resultsCount
        }
    }
};

const downloadTermsAndConditionsFile = (fileId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.adminRelated.downloadTermsAndConditionsFile.replace(/{termsAndConditions}/, fileId)
            const response = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(downloadTcFileSuccess(response));

            return true;
        } catch (error) {
            toastr.error("Failed to download the T&C file.", error.message);
        }
    }
}

const downloadTcFileSuccess = (response) => {
    return {
        type: actionTypes.downloadTermsAndConditionsFile,
        response
    }
};

const addOrganizationTermsAndConditionFile = (data) => {
    return async () => {
        try {
            let url = '';

            if (data.id) {
                url = apiRoutes.adminRelated.editOrganizationTermsAndConditionsFile.replace(/{termsAndConditions}/, data.id);
                return await ApiService.performRequest(RequestType.PUT, url, data);
            } else {
                url = apiRoutes.adminRelated.addOrganizationTermsAndConditionFile
                return await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);
            }
        } catch (error) {
            toastr.error("Failed to upload the T&C file.", error.message);
        }
    }
};

const getTermsAndConditionProductCategories = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.adminRelated.getTermsAndConditionsProductCategories;
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getTermsAndConditionProductCategoriesSuccess(response.productCategories));
        } catch (error) {
            toastr.error("Kan de productcategorieën niet ophalen", error.message);
        }
    }
};

const getTermsAndConditionProductCategoriesSuccess = (response) => {
    return {
        type: actionTypes.setTermsAndConditionsProductCategories,
        payload: response
    }
};

export default {
    deleteTermsAndConditionsFile,
    signTermsAndConditions,
    retrieveTermsAndConditionsFile,
    enableOrganizationTermsAndConditions,
    getOrganizationTermsAndConditionsFiles,
    downloadTermsAndConditionsFile,
    addOrganizationTermsAndConditionFile,
    getTermsAndConditionProductCategories,
    retrieveTermsAndConditionsFiles,
    signYielderTermsAndConditions,
    retrieveQuotationTermsAndConditionsFile
}