import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import Pagination from '../../../components/pagination/pagination';
import styled from 'styled-components';
import quotationType from "../../../constants/quotationType";
import {connect} from 'react-redux';
import {Button} from "react-materialize";
import productActionTypes from "../../../constants/productActionTypes";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";
import {odidoProviders} from "../../../constants/providerType";

const CustomLabel = styled.div(
  {
    'label::before': {
      top: '9px !important',
      left: '7px'
    }
  }
);

class OrderingProducts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pagination: {},
      selectedProducts: new Map(),
      saveData: false
    };
  }

  componentDidMount() {
    this.updateStateProducts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedProducts !== this.props.selectedProducts) {
      this.updateStateProducts();
    }
  }

  updateStateProducts = () => {
    let products = new Map();
    this.props.selectedProducts.forEach(({productId, sent, telephoneNumber, indexVoip, hostedVoiceNumber, orderId, isAccess, productLocationId}) => {
      let id = productId;

      if (telephoneNumber !== null) {
        id = telephoneNumber
      }

      if (hostedVoiceNumber !== null) {
        id = hostedVoiceNumber
      }

      if (indexVoip !== null) {
        id = productId + '_' + indexVoip.toString()
      }

      if (isAccess) {
        id = productId + (orderId ? '-' + orderId : '')

        if (productLocationId) {
          id = productId + '-' + productLocationId;
        }
      }

      products.set('product-' + id, typeof sent === 'boolean' ? sent : null)
    });

    this.setState({selectedProducts: products, saveData: this.props.statusSaveMatchingData.success})
  };

  generateHeaders = (isCustomCategory, displayActions, displayQuantity) => {
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;

    const moduleName = this.props.module.name;
    const isMobileModule = moduleName === 'Mobiel';

    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: '50%'}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
          </TableHeaderColumn>

          {isMobileModule &&
            <TableHeaderColumn style={{width: 'auto'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Order actie</div>
            </TableHeaderColumn>
          }

          {['Telefooncentrale', 'Vast Bellen'].includes(moduleName) && <TableHeaderColumn style={{width: 'auto'}}/>}

          {isSelectedActionNeeded &&
            <TableHeaderColumn style={{width: 'auto'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Gekozen order actie</div>
            </TableHeaderColumn>
          }

          {moduleName === 'Verbindingen' &&
            <TableHeaderColumn style={{width: 'auto'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Locatie</div>
            </TableHeaderColumn>
          }

          {moduleName === 'Telefoonnummers' &&
            <TableHeaderColumn>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold', width: 'auto'}}>Telefoonnummer</div>
            </TableHeaderColumn>
          }

          {displayQuantity &&
            <TableHeaderColumn style={{width: 'auto'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold', width: 'auto'}}>Aantal</div>
            </TableHeaderColumn>
          }

          {(isMobileModule || ['Telefooncentrale', 'Vast Bellen', 'Telefoonnummers', 'Verbindingen'].includes(moduleName)) &&
            <TableHeaderColumn style={{width: 'auto'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Acties</div>
            </TableHeaderColumn>
          }
        </TableRow>
      </TableHeader>
    )
  };

  changePage = (numberId, target) => {
    let pagination = this.state.pagination;
    pagination[numberId] = target.value;
    this.setState({pagination: pagination});
  };

  getActionName = (action, isVerlengenType) => {
    if (!action && isVerlengenType) {
      return 'Nieuw';
    }

    switch (action) {
      case 5:
        return 'Nieuw';
      case 1:
        return 'Porteren';
      case 4:
        return 'Simless porteren';
      case 6:
        return isVerlengenType ? 'Up/downgrade' : 'Upgraden';
      case 7:
        return 'Verlenging';
      case 8:
        return 'Opheffing';
      case 9:
        return 'Toevoeging';
      case 10:
        return 'Retentie';
      case 'Meerdere':
        return 'Meerdere';
      default:
        return '';
    }
  };

  handleChange = ({target: {name, checked, dataset}}, originalProduct) => {
    const {quotation: {id: quotationId}, module} = this.props;
    let type = dataset.type;
    let {products, mobileNumbers, vamoProducts, landlineUsers, complexProducts, isHostedVoiceNumber, isAccess, name: moduleName} = module;
    let isTelefooncentrale = moduleName === "Telefooncentrale";
    let isParentProduct = !(type !== 'category' || isAccess);
    let newChecked = checked === true ? false : null;

    let selectedProducts = this.state.selectedProducts.set('product-' + name, newChecked);
    this.setState({selectedProducts});

    let productId = parseInt((landlineUsers || isTelefooncentrale) ? name : name.replace('product-', ''));
    let isPhoneNumber = null;
    let allProducts = products;

    if (mobileNumbers) {
        allProducts = mobileNumbers.products;
        isPhoneNumber = mobileNumbers.isPhoneNumber;
    }
    if (landlineUsers) {
        allProducts = landlineUsers.userProducts;
    }
    if (originalProduct && !mobileNumbers) {
      allProducts = allProducts.filter((product) => product.id === originalProduct.id)
    }
    if (isTelefooncentrale) {
      allProducts = complexProducts;
      let complexProductNumbers = complexProducts.map((el) => el.product_number);
      products.forEach((el) => {
        if (!complexProductNumbers.includes(el.product_number)) {
          allProducts.push(el);
        }
      });

      landlineUsers = true;
    }

    let foundProduct;
    if (originalProduct && originalProduct.selection) {
      foundProduct = allProducts.find((product) => product.orderId === originalProduct.orderId && product.locationId === originalProduct.locationId);
    } else {
      foundProduct = allProducts.find((product) => originalProduct && originalProduct.action !== 'Meerdere' ?
        ((product.id === productId ||product.product === productId) && product.action === originalProduct.action) :
        (product.id === productId || product.product === productId));
    }

    if (landlineUsers && !foundProduct) {
      allProducts = products.filter((product) => product.id === productId);
    }

    if (! mobileNumbers && ! landlineUsers) {
      this.props.updateProducts.updateOrderingProducts(
        quotationId,
        {
          products: [foundProduct],
          checked: newChecked,
          isPhoneNumber,
          isHostedVoiceNumber,
          isParentProduct
        }
        ).then(() => this.props.updateProducts.getSelectedProducts(quotationId));

      return;
    }

    let checkedProducts, categoryData, moduleData;

    if (mobileNumbers) {
      let phoneProducts = [];
      let filteredProducts = [];
      let mobileProductId = null;
      let mobileProductAction = null;
      checkedProducts = 0;

      if (foundProduct) {
        mobileProductId = foundProduct.product;
        mobileProductAction = foundProduct.action;
        phoneProducts = allProducts.filter((mobileProduct) => mobileProduct.product === foundProduct.product);
        phoneProducts.forEach(({id}, index) => {
          if (typeof newChecked === 'boolean' && (productId === id || selectedProducts.has('product-' + id) || selectedProducts.has('product-' + id + '_' + index))) {
            checkedProducts++;
          }

          filteredProducts.push({
            id: landlineUsers ? id + '_' + index : id,
            value: landlineUsers ? selectedProducts.get('product-' + id + '_' + index) : selectedProducts.get('product-' + id)
          })
        });
      } else {
        const newProduct = products.find((el) => el.id === productId);
        mobileProductId = newProduct ? newProduct.id : null;
        mobileProductAction = newProduct ? newProduct.action :null;
      }

      const uncheckedProducts = filteredProducts.filter((el) => el.value === null && el.id !== productId);
      let parentChecked = type === 'category' ? newChecked :
        ( checkedProducts === phoneProducts.length && !uncheckedProducts.length ? false : null);

      // filter out sent products
      phoneProducts = phoneProducts.filter((el) => {return this.state.selectedProducts.get('product-' + el.id) !== true;});
      categoryData = {products: [{id: mobileProductId, action: mobileProductAction}], checked: parentChecked};
      moduleData = {
        products: type === 'category' ? phoneProducts : [foundProduct],
        checked: newChecked, isPhoneNumber,
        isParentProduct
      };
      
      const vamoProduct = products.find(product => product.id === mobileProductId && product?.isVamoProduct);

      if (vamoProduct) {
        let selectedVamoProducts = [];
        vamoProducts.forEach((vamoProduct) => {
          if (vamoProduct.id === mobileProductId) {
            selectedVamoProducts.push(vamoProduct);
          }
        });

        categoryData = {
          products: selectedVamoProducts,
          checked: parentChecked
        };
      }

      this.props.updateProducts.updateOrderingProducts(quotationId, categoryData)
        .then(() => this.props.updateProducts.updateOrderingProducts(quotationId, moduleData)
        .then(() => this.props.updateProducts.getSelectedProducts(quotationId)));
      return;
    }

    if (landlineUsers) {
      checkedProducts = 0;

      allProducts = allProducts.map((product, index) => {
        return this.state.selectedProducts.get('product-' + product.id + '_' + index) === true ? {id: null} : product;
      });
      allProducts = this.getOrderFilteredProducts(allProducts);

      let orderId = originalProduct && originalProduct.orderId ? originalProduct.orderId :
        (foundProduct ? foundProduct.orderId : null);

      let categoryProduct = products.find((product) => product.id === productId);
      let amount = categoryProduct ? categoryProduct.amount : 0;
      for (let index = 0; index < amount; index ++) {
        if (this.state.selectedProducts.get('product-' + productId + '_' + index) !== null) {
          checkedProducts++;
        }
      }

      let categoryCheck = amount === checkedProducts && checked ? false : null;
      categoryData = {
        products: [{id: productId}],
        checked: categoryCheck,
        isParentProduct
      };
      moduleData = {
        products: type === 'category' ? allProducts : [{...foundProduct, orderId}],
        checked: newChecked,
        voipIndex: parseInt(name.substring(name.indexOf('_') + 1, name.length)),
        all: !foundProduct ? false : type === 'category',
        isUsername: true,
        isParentProduct: isParentProduct
      };

      this.props.updateProducts.updateOrderingProducts(quotationId, categoryData)
        .then(() => this.props.updateProducts.updateOrderingProducts(quotationId, moduleData))
        .then(() => this.props.updateProducts.getSelectedProducts(quotationId))
    }
  };

  getOrderFilteredProducts = (productList) => {
    let filteredProducts = [];
    productList.forEach((product) => {
      let productOrderId = product.orderId;
      let alreadyProcessedProduct = filteredProducts.find(formattedProduct => productOrderId && formattedProduct.orderId === productOrderId);
      if (! alreadyProcessedProduct) {
        filteredProducts.push(product);
      }
    });

    return filteredProducts;
  };

  getPenIconColor = (product) => {
    if (product?.isVamoProduct) {
      return product.requiredDataFilled ? "green-text" : "rood"
    }
    
    return product.required_data_filled ? "green-text" : "rood"
  }

  renderComplexProducts = (productNumber) => {
    let complexProducts = this.props.module.complexProducts;
    if (!complexProducts) {
      return;
    }
    let filteredProducts = complexProducts.filter((complexProduct) => complexProduct.product_number === productNumber);
    if (filteredProducts.length < 1) {
      return;
    }

    let { selectedProducts } = this.state;
    const { inputsDisabled } = this.props;
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;

    return filteredProducts.map((complexProduct) => {
      const index = complexProduct.index;
      let localIndex = index - 1;
      let dataFilled = false;
      let original = complexProduct.complexException ? complexProduct : null;

      const selected = selectedProducts.get(`product-${complexProduct.id}_${localIndex}`);
      const complexProductsData = this.props.userNames[complexProduct.product_number];
      if (complexProductsData) {
        dataFilled = complexProductsData[localIndex];
      }

      let iconColor = (dataFilled || complexProduct.required_data_filled) ? "green-text" : "rood";

      return (
        <TableRow>
          {
            this.props.module.name === 'Telefooncentrale' && <TableRowColumn style={{width: 'auto'}}/>
          }
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: '50%'}}>
            <CustomLabel>
              <input
                name={complexProduct.id + '_' + localIndex}
                className="input-field"
                checked={typeof selected === 'boolean'}
                id={'product-' + complexProduct.id + '_' + localIndex}
                type="checkbox"
                onChange={(e) => this.handleChange(e, original)}
                disabled={selected}
              />
              <label htmlFor={'product-' + complexProduct.id + '_' + localIndex}/>
              {complexProduct.name}
            </CustomLabel>
          </TableRowColumn>
          { isSelectedActionNeeded &&
            <TableRowColumn style={{width: 'auto'}}>
              <span>{this.getActionName(complexProduct.action, isSelectedActionNeeded)}</span>
            </TableRowColumn>
          }
          <TableRowColumn/>
          { complexProduct.needsEditing &&
            <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
              <a style={selected ? {pointerEvents: 'none', cursor: 'default'} : {cursor: 'pointer'}} onClick={() => this.props.editUsernames(localIndex, complexProduct.product_number, this.props.module)}>
                <i className={"small material-icons left " + (inputsDisabled && selected ? "grey-text" : iconColor)}>edit</i>
              </a>
            </TableRowColumn>
          }
        </TableRow>
      );
    });
  };

  renderUserProducts = (productId) => {
    const {module, inputsDisabled} = this.props;
    const {selectedProducts} = this.state;
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;
    const isVerlengen = this.props.quotation.type === quotationType.TYPE_VERLENGEN;

    let filteredProducts = module.landlineUsers.userProducts.filter((userProduct) => userProduct.id === productId);

    return filteredProducts.map((product, index) => {
      const userProductsData = this.props.userNames[product.product_number];
      const selected = selectedProducts.get('product-' + product.id + '_' + index);

      let dataFilled = false;
      let productName = product.product_number === '101A00001N' ? 'KPN EEN gebruiker' : product.name + '#' + (index + 1);
      if (userProductsData) {
        dataFilled = userProductsData[index];
      }

      let iconColor = (dataFilled || product.required_data_filled) ? "green-text" : "rood";

      return (
        <TableRow>
          {
            module.name === 'Vast Bellen' && <TableRowColumn style={{width: '50%'}}/>
          }
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: module.name === 'Vast Bellen' ? 'auto' : '50%'}}>
            <CustomLabel>
              <input
                name={product.id + '_' + index}
                className="input-field"
                checked={typeof selected === 'boolean'}
                id={'product-' + product.id + '_' + index}
                type="checkbox"
                onChange={(e) => this.handleChange(e, product)}
                disabled={selected}
              />
              <label htmlFor={'product-' + product.id + '_' + index}/>
              {productName}
            </CustomLabel>
          </TableRowColumn>
          { isSelectedActionNeeded &&
            <TableRowColumn style={{width: 'auto'}}>
              <span>{this.getActionName(product.action, isVerlengen)}</span>
            </TableRowColumn>
          }
          {
            module.name === 'Vast Bellen' &&
            <TableRowColumn style={{width: '50%'}}>
              {this.renderProductQuantity(product)}
            </TableRowColumn>
          }
          { product.needsEditing &&
            <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
              <a style={selected ? {pointerEvents: 'none', cursor: 'default'} : {cursor: 'pointer'}} onClick={() => this.props.editUsernames(index, product.product_number)}>
                <i className={"small material-icons left " + (inputsDisabled && selected ? "grey-text" : iconColor)}>edit</i>
              </a>
            </TableRowColumn>
          }
        </TableRow>
      )
    })
  };

  renderProductQuantity = (product) => {
    if (!product.quantity) {
      return '';
    }
    if (product.quantity <= 1) {
      return '';
    }

    return (<span>{product.quantity}</span>);
  };

  renderFixedPhoneNumbers = (product) => {
    let rows = [];
    const {useNina} = this.props.authentication;
    const isNotOrderingPossible = useNina ? product.nina_ordering_possible === false : product.grexx_ordering_possible === false;
    const orderingPossibleMessage = product.is_ip_product ?
        ' Dit product wordt georderd in de verbinding zelf en niet als los product.' :
        (product.isKantoorOnberperktProduct ?
            'Dit product wordt in de telefooncentrale geactiveerd bij het versturen van de order' : ' Orderen niet mogelijk');

    for (let i = 0; i < product.amount; i++) {
      rows.push(
        <TableRow key={`product-${product.id}-${i}`}>
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: '50%'}}>
            {product.name}
            {isNotOrderingPossible && <span className='text-danger'>{orderingPossibleMessage}</span>}
          </TableRowColumn>
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            1
          </TableRowColumn>
        </TableRow>
      );

      if (product.fixed_phone_numbers && product.fixed_phone_numbers[i]) {
        let renderedFixedPhoneNumbers = this.renderFixedPhoneNumber(product.fixed_phone_numbers[i], product.required_data_filled);
        rows = [...rows, ...renderedFixedPhoneNumbers];
      }
    }

    return rows;
  };

  renderFixedPhoneNumber = (number, requiredDataFilled) => {
    const {inputsDisabled} = this.props;
    let rows = [];
    let rangeStart = number.prefix ? number.prefix + number.startingNumber : number.startingNumber;
    let intRangeStart = parseInt(rangeStart);
    let currentPage = this.state.pagination[number.id] || 1;
    let perPage = number.blocks <= 1 ? 1 : 10;

    intRangeStart += (currentPage - 1) * perPage;
    for (let i = 0; i < perPage; i++) {
      let txtNumber = String(intRangeStart + i);

      rows.push(
        <TableRow key={`number-${number.id}-${i}`}>
          <TableRowColumn style={{paddingLeft: '44px', width: 'auto'}} colSpan={2}>
            {txtNumber.padStart(10, '0')}
          </TableRowColumn>
          <TableRowColumn style={{width: 'auto'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.editNumber('fixed', number.id, txtNumber.padStart(10, '0'))}>
              <i className={"small material-icons left " + (inputsDisabled ? "grey-text" : (requiredDataFilled ? "green-text" : "rood"))}>edit</i>
            </a>
          </TableRowColumn>
        </TableRow>
      );
    }

    if (number.blocks > 10) {
      rows.push(
        <TableRow key={`pagination-${number.id}`}>
          <TableRowColumn style={{paddingLeft: '44px', width: 'auto'}} colspan={2}>
            <Pagination page={currentPage} pages={number.blocks / 10} changePage={(e) => {this.changePage(number.id, e.target)}}/>
          </TableRowColumn>
        </TableRow>
      );
    }

    return rows;
  };

  displayDataBasedOnCategory = (moduleName, product) => {
    if (moduleName === 'Telefoonnummers') {
      return product.number;
    }

    //return location name of product
    if (moduleName === 'Verbindingen') {
      const location = this.props.locations.allLocations.find((el) => el.id === product.locationId);

      return location.name;
    }
  }

  renderProduct = (product, productId) => {
    const {selectedProducts} = this.state;
    const {module, inputsDisabled, quotation} = this.props;
    const {useNina} = this.props.authentication;
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;
    const isVerlengen = this.props.quotation.type === quotationType.TYPE_VERLENGEN;
    const selectedProduct = selectedProducts.get('product-' + productId);
    let disabled = inputsDisabled;
    let iconColor = this.getPenIconColor(product);
    let selected = selectedProducts.get('product-' + product.id);
    let isCategoryChecked = typeof selectedProduct === 'boolean';
    const isNotOrderingPossible = useNina ? product.nina_ordering_possible === false : product.grexx_ordering_possible === false;
    const isVamoProduct = module.isMobile && product?.isVamoProduct;
    const isOpheffenAction = product.action === productActionTypes.OPHEFFEN;
    const orderingPossibleMessage = product.is_ip_product ?
        ' Dit product wordt georderd in de verbinding zelf en niet als los product.' :
        (product.isKantoorOnberperktProduct ?
            'Dit product wordt in de telefooncentrale geactiveerd bij het versturen van de order' : ' Orderen niet mogelijk');

    if (isVamoProduct) {
      const vamoProductsList = module.vamoProducts.filter((vamoProduct) => vamoProduct.id === productId);
      const selectedVamoProducts = this.props.selectedProducts.filter((selectedProduct) => selectedProduct.productId === productId);
      if (vamoProductsList.length !== selectedVamoProducts.length) {
        isCategoryChecked = false;
      }
    }

    return (
      <React.Fragment>
        <TableRowColumn
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: '50%'
          }}>
          <React.Fragment>
            {product.id ?
              <CustomLabel>
                <input
                  name={productId}
                  className="input-field"
                  data-type="category"
                  checked={isCategoryChecked}
                  id={'product-' + productId}
                  type="checkbox"
                  onChange={(e) => this.handleChange(e, product)}
                  disabled={selectedProduct || isNotOrderingPossible}
                />
                <label htmlFor={'product-' + productId} className={isCategoryChecked && !selectedProduct && !isNotOrderingPossible ? "checkbox-active" : ""}/>
                {product.name}
              </CustomLabel> :
              <CustomLabel style={{paddingLeft: '35px'}}>
                {product.name}
              </CustomLabel>
            }
          </React.Fragment>
          {isNotOrderingPossible &&
            <span className='text-danger'>{orderingPossibleMessage}</span>}
          {product.fetchedFromGrexx === true &&
            <span className='text-danger'>LET OP: Product komt niet meer voor in de huidige KPN één menukaart. Extra aandacht vereist. Voor verlengen is de oude prijs onbekend in Ratio.</span>}
          {product.id === null &&
            <span className='grey-text' style={{fontStyle: 'italic'}}>
              De opheffing van dit oude product zal direct meegaan in de eerste order
            </span>}
        </TableRowColumn>

        {['Mobiel', 'Telefoncentrale', 'Vast Bellen', 'Telefooncentrale'].includes(module.name) && <TableRowColumn style={{width: 'auto'}}/>}

        {isSelectedActionNeeded &&
          <TableRowColumn style={{width: 'auto'}}>
            <span>{this.getActionName(product.action, isVerlengen)}</span>
          </TableRowColumn>
        }

        {(module.name === 'Telefoonnummers' || module.name === 'Verbindingen') &&
          <TableRowColumn>
            <span>{this.displayDataBasedOnCategory(module.name, product)}</span>
          </TableRowColumn>
        }

        <TableRowColumn
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            display: isVamoProduct ? 'flex' : '',
            alignItems: isVamoProduct ? 'center' : '',
            height: isVamoProduct ? '100%' : '',
            width: 'auto'
          }}
        >
          <span>{product.amount}</span>
        </TableRowColumn>

        {['Telefooncentrale', 'Vast Bellen', 'Mobiel'].includes(module.name) &&
          <TableRowColumn style={{width: 'auto'}}>
            {isVamoProduct && isOpheffenAction &&
            <a style={{cursor: 'pointer', marginLeft: 15}} onClick={() => this.props.editVamoProduct(quotation.id, product.id)}>
              <i className={"small material-icons " + (disabled && selected ? "grey-text" : iconColor)}>edit</i>
            </a>
            }
          </TableRowColumn>
        }

        {module.isHostedVoiceNumber &&
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.editHostedVoiceNumber(product.id)}>
              <i className={"small material-icons left " + (disabled && selected ? "grey-text" : iconColor)}>edit</i>
            </a>
          </TableRowColumn>
        }

        {module.isAccess &&
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.editAccessOptions(product.selection, product.ordering_access_type)}>
              <i className={"small material-icons left " + (disabled && selected ? "grey-text" : iconColor)}>edit</i>
            </a>
          </TableRowColumn>
        }
        {module.isUsername &&
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.editUsernames()}>
              <i className={"small material-icons left " + (disabled ? "grey-text" : iconColor)}>edit</i>
            </a>
          </TableRowColumn>
        }
        {module.isAccessOptions && product.name.startsWith('Fiber VLAN |') &&
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.editAccessOptions(product.selection, product.ordering_access_type)}>
              <i className={"small material-icons left " + (disabled && selected ? "grey-text" : iconColor)}>edit</i>
            </a>
          </TableRowColumn>
        }
      </React.Fragment>
    );
  };

  renderMobileNumbers = (productId) => {
    const {module, inputsDisabled} = this.props;
    const {selectedProducts} = this.state;
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;
    const isVerlengen = this.props.quotation.type === quotationType.TYPE_VERLENGEN;

    let filteredProducts = module.mobileNumbers.products.filter((mobileNumber) => mobileNumber.product === productId);

    return filteredProducts.map((mobileNumber) => {
      const selected = selectedProducts.get('product-' + mobileNumber.id);
      return (
        <TableRow key={'mobile-' + mobileNumber.id}>
          {
            module.name === 'Mobiel' && <TableRowColumn/>
          }

          <TableRowColumn style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '50%'}}>
            <CustomLabel>
              <input
                name={mobileNumber.id}
                className="input-field"
                checked={typeof selected === 'boolean'}
                id={'product-' + mobileNumber.id}
                type="checkbox"
                onChange={(e) => this.handleChange(e)}
                disabled={selected}
              />
              <label htmlFor={'product-' + mobileNumber.id}/>
              {this.getActionName(mobileNumber.action, isVerlengen)}: {mobileNumber.number}
            </CustomLabel>
          </TableRowColumn>

          { isSelectedActionNeeded &&
            <TableRowColumn style={{width: 'auto'}}>
              <span>{this.getActionName(mobileNumber.action, isVerlengen)}</span>
            </TableRowColumn>
          }

          <TableRowColumn style={{width: 'auto'}}/>

          <TableRowColumn style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: 'auto'}}>
            <a style={selected ? {pointerEvents: 'none', cursor: 'default'} : {cursor: 'pointer'}} onClick={() => this.props.editNumber('mobile', mobileNumber.id, mobileNumber.number)}>
              <i className={"small material-icons left " + (inputsDisabled && selected ? "grey-text" : (mobileNumber.required_data_filled ? "green-text" : "rood"))}>edit</i>
            </a>
          </TableRowColumn>
        </TableRow>
      );
    });
  };

  renderPhoneNumber = (product) => {
    const {selectedProducts} = this.state;
    const {inputsDisabled} = this.props;
    const isSelectedActionNeeded = this.props.quotation.type === quotationType.TYPE_VERLENGEN || this.props.isPIT;
    const isVerlengen = this.props.quotation.type === quotationType.TYPE_VERLENGEN;

    return (
      <React.Fragment>
        <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', width: '50%'}}>
          <React.Fragment>
            {product.id ?
              <CustomLabel>
                <input
                  name={product.id}
                  className="input-field"
                  checked={typeof selectedProducts.get('product-' + product.id) === 'boolean'}
                  id={'product-' + product.id}
                  type="checkbox"
                  onChange={(e) => this.handleChange(e)}
                  disabled={selectedProducts.get('product-' + product.id)}
                />
                <label htmlFor={'product-' + product.id}/>
                {product.number}
              </CustomLabel> :
              <CustomLabel style={{paddingLeft: '35px'}}>
                {product.name}
              </CustomLabel>
            }
          </React.Fragment>
          ({this.getActionName(product.action, isVerlengen)})
        </TableRowColumn>
        { isSelectedActionNeeded &&
          <TableRowColumn style={{width: 'auto'}}>
            <span>{this.getActionName(product.action, isVerlengen)}</span>
          </TableRowColumn>
        }
        <TableRowColumn
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: 'auto'
          }}>
          <a style={{cursor: 'pointer'}} onClick={() => this.props.editNumber('mobile', product.id, product.number)}>
            <i className={"small material-icons left " + (inputsDisabled ? "grey-text" : (product.required_data_filled ? "green-text" : "rood"))}>edit</i>
          </a>
        </TableRowColumn>
      </React.Fragment>
    );
  };

  render() {
    const {module, onCheckboxChange, checked, quotation, authentication} = this.props;
    const {type, provider} = quotation;
    const isVerlengen = type === quotationType.TYPE_VERLENGEN;
    const {useNina} = authentication;
    const isStandardQuotation = type === quotationType.TYPE_STANDARD;
    const isOdidoProvider = odidoProviders.includes(provider);

    const areAccessOptionsActionsNeeded = module.products.filter((product) =>
        product.name.startsWith('Fiber VLAN |')).length > 0 && module.isAccessOptions;

    return (
      <div className="row">
        <div className="col s12 overviewkopbg">
          <div className="overviewkop" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <React.Fragment>
              <CustomLabel>
                <input
                    name={module.name}
                    className="input-field"
                    checked={typeof checked === 'boolean'}
                    id={'category-' + module.name.replace(" ", "").toLowerCase()}
                    type="checkbox"
                    onChange={(e) => onCheckboxChange(e)}
                    disabled={checked}
                />
                  <label htmlFor={'category-' + module.name.replace(" ", "").toLowerCase()} style={{'&::before': {top: '10px'}}}/>
                {module.name}
              </CustomLabel>

              <div style={{display: 'flex', alignItems: 'center'}}>
                {module.name === 'Mobiel' && module.addonsActive && !isVerlengen &&
                    <span style={{ fontSize: "13px", fontFamily: "Roboto, sans-serif"}}>Voor deze order worden alle voordeelmodules geactiveerd</span>
                }
                {module.name === 'Mobiel' && ! module.addonsActive && !isVerlengen &&
                    <span style={{ fontSize: "13px", fontFamily: "Roboto, sans-serif"}}>Voor deze order worden geen voordeelmodules geactiveerd</span>
                }

                {useNina && isStandardQuotation &&
                  <Button
                    waves="light"
                    className="doceri-btn-right"
                    onClick={() => this.props.openBulkDialog()}
                    style={{marginLeft: '50px'}}
                  >
                    <i className="small material-icons left">edit</i>
                    Data in bulk ingeven
                  </Button>
                }

                {isOdidoProvider && module.isMandatoryCategory && (
                  <span style={{ fontSize: "13px", fontFamily: "Roboto, sans-serif"}}>Deze productcategorie is verplicht. Selecteer de producten als ook het mobiele abonnement is geselecteerd.</span>
                )}

              </div>
            </React.Fragment>
          </div>
        </div>
        <RatioOverflowTableWrapper>
          <Table className="highlight without-margin-bottom" selectable={false} displayRowCheckbox={false}>
            {
              this.generateHeaders(
                  module.isCustomCategory,
                  ((!module.isPhoneNumber && module.isHostedVoiceNumber) || module.isPhoneNumber || module.isAccess || areAccessOptionsActionsNeeded || module.isUsername),
                  !module.isPhoneNumber)
            }

            <TableBody displayRowCheckbox={false}>
              {
                module.products.map((product) => {
                      if (product.one_row_per_quantity) {
                        return this.renderFixedPhoneNumbers(product);
                      }
                      let productId = product.id;

                      if (module.isUsername) {
                        productId = module.userProducts[0]['id'];
                      }
                      if (module.isPhoneNumber) {
                        productId = module.products[0]['id']
                      }
                      if (module.isAccess) {
                        productId = product.id + (product.orderId ? '-' + product.orderId : '')
                        if (product.locationId) {
                          productId = product.id + '-' + product.locationId;
                        }
                      }

                      return (
                          <React.Fragment>
                            <TableRow key={`product-${product.id}`}>
                              {module.isPhoneNumber ? this.renderPhoneNumber(product) : this.renderProduct(product, productId)}
                            </TableRow>
                            {
                              product.complex && this.renderComplexProducts(product.product_number)
                            }
                            {
                              module.landlineUsers && module.landlineUsers.userProducts && !module.isUsername &&
                              this.renderUserProducts(productId)
                            }
                            {
                              module.mobileNumbers && module.mobileNumbers.products && !module.isPhoneNumber &&
                              this.renderMobileNumbers(productId)
                            }
                          </React.Fragment>
                      )
                    }
                )
              }
            </TableBody>
          </Table>
        </RatioOverflowTableWrapper>
        <div className="line30" style={{ marginBottom: '10px' }}/>
      </div>
    )
  }
}

const mapStateToProps = ({ authentication, ordering, locations }) => {
  return {
    authentication,
    ordering,
    locations
  };
};

export default connect(mapStateToProps, null)(OrderingProducts);
