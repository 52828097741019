import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Autocomplete} from 'react-materialize'

import locationActions from '../../../actions/locationActions';
import KpnDialog from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import providerType from "../../../constants/providerType";

class EditLocationForQuotation extends Component {

  closeEditionDialog = () => {
    this.props.locationActions.closeEditionDialog();
  }

  _onLocationPropertyChange = ({target}) => {
    const {name, value} = target;
    this.props.locationActions.updateSelectedLocationInRedux(name, value);
  };

  saveUpdatedLocation = () => {
    const {isLead, currentQuotation} = this.props.quotation;
    const quotationId = currentQuotation.id;
    const locationId = this.props.locations.selectedLocation.id;

    this.props.locationActions.updateSelectedLocation(locationId, quotationId, isLead).then((response) => {
      if (response && response.isra_check_complete === false && !isLead) {
        this.doIsraCheck()
      }
    });
    this.closeEditionDialog();
  };

  selectLocation = (locationName, location, locationOptionalName) => {
    if (locationOptionalName)
      location.name = locationOptionalName;

    this.props.locationActions.setSelectedLocation(location, true);
  };

  doIsraCheck = () => {
    let {accessPortfolio, provider} = this.props.quotation.currentQuotation;
    let noIsraCondition = [providerType.FLASH].includes(provider);
    if ((provider === providerType.ODIDO && accessPortfolio !== 'irma') || noIsraCondition) {
      return;
    }

    this.props.locationActions.locationIsraCheck(this.props.locations.selectedLocation);
  };

  isLocationInvalid = (location) => {
    return location && location.bulk_imported === true && location.postal_code_check_passed === false;
  };

  fieldHasServiceError = (location, field) => {
    return location.hasOwnProperty('serviceErrors') && location.serviceErrors.hasOwnProperty(field);
  };

  clearIsraData = () => {
    let {selectedLocation} = this.props.locations;
    selectedLocation.isra_name = null;
    selectedLocation.isra_postal_code = null;
    selectedLocation.isra_house_number = null;
    selectedLocation.isra_house_number_extension = null;

    this.setState({selectedLocation: selectedLocation});
    this.saveUpdatedLocation();
  };

  hasIsraData = () => {
    const selectedLocationId = this.props.locations.selectedLocation.id;
    const selectedLocation = this.props.locations.allLocations.find(x => x.id === selectedLocationId);
    if (selectedLocation) {
      return selectedLocation.isra_name ||
        selectedLocation.isra_postal_code ||
        selectedLocation.isra_house_number ||
        selectedLocation.isra_house_number_extension;
    }
    return false;
  };

  render() {
    let {selectedLocation, editionDialogOpened} = this.props.locations;
    let {handleLocationSearchChange} = this.props;
    const {inputsDisabled} = this.props.quotation.currentQuotation;
    let matchingAddresses = this.props.locations.matchingAddresses;

    // I think this needs to be moved to reducer
    let dropdownData = {};
    Object.keys(matchingAddresses).forEach(function (key) {
      let obj = matchingAddresses[key];

      let name = obj.postalCode + ' ' + obj.houseNumber + obj.houseNumberExtension + ', ' + obj.street + ', ' + obj.city;
      dropdownData[name] = obj;
    });

    return (
      <KpnDialog
        dialogState={editionDialogOpened}
        dialogHeader={(
          <DialogHeader
            headerIcon="edit_location"
            headerText="Locatie:"
            headerSubtext="wijzigen"
            closeHandler={this.closeEditionDialog}
          />
        )}
        dialogBody={
          <DialogBody>
            <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
              {
                this.isLocationInvalid(selectedLocation) ? (
                  <div className={'row'}>
                    <div className="input col s12 left-align" style={{marginBottom: 50, color: '#ff0000'}}>
                      Deze locatie heeft geen geldig adres
                    </div>

                    <Autocomplete
                      className='left-align'
                      title='Wijzig adres'
                      data={dropdownData}
                      onChange={e => handleLocationSearchChange(e.target.value)}
                      onAutocomplete={(value) => this.selectLocation(value, dropdownData[value])}
                      placeholder="1234DD 32B"
                      value={this.props.locations.locationAddress}
                      s={12}
                    />

                  </div>
                ) : selectedLocation.isra_check_complete === false ? (
                  <div className={'row'}>
                    <div className="input col s12 left-align" style={{marginBottom: 50, color: '#ff0000'}}>
                      Geen ISRA punt gevonden op dit adres
                    </div>
                  </div>
                ) : null
              }
              <div className={'row'}>
                <div className="input-field col s12">
                  <input name="name" value={selectedLocation.name} onChange={this._onLocationPropertyChange} type="text"
                         className="validate"/>
                  <label className="active" htmlFor="name">Locatie naam</label>
                </div>
              </div>

              <div className={'row'}>
                <div className="input-field col s8">
                  <input name="street" value={selectedLocation.street} onChange={this._onLocationPropertyChange}
                         type="text" className="validate"/>
                  <label className="active" htmlFor="street">Straat</label>
                </div>

                <div className="input-field col s2">
                  <input name="house_number" value={selectedLocation.house_number}
                         onChange={this._onLocationPropertyChange} type="text" className="validate"/>
                  <label className="active" htmlFor="house_number">Huisnummer</label>
                </div>

                <div className="input-field col s2">
                  <input name="house_number_extension" value={selectedLocation.house_number_extension}
                         onChange={this._onLocationPropertyChange} type="text" maxLength="4" minLength="0"
                         pattern="[a-zA-Z0-9]{4}" className="validate"/>
                  <label className="active" htmlFor="house_number_extension">toevoeging</label>
                </div>
              </div>

              <div className={'row'}>
                <div className="input-field col s3">
                  <input name="postal_code" value={selectedLocation.postal_code}
                         onChange={this._onLocationPropertyChange} type="text"
                         className={`validate ${this.fieldHasServiceError(selectedLocation, 'postalCode') ? "invalid" : ""}`}/>
                  <label className="active" htmlFor="postal_code">Postcode</label>
                </div>
                <div className="input-field col s9">
                  <input name="city" value={selectedLocation.city} onChange={this._onLocationPropertyChange} type="text"
                         className="validate"/>
                  <label className="active" htmlFor="city">Plaats</label>
                </div>
              </div>

              {!this.props.isLead &&
              this.isLocationInvalid(selectedLocation) === false ? (
                <div>
                  <div className={'row'}>
                    <div className="input col s12 left-align">Alternatief isra punt
                      {
                        selectedLocation.isra_name ? (
                          <span className="themeprimarycolor"
                                style={{fontSize: '0.9em'}}> {selectedLocation.isra_name}</span>
                        ) : null
                      }
                    </div>

                    <div className="input col s12 left-align">&nbsp;</div>
                  </div>

                  <div className={'row'}>
                    <div className="input-field col s12">
                      <input name="isra_reference" value={selectedLocation.isra_reference}
                             onChange={this._onLocationPropertyChange} type="text"
                             className={`validate ${this.fieldHasServiceError(selectedLocation, 'isra_reference') ? "invalid" : ""}`}/>
                      <label className="active" htmlFor="isra_reference">Referentie telefoonnummer</label>
                    </div>
                  </div>

                  <div className={'row'}>
                    <div className="input-field col s7">
                      <input name="isra_postal_code" value={selectedLocation.isra_postal_code}
                             onChange={this._onLocationPropertyChange} type="text"
                             className={`validate ${this.fieldHasServiceError(selectedLocation, 'isra_postal_code') ? "invalid" : ""}`}/>
                      <label className="active" htmlFor="isra_postal_code">Postcode</label>
                    </div>

                    <div className="input-field col s2">
                      <input name="isra_house_number" value={selectedLocation.isra_house_number}
                             onChange={this._onLocationPropertyChange} type="text" className="validate"/>
                      <label className="active" htmlFor="isra_house_number">Huisnummer</label>
                    </div>

                    <div className="input-field col s3">
                      <input name="isra_house_number_extension" value={selectedLocation.isra_house_number_extension}
                             onChange={this._onLocationPropertyChange} type="text" maxLength="30" minLength="0"
                             pattern="^([\w -]*)$" className="validate"/>
                      <label className="active" htmlFor="isra_house_number_extension">toevoeging</label>
                    </div>
                  </div>
                </div>
              ) : null
              }
            </fieldset>
          </DialogBody>
        }
        dialogFooter={
          <DialogFooter
            buttonLeft={
              this.hasIsraData() ? (
                <DialogButton
                  buttonAction={this.clearIsraData}
                  buttonText='Verwijder ISRA info'
                  left={true}
                  buttonDisabled={inputsDisabled}
                />
              ) : null
            }
            buttonRight={(
              <DialogButton
                buttonAction={this.saveUpdatedLocation}
                buttonText='Locatie wijzigen'
                buttonDisabled={inputsDisabled}
              />
            )}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({locations, quotation}) => {
  return {
    locations,
    quotation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    locationActions: bindActionCreators(locationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationForQuotation);
