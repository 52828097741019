export default {
  KPN: 0,
  SALESVALUE: 1,
  VODAFONE: 2,
  DBIT: 3,
  ODIDO: 4,
  FLASH: 5,
  TELE2: 6,
  PHC:7,
  VENECO:8,
  YIELDER: 9,

  LEAD: 10,

  KPN_PROVIDER_NAME: 'KPN ÉÉN',
  VODAFONE_PROVIDER_NAME: 'Vodafone',
  ODIDO_PROVIDER_NAME: 'Odido',
  FLASH_PROVIDER_NAME: 'Flash',
  TELE2_PROVIDER_NAME: 'Tele2',
  PHC_PROVIDER_NAME: 'PHC',
  VENECO_PROVIDER_NAME: 'Venéco',
  YIELDER_PROVIDER_NAME: 'Yielder',
}

export const odidoProviders = [4, 7, 8, 9];
export const ownBrandProviders = [7, 8, 9];