import _ from 'lodash';
import connect from "react-redux/es/connect/connect";
import "react-table/react-table.css";
import {bindActionCreators} from "redux";
import {toastr} from "react-redux-toastr";
import {poll} from "../../utils/utils";

import React, {Component, Fragment} from 'react';
import ReactTable from 'react-table';
import {Button, Icon, Input, Preloader} from "react-materialize";
import {DelayInput} from "react-delay-input";
import {apiRoutes} from "../../constants/apiRoutes";
import BulkUpload from "../workflow/info/BulkUpload";
import FileDownload from "js-file-download";
import RegularSelect from "../../components/RegularSelect";
import DeleteData from '../workflow/info/DeleteData';

import quotationActions from "../../actions/quotationActions";
import stepsActions from "../../actions/stepsActions";
import mobileActions from "../../actions/workflow/mobileActions";

import GeneralDialog from "../../containers/common/GeneralDialog";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DefaultDialogBody from "../../containers/common/KpnDialog/DefaultDialogBody";

import providerType, {odidoProviders} from "../../constants/providerType";
import quotationType from "../../constants/quotationType";
import productActionTypes from "../../constants/productActionTypes";
import {additionVamo, additionDataSim, addition} from "../../constants/telephoneNumberType";

class MobileTelephoneNumbersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      prevData: [],
      selectAll: this.makeStateSelectedAll(this.props.data),
      dialogOpened: false,
      vamoModalOpened: false,
      saveRequired: false,
      deleteDialogOpened: false,
      deleteDataDialogOpened: false,
      selectedPhoneNumber: {},
      uploadErrors: {},
      successBulkImport: false,
      importedRows: 0,
      loading: false,
      phoneNumberSelected: null,
      profilesProcessing: false,
      isExcludedPortfolio: false,
      hasNoPortfolioSelected: false,
      hideAdditionVamo: false,
      hideAdditionDataSim: false,
    }
  }

  componentDidMount() {
    this.toggleFlagForAllAKBNumbers = _.debounce(this.toggleFlagForAllAKBNumbers, 500);

    const self = this;
    this.saveAKBTelephoneNumberDebounced = _.wrap(_.memoize(function () {
      return _.debounce(self.saveAKBTelephoneNumber, 500);
    }, _.property('id')), function (func, obj) {
      return func(obj)(obj);
    });

    this.updateAdditionConditions();
  }

  componentDidUpdate(prevProps, prevState) {
    let {data, isUsingExistingData} = this.props;

    if (prevProps.data !== data) {
      this.setState({
        selectAll: this.makeStateSelectedAll(data),
        data: this.props.data,
        prevData: prevProps.data
      });
      this.updateAdditionConditions();
    }

    if (prevProps.isUsingExistingData !== isUsingExistingData) {
      this.updateAdditionConditions();
    }
  }

  updateAdditionConditions = () => {
    let {data, isVerlengenType, isUsingExistingData} = this.props;
    let additionVamoCondition = isVerlengenType && isUsingExistingData ? !data.filter((el) => el.action === addition && additionVamo.includes(el.additionType)).length : false;
    let additionDataSimCondition = isVerlengenType && isUsingExistingData ? !data.filter((el) => el.action === addition && additionDataSim.includes(el.additionType)).length : true;
    this.setState({hideAdditionVamo: additionVamoCondition, hideAdditionDataSim: additionDataSimCondition});
  };

  getHeaderCheckboxes = () => {
    return {
      guideListing: 'Gegevens opnemen in telefoongids',
      blockSim: 'SIM-Kaart blokkeren',
      blockOutgoingCall: 'Blokkeren uitgaande gesprekken',
      blockCallAbroad: 'Blokkeren bellen naar niet-NL nummers',
      blockOutgoingCallsExceptDomestic: 'Blokkeren uitgaande gesprekken behalve binnen NL en naar NL nummers',
      blockCallRoaming: 'Blokkeren roaming',
      blockIncomingCallsWhenRoaming: 'Blokkeren binnenkomende gesprekken in het buitenland',
      blockDataRoaming: 'Blokkeren data roaming',
      blockInformationNumbers: 'Blokkeren betaalde informatie- en servicenummers (0900)',
      blockPaidServices: 'Blokkeren entertainment nummers (0909 en 0906)',
      blockNumberRecognition: 'Blokkeren nummerherkenning',
      blockCallWaiting: 'Blokkeren wisselgesprek',
      blockVolte: 'Blokkeren 4G bellen',
      blockPremiumSms: 'Blokkeren premium SMS',
      dataRoamingLimitEnabled: '50 euro data roaming limiet',
      internationalCallForwardingEnabled: 'Automatische doorschakeling activeren'
    };
  };

  getActionOptions = (isVerlengenType, provider, telephoneNr = null) => {
    const isKPNProvider = provider === providerType.KPN;
    const isVodafoneProvider = provider === providerType.VODAFONE;
    const isOdidoProvider = odidoProviders.includes(provider);

    let options = [
      {
        key: 5,
        value: 'nieuw',
        name: isOdidoProvider ? 'Acquisitie' : 'nieuw'
      },
      {
        key: 1,
        value: 'porteren',
        name: isOdidoProvider ? 'Acquisitie met portering' : 'porteren'
      }
    ];

    if (isVodafoneProvider) {
      return options;
    }

    if (telephoneNr && isOdidoProvider ) {
      if (telephoneNr.isFetched) {
        options.splice(0, 2);
      }
    }

    if (this.props.isSalesValue) {
      options = options.concat([
        {
          key: 10,
          value: 'retentie',
          name: 'retentie',
        },
        {
          key: 9,
          value: 'toevoeging',
          name: 'toevoeging',
        }
      ])
    }

    if (! isOdidoProvider) {
      options = options.concat([
        {
          key: 6,
          value: isVerlengenType ? 'up/downgraden' : 'upgrade',
          name: isVerlengenType ? 'up/downgraden' : 'upgrade',
        },
        {
          key: 7,
          value: 'verlengen',
          name: 'verlengen',
        },
        {
          key: 8,
          value: 'opzeggen',
          name: 'opzeggen',
        },
        {
          key: 9,
          value: 'toevoeging',
          name: 'toevoeging',
        },
      ])
    }

    if (isOdidoProvider && isVerlengenType) {
      if (telephoneNr?.showRetention) {
        options = options.concat([
          {
            key: 11,
            value: 'reorder',
            name: 'Reorder',
          }
        ])
      }
    }

    if (! isOdidoProvider && !isKPNProvider) {
      options = options.concat([
        {
          key: 4,
          value: 'simless porteren',
          name: 'simless porteren'
        }
      ]);
    }

    return options;
  };

  getExampleFile = () => {
    const {isUsingExistingData, quotationId, locationId} = this.props;

    let url = apiRoutes.mobileRelated.getMobileBulkXlsExample
      .replace('{quotationId}', quotationId)
      .replace(/{locationId}/, locationId)
      .replace(/{existing}/, isUsingExistingData);

    this.props.mobileActions.getMobileBulkExampleFileForQuotation(quotationId, locationId, isUsingExistingData)
      .then(() => poll(url, {responseType: 'arraybuffer'}))
      .then((response) => FileDownload(response, quotationId + '-Bulk-upload-ratio-mobiele-telefoonnummers.xlsx'))
      .catch((e) => toastr.error('Kan het voorbeeldbestand niet downloaden', e.message));
  };

  saveAKBTelephoneNumber = (telephoneData, originalTelephoneNumbers) => {
    const currentQuotation = this.props.quotation.currentQuotation;
    const isOdidoExistingQuotation = currentQuotation.type === quotationType.TYPE_VERLENGEN && odidoProviders.includes(currentQuotation.provider);
    let product = [];
    if (this.props.quotationStatus) {
      return;
    }
    if (isOdidoExistingQuotation) {
      product['product'] = telephoneData.product;
    } else {
      product = this.state.prevData.find((el) => el.id === telephoneData.id);
    }

    this.props.quotationActions.saveAKBTelephoneNumber(telephoneData.id, telephoneData, this.props.quotationId, product ? product.product : null)
      .then((val) => {
        if (val && val.error) {
          this.setState({
            data: originalTelephoneNumbers
          });
        }
      }).then(() => this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId));
  };

  deletePhoneNumber = () => {
    if (this.props.quotationStatus) {
      return;
    }
    this.props.quotationActions.deleteAKBTelephoneNumber(this.state.selectedPhoneNumber);

    this.setState({deleteDialogOpened: false, selectedPhoneNumber: {}});
  };

  showDeletePhoneNumberDialog = (phoneNumber) => {
    if (this.props.quotationStatus) {
      return;
    }
    this.setState({deleteDialogOpened: true, selectedPhoneNumber: phoneNumber});
  }

  makeStateSelectAllForColumn(column, telephoneNumbers) {
    const checkedCount = telephoneNumbers.reduce((acc, crtElem) => {
      return crtElem[column] === true ? ++acc : acc;
    }, 0);
    return telephoneNumbers.length && telephoneNumbers.length === checkedCount ? 1 : 0;
  }

  makeStateSelectedAll(telephoneNumbers) {
    let selectAll = {};

    for (let checkboxIndex in this.getHeaderCheckboxes()) {
      selectAll[checkboxIndex] = this.makeStateSelectAllForColumn(checkboxIndex, telephoneNumbers)
    }

    return selectAll;
  }

  toggleSelect(column, index) {
    if (this.props.quotationStatus) {
      return;
    }

    let telephoneNumbers = [...this.state.data];

    if (column === 'blockInformationNumbers' || column === 'blockPaidServices') {
      telephoneNumbers[index] = {
        ...telephoneNumbers[index],
        'blockInformationNumbers': !telephoneNumbers[index][column],
        'blockPaidServices': !telephoneNumbers[index][column]
      };
    } else {
      telephoneNumbers[index] = {
        ...telephoneNumbers[index],
        [column]: !telephoneNumbers[index][column]
      };
    }

    const selectAll = {...this.state.selectAll};
    selectAll[column] = this.makeStateSelectAllForColumn(column, telephoneNumbers);

    let oldObj = [...this.props.data];
    const originalTelephoneNumbers = oldObj;
    let toUpdate = {};
    _.mergeWith(oldObj, telephoneNumbers, function (objectValue, sourceValue, key, object, source) {
      // todo: check why props gets updated
      if (!(_.isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
        toUpdate[object.id] = object;
      }
    });
    const newObj2 = {};
    telephoneNumbers.forEach((value) => {
      newObj2[value.id] = {...value};
    });

    this.props.quotationActions.updateAKBSelections({
      items: newObj2,
    })
      .then((val) => {
        if (val && val.error) {
          this.setState({data: originalTelephoneNumbers});
        } else {
          this.setState({data: telephoneNumbers});
        }
      });

    this.setState({selectAll: selectAll});
  };

  toggleFlagForAllAKBNumbers = (column, value, originalTelephoneNumbers) => {
    this.props.quotationActions.toggleFlagForAllAKBNumbers(this.props.quotationId, {
      flag: column,
      value: value
    }).then((val) => {
      if (val && val.error) {
        this.setState({
          data: originalTelephoneNumbers
        });
      }
    });
  };

  toggleSelectAll(column) {
    if (this.props.quotationStatus) {
      return;
    }

    const originalTelephoneNumbers = [...this.state.data];
    const telephoneNumbers = [...this.state.data];
    let selectAll = {...this.state.selectAll};

    this.state.data.forEach((x, index) => {
      if (telephoneNumbers[index]['action'] !== 8) {
        telephoneNumbers[index][column] = (selectAll[column] === 0);
      }
    });
    selectAll[column] = this.state.selectAll[column] === 0 ? 1 : 0;

    let oldObj = [...this.props.data];
    let toUpdate = {};
    _.mergeWith(oldObj, telephoneNumbers, function (objectValue, sourceValue, key, object, source) {
      // todo: check why props gets updated
      if (!(_.isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
        toUpdate[object.id] = object;
      }
    });
    const newObj2 = {};
    telephoneNumbers.forEach((value) => {
      newObj2[value.id] = {...value};
    });

    this.props.quotationActions.updateAKBSelections({
      items: newObj2,
    })
      .then((val) => {
        if (val && val.error) {
          this.setState({data: originalTelephoneNumbers});
        } else {
          this.setState({data: telephoneNumbers});
        }
      });

    this.setState({selectAll: selectAll});
  }

  renderActionOptions = (number) => {
    const {quotation, isUsingExistingData} = this.props;
    const {currentQuotation} = quotation;
    const {type, provider} = currentQuotation;

    const isExistingType = type === quotationType.TYPE_EXISTING;
    const isVerlengenType = type === quotationType.TYPE_VERLENGEN;
    const isExistingException = isExistingType && number.custom;
    let actionsToShow = [];

    const actions = this.getActionOptions(isVerlengenType, provider, number);

    if (number.isFetched) {
      actionsToShow = actions.filter((el) => ['nieuw', 'porteren', 'simless porteren', 'reorder', 'reorder porteren'].includes(el.value));
    } else {
      actionsToShow = actions.filter((el) => ['nieuw', 'porteren', 'simless porteren'].includes(el.value));
    }

    const existingActionsCondition = isExistingType && !isExistingException;
    const verlengenActionsCondition = isVerlengenType && isUsingExistingData;

    const renderActions = existingActionsCondition || verlengenActionsCondition ? actions : actionsToShow;

    return renderActions.map((el, idx) => {
      return <option value={el.key} key={idx}>{el.name}</option>
    })
  };

  getSelectedProductName = (data) => {
    let product = null;

    if (data.profiles && data.profiles.length && [productActionTypes.UPGRADE].includes(data.action)) {
      let products = [];

      data.profiles.forEach((el) => {
        const item = this.props.getAllMobileProducts.find((item) => item.productNumber === el.product_id);

        if (item) {
          products.push(item);
        }
      });

      product = products.find((item) => item.id === data.product);
    } else {
      product = this.props.mobileProducts.find(item => item.id === data.product);
    }

    return product ? product.name : '';
  };

  getSelectedVamoProductName = (data) => {
    const product = this.props.vamoProducts.find(item => item.productNumber === data.vamoProductNumber);

    return product ? product.name : '';
  };

  getSelectedDataSimAmountOption = (telephoneNumber) => {
    return telephoneNumber.dataSimAmount ? `${telephoneNumber.dataSimAmount}x ${telephoneNumber.dataSimOption}` : '';
  };

  onChange = (e, index, inputType) => {
    if (this.props.quotationStatus) {
      return;
    }

    let {name, value} = e.target;
    if (inputType === 'delay-input') {
      name = e.nativeEvent.target.name;
      value = e.nativeEvent.target.value;
    }

    const originalTelephoneNumbers = [...this.state.data];
    const telephoneNumbers = [...this.state.data];
    let telephoneNumber = telephoneNumbers[index];

    //do not trigger change if it is selected the same value
    if (telephoneNumber[name] && telephoneNumber[name].toString() === value.toString()) {
      return;
    }

    if (name === 'product' && value !== "") {
      //verify if the product can be selected
      const {mobileProducts, getAllMobileProducts} = this.props;
      const allMobileProducts = getAllMobileProducts.concat(mobileProducts);

      const found = allMobileProducts.find(product => {
        return product.id === parseInt(value)
      });
      if (!found) {
        return;
      }

      const selectedCount = telephoneNumbers.reduce((acc, crtElem) => {
        return parseInt(crtElem.product) === parseInt(value) ? ++acc : acc;
      }, 0);

      if (selectedCount >= found.quantity) {
        this.openDialog();
        return;
      }
    }

    if (!['number', 'vamoProductNumber', 'sim_number'].includes(name)) {
      value = value ? parseInt(value) : ""
    }

    // Make sure that the vamo selected amount is not greater then the vamo amount selected on profile level
    let vamoQuantity = 0;
    if (name === 'vamoProductNumber' && value) {
      let telephoneNumberSelectionId = telephoneNumber.selectionId;
      let telephoneNumberHasAddition = telephoneNumber.hasAddition;
      let addonsList = this.props.mobileProductOptions[telephoneNumberSelectionId];

      if (!addonsList && !telephoneNumberHasAddition) {
        return;
      }

      if (telephoneNumberHasAddition) {
        if (!telephoneNumber.vamoOptions.includes(value)) {
          return;
        }
      } else {
        let selectedAddon = this.props.mobileProductOptions[telephoneNumberSelectionId]
          .find(($el) => $el['category']['product_number'] === 'VASTMOBOPT');

        if (selectedAddon) {
          let selectedRowAddon = selectedAddon.selectedProducts.find((el) => el.product_number === value)
          vamoQuantity = selectedRowAddon ? selectedRowAddon['quantity'] : 0;
        }

        let numbersWithTheSameProfile = telephoneNumbers.filter((el) => el.product === telephoneNumber.product)
        let sameVamoProducts = numbersWithTheSameProfile.filter((el) => el.vamoProductNumber === value)

        if (vamoQuantity < sameVamoProducts.length + 1 && !telephoneNumberHasAddition) {
          this.setState({vamoModalOpened: true});
          return;
        }
      }
    }

    telephoneNumbers[index] = {
      ...telephoneNumbers[index],
      [name]: value
    };
    this.setState({
      data: telephoneNumbers
    });

    this.saveAKBTelephoneNumberDebounced(telephoneNumbers[index], originalTelephoneNumbers);
  };

  getColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 400;
    const magicSpacing = 10;
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );

    return Math.min(maxWidth, cellLength * magicSpacing) + 15;
  };

  closeUploadErrorsDialog = () => {
    this.setState({
      uploadErrors: {},
      isExcludedPortfolio: false,
      hasNoPortfolioSelected: false
    });
  };

  closeVamoModal = () => {
    this.setState({vamoModalOpened: false});
  };

  closeDialog = () => {
    this.setState({dialogOpened: false});
  };

  openDialog = () => {
    this.setState({dialogOpened: true});
  };

  hideDeleteDialog = () => {
    this.setState({deleteDialogOpened: false, selectedPhoneNumber: {}})
  }

  calculateMinHeight = () => {
    const height = this.props.mobileProducts
      ? Math.max((this.props.mobileProducts.length * 50) + 20 /*select scroll-x*/,
      this.getActionOptions().length * 50) /*Maximum between mobile profiles and action selectboxes height*/
      + 106 /*table header height*/
      + 20 /*padding*/
      : 300;
    return height + 'px';
  };

  _handleDialogOpen = () => {
    if (this.props.quotationStatus) {
      return;
    }
    this.setState({bulkUploadDialogOpened: true});
  };

  _handleDialogClose = () => {
    this.setState({
      bulkUploadDialogOpened: false,
      file: null
    });
  };

  _onDrop = (files) => {
    let file = files[0];

    if (file) {
      this.setState({file: file});
    }
  };

  _handleDeleteDataDialogOpen = () => {
    this.setState({deleteDataDialogOpened: true});
  };

  _handleDeleteDataDialogClose = () => {
    this.setState({deleteDataDialogOpened: false});
  };

  deleteAllData = () => {
    if (this.props.quotationStatus) {
      return;
    }

    this.setState({profilesProcessing: true});

    this.props.quotationActions.deleteAllMobileOptions(this.props.quotationId, this.props.locationId, 'mobiele', 'deleteAllMobileOptions')
      .then(() => {
        this.props.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locationId);
        this.setState({profilesProcessing: false});
      });

    this._handleDeleteDataDialogClose();
  };

  importBulkTemplateMobile = () => {
    const {isUsingExistingData, quotationId, locationId} = this.props;

    this.setState({profilesProcessing: true});

    this.props.quotationActions.bulkImport(quotationId, locationId, this.state.file, 'mobile', isUsingExistingData).then((response) => {
      if (response) {
        if (response.errors) {
          this.setState({
            isExcludedPortfolio: response.isExcludedPortfolio,
            hasNoPortfolioSelected: response.hasNoPortfolioSelected,
            uploadErrors: response.errors,
            profilesProcessing: false
          });
        }

        if (response.importedRows) {
          this.setState({
            successBulkImport: true,
            importedRows: response.importedRows,
            profilesProcessing: false
          });
          this.props.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locationId);

          if (isUsingExistingData) {
            this.props.quotationActions.getPhoneNumbers(quotationId);
          }
        }
      } else {
        this.props.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locationId);
        this.setState({profilesProcessing: false});
      }
    });
  };

  save = () => {
    if (this.props.quotationStatus) {
      return;
    }

    this.importBulkTemplateMobile();

    this._handleDialogClose();
  };

  closeSuccessDialog = () => {
    this.setState({
      successBulkImport: false,
      importedRows: 0
    });

    this.props.showAddonsImportDialog();
  };

  patchMobileSettings = (phoneNumber) => {
    if (this.props.quotationStatus) {
      return;
    }

    this.setState({loading: true, phoneNumberSelected: phoneNumber});

    this.props.quotationActions.patchMobileSettings(this.props.quotationId, phoneNumber)
      .then(() => this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId))
      .then(() => this.setState({loading: false}));
  };

  renderSuccessMessage = (rowsImported) => {
    return (
      <GeneralDialog
        dialogState={this.state.successBulkImport}
        closeHandler={this.closeSuccessDialog}
        headerText={'Gelukt!'}
        headerIcon={'folder_open'}
        dialogBodyContent={(
          <div className="input-field col s12" style={{margin: '10px 0 20px'}}>
            Uw bulk-upload is succesvol toegevoegd. Er zijn {rowsImported} telefoonnummers toegevoegd.
          </div>
        )}
        rightButtonAction={this.closeSuccessDialog}
        rightButtonText={'Ik heb dit begrepen'}
      />
    );
  };

  generateErrorRows() {
    return (
      <tbody>
      {Object.keys(this.state.uploadErrors).map(key => (
        this.state.uploadErrors[key].map((el, index) => (
          <tr key={`${key}${index}`}>
            <th
              scope="row"
              style={{padding: '10px 5px 10px 15px'}}
            >{key}</th>

            <td
              style={{borderRightColor: '#FFF', padding: '10px 5px'}}
            >{el}</td>
          </tr>
        ))
      ))}
      </tbody>
    )
  };

  renderDevicePaymentOptions = (productId) => {
    const {mobileOfferYear, devicePaymentOptions} = this.props;
    let options = [];
    options.push(<option value="" key={0}/>);

    if (!mobileOfferYear
      || parseInt(mobileOfferYear) === 2016
      || !productId
      || this.props.mobileProducts.findIndex((elem) => elem.id === productId) === -1
    ) {
      return options;
    }

    devicePaymentOptions.forEach((el) => {
      options.push(<option value={el.id} key={el.id}>{el.name}</option>);
    });

    return options;
  };

  getKPNColumns = () => {
    const {data, inputsDisabled, PreloaderParent, quotation, isUsingExistingData, isVerlengenType} = this.props;
    const {currentQuotation: {type, provider}} = quotation;
    const {authentication: {isSalesValue}} = this.props;

    let headerCheckboxes = this.getHeaderCheckboxes();
    if (isVerlengenType) {
      headerCheckboxes = {};
    }

    let columns = [
      {
        id: 'number',
        accessor: 'number',
        Cell: ({original, index}) => {
          return (
            <DelayInput
              delayTimeout={6000}
              type="text"
              name="number"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || (isVerlengenType && isUsingExistingData) || [6, 7, 8, 9, 10].includes(original.action)}
              pattern="^(\d{10}|\d{12})$"
              maxLength="12"
              minLength="10"
              className="validate"
              value={original.number}
            />
          )
        },
        Header: 'Telefoonnummer',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      },
      {
        id: 'action',
        Cell: ({original, index}) => {
          return (
            <RegularSelect
              s={12}
              name="action"
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.action}
              disabled={inputsDisabled || [6, 7, 8, 9, 10].includes(original.action)}
              multiple={false}>
              {this.renderActionOptions(original)}
            </RegularSelect>
          )
        },
        Header: 'Maak een keuze',
        className: 'col-action col-select actionSelection',
        headerClassName: 'head-action alignleft',
        width: this.getColumnWidth(data, 'action', 'Maak een keuze'),
      },
      {
        id: "product",
        Cell: ({original, index}) => {
          return (
            <div style={{
              padding: '10px',
              textAlign: 'center',
              display: 'block',
              position: 'relative',
              width: '100%',
              height: '3rem',
              border: '1px solid #939393',
              opacity: 1,
              pointerEvents: 'initial',
              borderRadius: '4px'
            }}>
              {this.getSelectedProductName(original)}
            </div>
          )
        },
        Header: 'Profiel',
        className: 'col-product col-select actionSelection',
        headerClassName: 'head-product alignleft',
        // width: this.getColumnWidth(data, 'action', 'Maak een keuze'),
        minWidth: 300,
        disable: isUsingExistingData && isVerlengenType
      },
    ];

    columns.push({
      id: "devicePayment",
      Cell: ({original, index}) => {
        return (
          <RegularSelect
            name="devicePayment"
            type='select'
            onChange={(event) => this.onChange(event, index)}
            value={parseInt(original.devicePayment) || ''}
            multiple={false}
            disabled={inputsDisabled}
          >
            {this.renderDevicePaymentOptions(original.product)}
          </RegularSelect>
        )
      },
      Header: 'Toestelbundel',
      className: 'col-product col-select actionSelection',
      headerClassName: 'head-product alignleft',
      minWidth: 200,
      disable: isUsingExistingData && isVerlengenType
    });

    columns.push({
      id: 'dataSim',
      Cell: ({original, index}) => {
        let actionCondition = [6, 7, 8, 10].includes(original.action);
        let additionException = (original.action === addition && !additionDataSim.includes(original.additionType)) || !original.dataSimOption;

        return (
          (actionCondition || additionException) && (isVerlengenType && isUsingExistingData) ?
            <div style={{
              padding: '10px',
              textAlign: 'center'
            }}>
              {this.getSelectedDataSimAmountOption(original)}
            </div> :
            <RegularSelect
              name='dataSimAmount'
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.dataSimAmount}
              disabled={inputsDisabled || [7, 8].indexOf(original.action) !== -1}
              multiple={false}>
              <option value="" key={0}/>
              {[1, 2, 3].map((dataSimAmount) => <option value={dataSimAmount}
                                                        key={dataSimAmount}>{`${dataSimAmount}x ${original.dataSimOption}`} </option>)}
            </RegularSelect>
        )
      },
      Header: 'Aantal data sims',
      className: 'col-action col-select actionSelection',
      headerClassName: 'head-product alignleft',
      minWidth: 250,
      disable: this.state.hideAdditionDataSim
    });

    columns.push({
      id: 'vamo',
      Cell: ({original, index}) => {
        let actionCondition = [6, 7, 8, 10].includes(original.action);
        let additionException = (original.action === addition && !additionVamo.includes(original.additionType)) || (original.vamoOptions && !original.vamoOptions.length);

        return (
          (actionCondition || additionException) && (isVerlengenType && isUsingExistingData) ?
            <div style={{
              padding: '10px',
              textAlign: 'center'
            }}>
              {this.getSelectedVamoProductName(original)}
            </div> :
            <RegularSelect
              name='vamoProductNumber'
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.vamoProductNumber}
              disabled={inputsDisabled || [7, 8].indexOf(original.action) !== -1}
              multiple={false}>
              <option value="" key={0}/>
              {this.props.vamoProducts.map((product) =>
                <option value={product.productNumber} key={product.productNumber}>{product.name}</option>
              )}
            </RegularSelect>
        )
      },
      Header: 'Vamo module',
      className: 'col-action col-select actionSelection',
      headerClassName: 'head-product alignleft',
      minWidth: 250,
      disable: this.state.hideAdditionVamo
    });

    if (!isVerlengenType && !isSalesValue) {
      columns.push({
        id: 'orderId',
        accessor: 'orderId',
        Cell: ({original, index}) => {
          return (
            <DelayInput
              delayTimeout={2000}
              type="text"
              name="orderId"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || [1, 4, 6, 7, 8, 9, 10].includes(original.action)}
              value={original.orderId ? original.orderId : ''}
            />
          )
        },
        Header: 'OrderID',
        headerClassName: 'alignleft',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      });

      for (let checkboxKey in headerCheckboxes) {
        columns.push({
          id: checkboxKey,
          accessor: "",
          Cell: ({original, index}) => {
            return (
              <Input
                type="checkbox"
                name={`${checkboxKey}-${original.id}`}
                label=" "
                className="custom-checkbox checkbox"
                disabled={(inputsDisabled || isVerlengenType) || [1, 4, 8].includes(original.action)}
                checked={original[checkboxKey] === true}
                onChange={() => this.toggleSelect(checkboxKey, index)}
              />
            );
          },
          Header: () => {
            return (
              <div style={isVerlengenType && isUsingExistingData ? {whiteSpace: "initial"} : {}}>
                <div>
                  {isVerlengenType && isUsingExistingData ?
                    (
                      <span>{headerCheckboxes[checkboxKey]}</span>
                    ) : (
                      <Input
                        type="checkbox"
                        label={headerCheckboxes[checkboxKey]}
                        name={`all_${checkboxKey}`}
                        className="custom-checkbox checkbox"
                        disabled={inputsDisabled}
                        checked={this.state.selectAll[checkboxKey] === 1}
                        ref={input => {
                          if (input) {
                            input.indeterminate = this.state.selectAll[checkboxKey] === 2;
                          }
                        }}
                        onChange={() => this.toggleSelectAll(checkboxKey)}
                      />
                    )
                  }
                </div>
              </div>
            );
          },
          sortable: false,
          resizable: false,
          className: 'col-checkbox',
          headerClassName: 'head-checkbox alignleft',
          minWidth: 180,
          // width: this.getColumnWidth(data, '', headerCheckboxes[index]),
        });
      }

      if (!isVerlengenType) {
        columns.push({
          id: 'opties',
          Cell: ({original}) => {
            return (
              [1, 5, 4].includes(original.action) ? '' :
                <Button
                  waves="light"
                  className="doceri-btn-right"
                  disabled={inputsDisabled || [1, 4, 8].includes(original.action)}
                  onClick={() => this.patchMobileSettings(original.number)}
                  style={{width: 130, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                  {this.state.loading && this.state.phoneNumberSelected === original.number ?
                    <PreloaderParent>
                      <Preloader/>
                    </PreloaderParent> :
                    'Opties ophalen'
                  }
                </Button>
            )
          },
          Header: 'Opties',
          headerClassName: 'alignleft',
          minWidth: 140
        });
      }

      columns.push({
        id: 'delete',
        Cell: ({original}) => {
          return (
            <Button
              waves="light"
              className="doceri-btn-right"
              disabled={inputsDisabled}
              onClick={() => this.showDeletePhoneNumberDialog(original)}
            >
              <i className="large material-icons">clear</i>
            </Button>
          )
        },
        Header: 'Verwijderen',
        headerClassName: 'alignleft'
      });
    }

    columns = columns.filter(item => {
        if (item.hasOwnProperty('disable')) {
          return !item['disable'];
        }

        if (isVerlengenType) {
          return ['orderId'].indexOf(item.id) === -1;
        }

        if (isVerlengenType && isUsingExistingData) {
          return ['action'].indexOf(item.id) === -1;
        }

        return true;
      }
    );

    return columns;
  };

  getVodafoneColumns = () => {
    const {data, inputsDisabled, isUsingExistingData, isVerlengenType} = this.props;
    const {authentication: {isSalesValue}} = this.props;

    let headerCheckboxes = this.getHeaderCheckboxes();
    if (isVerlengenType) {
      headerCheckboxes = {};
    }

    let columns = [
      {
        id: 'number',
        accessor: 'number',
        Cell: ({original, index}) => {
          return (
            <DelayInput
              delayTimeout={6000}
              type="text"
              name="number"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || (isVerlengenType && isUsingExistingData) || [6, 7, 8, 9, 10].includes(original.action)}
              pattern="^(\d{10}|\d{12})$"
              maxLength="12"
              minLength="10"
              className="validate"
              value={original.number}
            />
          )
        },
        Header: 'Telefoonnummer',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      },
      {
        id: 'action',
        Cell: ({original, index}) => {
          return (
              <RegularSelect
                  s={12}
                  name="action"
                  type='select'
                  onChange={(event) => this.onChange(event, index)}
                  value={original.action}
                  disabled={inputsDisabled}
                  multiple={false}>
                {this.renderActionOptions(original)}
              </RegularSelect>
          )
        },
        Header: 'Maak een keuze',
        className: 'col-action col-select actionSelection',
        headerClassName: 'head-action alignleft',
        width: this.getColumnWidth(data, 'action', 'Maak een keuze'),
      },
      {
        id: "product",
        Cell: ({original, index}) => {
          return (
            <div style={{
              padding: '10px',
              textAlign: 'center',
              display: 'block',
              position: 'relative',
              width: '100%',
              height: '3rem',
              border: '1px solid #939393',
              opacity: 1,
              pointerEvents: 'initial',
              borderRadius: '4px'
            }}>
              {this.getSelectedProductName(original)}
            </div>
          )
        },
        Header: 'Profiel',
        className: 'col-product col-select actionSelection',
        headerClassName: 'head-product alignleft',
        // width: this.getColumnWidth(data, 'action', 'Maak een keuze'),
        minWidth: 300,
        disable: isUsingExistingData && isVerlengenType
      },
    ];

    if (!isVerlengenType && !isSalesValue) {
      columns.push({
        id: 'orderId',
        accessor: 'orderId',
        Cell: ({original, index}) => {
          return (
            <DelayInput
              delayTimeout={2000}
              type="text"
              name="orderId"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || [1, 4, 6, 7, 8, 9, 10].includes(original.action)}
              value={original.orderId ? original.orderId : ''}
            />
          )
        },
        Header: 'OrderID',
        headerClassName: 'alignleft',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      });

      for (let checkboxKey in headerCheckboxes) {
        columns.push({
          id: checkboxKey,
          accessor: "",
          Cell: ({original, index}) => {
            return (
              <Input
                type="checkbox"
                name={`${checkboxKey}-${original.id}`}
                label=" "
                className="custom-checkbox checkbox"
                disabled={(inputsDisabled || isVerlengenType) || [1, 4, 8].includes(original.action)}
                checked={original[checkboxKey] === true}
                onChange={() => this.toggleSelect(checkboxKey, index)}
              />
            );
          },
          Header: () => {
            return (
              <div style={isVerlengenType && isUsingExistingData ? {whiteSpace: "initial"} : {}}>
                <div>
                  {isVerlengenType && isUsingExistingData ?
                    (
                      <span>{headerCheckboxes[checkboxKey]}</span>
                    ) : (
                      <Input
                        type="checkbox"
                        label={headerCheckboxes[checkboxKey]}
                        name={`all_${checkboxKey}`}
                        className="custom-checkbox checkbox"
                        disabled={inputsDisabled}
                        checked={this.state.selectAll[checkboxKey] === 1}
                        ref={input => {
                          if (input) {
                            input.indeterminate = this.state.selectAll[checkboxKey] === 2;
                          }
                        }}
                        onChange={() => this.toggleSelectAll(checkboxKey)}
                      />
                    )
                  }
                </div>
              </div>
            );
          },
          sortable: false,
          resizable: false,
          className: 'col-checkbox',
          headerClassName: 'head-checkbox alignleft',
          minWidth: 180,
          // width: this.getColumnWidth(data, '', headerCheckboxes[index]),
        });
      }

      columns.push({
        id: 'delete',
        Cell: ({original}) => {
          return (
            <Button
              waves="light"
              className="doceri-btn-right"
              disabled={inputsDisabled}
              onClick={() => this.showDeletePhoneNumberDialog(original)}
            >
              <i className="large material-icons">clear</i>
            </Button>
          )
        },
        Header: 'Verwijderen',
        headerClassName: 'alignleft'
      });
    }

    columns = columns.filter(item => {
        if (item.hasOwnProperty('disable')) {
          return !item['disable'];
        }

        if (isVerlengenType) {
          return ['orderId'].indexOf(item.id) === -1;
        }

        if (isVerlengenType && isUsingExistingData) {
          return ['action'].indexOf(item.id) === -1;
        }

        return true;
      }
    );

    return columns;
  };

  renderOdidoProfiles = (telephoneNumber) => {
    const { isFetched } = telephoneNumber;

    let currentlySelectedProfiles = this.props.mobileProducts;

    const filteredSelectedProfiles = currentlySelectedProfiles.filter(profile => isFetched && profile.availableScenarios && profile.availableScenarios.includes('Retention'));

    return filteredSelectedProfiles.map((el, idx) => {
      return <option value={el.id} key={idx}>{el.name}</option>
    })
  }

  getOdidoColumns = () => {
    const {data, inputsDisabled, isUsingExistingData, isVerlengenType} = this.props;
    const existingDataCondition = isVerlengenType && isUsingExistingData;

    let columns = [
      {
        id: 'number',
        accessor: 'number',
        Cell: ({original, index}) => {
          let disabledAction = [6, 7, 8, 9, 10].includes(original.action);

          return (
            <DelayInput
              delayTimeout={6000}
              type="text"
              name="number"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || existingDataCondition || disabledAction}
              pattern="^(\d{10}|\d{12})$"
              maxLength="12"
              minLength="10"
              className="validate"
              value={original.number}
            />
          )
        },
        Header: 'Telefoonnummer',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      },
      {
        id: 'scenario',
        accessor: 'scenario',
        Cell: ({original, index}) => {
          let disabledAction = [6, 7, 8, 9, 10].includes(original.action);
          const disableIfProfileNotSelected = !original.product;

          return (
            <RegularSelect
              s={12}
              name="action"
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.action}
              disabled={inputsDisabled || disabledAction || disableIfProfileNotSelected}
              multiple={false}>
              {this.renderActionOptions(original)}
            </RegularSelect>
          )
        },
        Header: 'Scenario',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: 250,
      },
      {
        id: "product",
        Cell: ({original, index}) => {
          if (!original.isFetched) {
            return (
                <div style={{
                  padding: '10px',
                  textAlign: 'center',
                  display: 'block',
                  position: 'relative',
                  width: '100%',
                  height: '3rem',
                  border: '1px solid #939393',
                  opacity: 1,
                  pointerEvents: 'initial',
                  borderRadius: '4px'
                }}>
                  {this.getSelectedProductName(original)}
                </div>
            )
          } else {
            return (
                <RegularSelect
                    s={12}
                    name="product"
                    type='select'
                    onChange={(event) => this.onChange(event, index)}
                    value={original.product}
                    disabled={inputsDisabled}
                    multiple={false}>
                  <option value="-1" key="-1">Selecteer</option>
                  {this.renderOdidoProfiles(original)}
                </RegularSelect>
            )
          }
        },
        Header: 'Profiel',
        className: 'col-product col-select actionSelection',
        headerClassName: 'head-product alignleft',
        minWidth: 200,
        disable: existingDataCondition
      },
      {
        id: 'simCardProduct',
        Cell: ({original, index}) => {
          let disabledAction = [6, 7, 8, 9, 10].includes(original.action);

          return (
            <RegularSelect
              name='simCardProduct'
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.simCardProduct}
              disabled={inputsDisabled || disabledAction}
              multiple={false}
            >
              {this.getSimCardProductOptions(original)}
            </RegularSelect>
          )
        },
        Header: 'Simkaartproduct',
        className: 'col-action col-select actionSelection',
        headerClassName: 'head-product alignleft',
        minWidth: 250,
        disable: existingDataCondition
      },
      {
        id: 'simCardVariation',
        Cell: ({original, index}) => {
          let disabledAction = [6, 7, 8, 9, 10].includes(original.action);

          return (
            <RegularSelect
              name='simCardVariation'
              type='select'
              onChange={(event) => this.onChange(event, index)}
              value={original.simCardVariation}
              disabled={inputsDisabled || disabledAction}
              multiple={false}
            >
              {this.getSimCardVariationOptions(original)}
            </RegularSelect>
          )
        },
        Header: 'Simkaartvariatie',
        className: 'col-action col-select actionSelection',
        headerClassName: 'head-product alignleft',
        minWidth: 200,
        disable: existingDataCondition
      },
      {
        id: 'simNumber',
        accessor: 'simNumber',
        Cell: ({original, index}) => {
          return original.isESimType ? (
            <div style={{
              padding: '10px',
              textAlign: 'center'
            }}>
              Sim-nummer niet mogelijk voor ESIM
            </div>
          ) : (
            <DelayInput
              delayTimeout={6000}
              type="text"
              name="sim_number"
              onChange={(event) => this.onChange(event, index, 'delay-input')}
              disabled={inputsDisabled || (isVerlengenType && isUsingExistingData) || [6, 7, 8, 9, 10].includes(original.action)}
              pattern="^(\d{8}|\d{19})$"
              maxLength="19"
              minLength="8"
              className="validate"
              value={original.sim_number}
            />
          );
        },
        Header: 'SIM Number',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: 250,
      },
      {
        id: 'imei',
        accessor: 'imei',
        Cell: ({original, index}) => {
          return (
              <DelayInput
                  delayTimeout={6000}
                  type="text"
                  name="imei"
                  onChange={(event) => this.onChange(event, index, 'delay-input')}
                  disabled={inputsDisabled || original.isSimOnly}
                  pattern="^[A-Za-z0-9]{15}$"
                  className="validate"
                  value={original.imei}
              />
          );
        },
        Header: 'IMEI',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number alignleft',
        minWidth: 250,
      },
    ];

    return columns;
  };

  getSimCardProductOptions = (telephoneNumber) => {
    const {simCardProducts} = this.props;

    const availableSimProducts = telephoneNumber.simCardOptions || [];
    let filteredSimCardProducts = simCardProducts.filter((simProduct) => availableSimProducts.includes(simProduct.id));

    let options = [];
    options.push(<option key={'no-selection'} value={null}>Geen simkaartproduct</option>);

    filteredSimCardProducts.forEach((simProduct) =>
      options.push(<option key={simProduct.id} value={simProduct.id}>{simProduct.name}</option>)
    );

    return options;
  };

  getSimCardVariationOptions = (telephoneNumber) => {
    const {simCardProducts} = this.props;

    if (! telephoneNumber.simCardProduct) {
      return <option key={'no-selection'} value={null}>Geen simkaartproduct geselecteerd</option>
    }

    let simCardProduct = simCardProducts.find((simProduct) => simProduct.id === parseInt(telephoneNumber.simCardProduct));
    if (! simCardProduct) {
      return <option key={'no-selection'} value={null}>Geen simkaartvariatie beschikbaar</option>
    }

    if (simCardProduct.productNumber === 'SIMDAT' && ![1, 5].includes(telephoneNumber.action)) {
      return <option key={'no-selection'} value={null}>Geen simkaartvariatie beschikbaar</option>
    }

    let simCardVariations = simCardProduct.options;
    let filteredSimCardVariations = simCardVariations.filter((simVariation) => {
      return simVariation.category.product_number.includes('MOBILEPOSTPAIDSIM-CARD');
    });

    if (!filteredSimCardVariations.length) {
      return <option key={'no-selection'} value={null}>Geen simkaartvariatie beschikbaar</option>
    }

    let options = [];
    options.push(<option key={'no-selection'} value={null}>Geen simkaartvariatie</option>);

    filteredSimCardVariations.forEach((simVariation) => {
      simVariation.products.forEach((simVariationProduct) => {
        options.push(<option key={simVariationProduct.id} value={simVariationProduct.id}>{simVariationProduct.name}</option>)
    })});

    return options;
  };

  render() {
    const {inputsDisabled, quotation, isUsingExistingData, isVerlengenType} = this.props;
    const {currentQuotation: {type, provider}} = quotation;
    const isExistingType = type === quotationType.TYPE_EXISTING;
    const isOdidoProvider = odidoProviders.includes(provider);
    const isVodafoneProvider = provider === providerType.VODAFONE;

    let columns;
    if (isOdidoProvider) {
      columns = this.getOdidoColumns();
    }
    if (isVodafoneProvider) {
      columns = this.getVodafoneColumns();
    }

    if (! isOdidoProvider && !isVodafoneProvider) {
      columns = this.getKPNColumns();
    }

    return (
      <div>
        {this.state.successBulkImport && this.renderSuccessMessage(this.state.importedRows)}
        <GeneralDialog
          dialogState={!!(Object.keys(this.state.uploadErrors).length && !this.state.hasNoPortfolioSelected)}
          closeHandler={this.closeUploadErrorsDialog}
          headerText={'Toewijzen niet mogelijk'}
          dialogBodyContentRaw={(
            <DialogBody>
              <DefaultDialogBody text="De volgende punten moeten aangepast worden in uw bestand.
               Daarna kunt u het bestand nogmaals uploaden."/>
              {this.state.isExcludedPortfolio && (
                <div>
                  <div style={{marginBottom: '10px'}}>
                    Het orderen van producten binnen het profiel 2016 is niet meer mogelijk. In de bulk upload
                    herkennen wij de profielen
                    als behorende tot 2016 en kunnen deze daarom niet accepteren. Als je wenst te upgraden naar
                    een huidige profiel, dan dien
                    je in de excel de gewenste nieuwe profiel te selecteren en ook het juiste (nieuwe) jaartal te
                    selecteren in je offerte in Ratio.
                    Daarna kun je de bulk weer uploaden.
                  </div>
                  <div style={{marginBottom: '10px'}}>
                    LET OP: Mobiele nummers met profiel 2016 kun je niet porteren, als je verlengen kiest, zal
                    deze door KPN worden omgezet naar een upgrade.
                  </div>
                  <div style={{marginBottom: '10px'}}>
                    LET OP: vergeet niet alle actieve orders mee te nemen!
                  </div>
                </div>
              )}

              <table className="bordered" style={{borderColor: '#FFF', margin: '10px 0'}}>
                <thead className="white">
                <tr>
                  <th scope="col">Rij</th>
                  <th scope="col">Fout</th>
                </tr>
                </thead>
                {this.generateErrorRows()}
              </table>
            </DialogBody>
          )}
        />

        <GeneralDialog
          dialogState={this.state.isExcludedPortfolio && this.state.hasNoPortfolioSelected}
          closeHandler={this.closeUploadErrorsDialog}
          headerText={'Toewijzen niet mogelijk'}
          dialogBodyContentRaw={(
            <DialogBody>
              <DefaultDialogBody text="De volgende punten moeten aangepast worden in uw bestand.
               Daarna kunt u het bestand nogmaals uploaden."/>
              <div style={{marginBottom: '10px'}}>
                Het orderen van producten binnen het profiel 2016 is niet meer mogelijk. In de bulk upload herkennen wij
                de profielen
                als behorende tot 2016 en kunnen deze daarom niet accepteren. Als je wenst te upgraden naar een huidige
                profiel, dan dien
                je in de excel de gewenste nieuwe profiel te selecteren en ook het juiste (nieuwe) jaartal te selecteren
                in je offerte in Ratio.
                Daarna kun je de bulk weer uploaden.
              </div>
              <div style={{marginBottom: '10px'}}>
                LET OP: Mobiele nummers met profiel 2016 kun je niet porteren, als je verlengen kiest, zal deze door KPN
                worden omgezet naar een upgrade.
              </div>
              <div style={{marginBottom: '10px'}}>
                LET OP: vergeet niet alle actieve orders mee te nemen!
              </div>
            </DialogBody>
          )}
          leftButtonAction={this.closeUploadErrorsDialog}
          leftButtonText={'Ik heb dit begrepen'}
          leftButtonLeft={true}
        />

        <GeneralDialog
          dialogState={this.state.dialogOpened}
          closeHandler={this.closeDialog}
          headerText={'Toewijzen niet mogelijk'}
          dialogBodyContent={(
            <DefaultDialogBody text='Een profiel kan niet vaker aan een nummer toegewezen worden dan dat het
                                bovenaan deze pagina is opgegeven. Wijs een ander profiel aan dit nummer toe of
                                voeg dit profiel vaker toe aan de offerte.'/>
          )}
          rightButtonAction={this.closeDialog}
          rightButtonText={'Ik begrijp het.'}
        />

        <GeneralDialog
          dialogState={!!this.state.vamoModalOpened}
          closeHandler={this.closeVamoModal}
          headerText={'Toewijzen niet mogelijk'}
          dialogBodyContent={(
            <DefaultDialogBody text='Een vamo kan niet vaker aan een nummer worden toegewezen dan het is
                                         vermeld bovenaan deze pagina. Wijs een ander vamo-product toe aan dit nummer of
                                         voeg deze vamo vaker toe aan de quote.'/>
          )}
          rightButtonAction={this.closeVamoModal}
          rightButtonText={'Ik begrijp het.'}
        />

        <GeneralDialog
          dialogState={this.state.deleteDialogOpened}
          closeHandler={this.hideDeleteDialog}
          headerText={'Weet u dit zeker?'}
          dialogBodyContent={(
            <DefaultDialogBody text='Weet u zeker dat u dit telefoonnummer wilt verwijderen?'/>
          )}
          rightButtonAction={this.hideDeleteDialog}
          rightButtonText={'Nee'}
          leftButtonAction={this.deletePhoneNumber}
          leftButtonText={'Ja'}
          leftButtonLeft={true}
        />

        {this.state.profilesProcessing &&
        (
          <div className="loader-component" style={{zIndex: 99}}>
            <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
            <Preloader size='medium'/>
          </div>
        )
        }

        <ReactTable
          data={this.state.data}
          resolveData={data => data.map(row => row)}
          columns={columns}
          minRows={0}
          defaultPageSize={999}
          showPagination={false}
          className={'akb-table'}
          getTbodyProps={() => {
            return {
              style: {
                overflow: 'visible',
                height: '450px'
              }
            }
          }}
          style={{minHeight: this.calculateMinHeight()}}
          // defaultPageSize={rowsPerPage}
          // showPagination={this.props.data.length > rowsPerPage}
        />

        {!(isVerlengenType && isUsingExistingData) &&
        <Button
          type="submit"
          waves="light"
          className="doceri-btn-right"
          style={{marginTop: 20, marginLeft: 20}}
          onClick={() => this._handleDeleteDataDialogOpen()}
          disabled={inputsDisabled}
        >
          Delete
          <Icon right>
            delete
          </Icon>
        </Button>
        }

        {!(isVerlengenType && isUsingExistingData) &&
        <DeleteData
          dialogOpened={this.state.deleteDataDialogOpened}
          onRequestClose={this._handleDeleteDataDialogClose}
          deleteData={this.deleteAllData}
          type="mobiele"
        />
        }

        {!(isVerlengenType && isUsingExistingData) && !isExistingType &&
        <Button
          type="submit"
          waves="light"
          className="doceri-btn-right"
          style={{marginTop: 20, marginLeft: 20}}
          onClick={() => this.props.addPhoneNumbersForEachProfileSelected()}
          disabled={inputsDisabled}
        >
          Rij toevoegen per gekozen profiel
          <Icon right>
            add
          </Icon>
        </Button>
        }

        {/*{!(isVerlengenType && isUsingExistingData) && !isExistingType &&
        <Button
          type="submit"
          waves="light"
          className="doceri-btn-right"
          style={{marginTop: "20px"}}
          onClick={() => this.props.addPhoneNumber()}
          disabled={inputsDisabled}
        >
          Losse regel toevoegen
          <Icon right>
            add
          </Icon>
        </Button>
        }*/}

        <Fragment>
          <Button
              type="submit"
              waves="light"
              className="doceri-btn-right"
              style={{marginTop: 20}}
              onClick={() => this._handleDialogOpen()}
              disabled={inputsDisabled}
          >
            Bulk Upload
            <Icon right>
              save
            </Icon>
          </Button>

          <Button
              type="submit"
              waves="light"
              className="doceri-btn-right"
              style={{marginTop: 20, marginRight: 20}}
              onClick={() => this.getExampleFile()}
              disabled={inputsDisabled}
          >
            Download bulk template
            <Icon right>
              save
            </Icon>
          </Button>

          <BulkUpload
              dialogOpened={this.state.bulkUploadDialogOpened}
              onRequestClose={this._handleDialogClose}
              onDrop={this._onDrop}
              droppedFile={this.state.file}
              save={this.save}
              mobile={true}
          />
        </Fragment>
        {this.state.saveRequired ? (
          <span className="red-text right" style={{clear: "both"}}>
            Om verder te gaan, dient u eerst de wijzigingen in het telefoonnummermenu op te slaan.
          </span>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({quotation, hardware, mobile, authentication}) => {
  return {
    quotationId: quotation.currentQuotation.id,
    customHardwareOptions: hardware.customHardwareOptions,
    mobileOfferYear: quotation.currentQuotation.mobileOfferYear,
    devicePaymentOptions: mobile.devicePaymentOptions,
    vamoProducts: mobile.vamoProducts,
    simCardProducts: mobile.simCardProducts,
    mobileProductOptions: mobile.currentMobileProductOptions,
    currentMobileProducts: mobile.currentMobileProducts,
    quotation,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTelephoneNumbersTable);
