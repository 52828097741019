import React from 'react';
import {DelayInput} from "react-delay-input";

import ProviderHelper from "../../../helper/providerHelper";

const HostedVoiceToggle = ({
                             onChangeCallback,
                             hostedVoiceState,
                             businessConnectState,
                             isMigrationOffer,
                             quotation,
                             inputsDisabled,
                             updateQuotation,
                             isSalesValue,
                             isYielderProvider,
                             showMobileSelections,
                             organization,
                             voipPerLocation,
                             existingTelephoneExchange,
                             onOrderIdChange,
                             isVerlengenType}) => (
  <React.Fragment>{
    /*!isMigrationOffer &&*/
    (
      <div className="row" id="Forms">
        <div className={"col s" + (existingTelephoneExchange ? "10" : "12")}>
          <h1 className={'ratio-section-title'}>
            <span className={'ratio-section-title'}><i
              className="small material-icons left ratio-section-title">settings_phone</i>
              {isVerlengenType ? 'Nieuwe Voice producten toevoegen' : 'Wenst u vaste telefonie?'}
            </span>
          </h1>
          <button
            className={"btn doceri-btn-left " + (hostedVoiceState ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right') + (inputsDisabled ? ' isSentToGrexx' : '')}
            onClick={() => onChangeCallback({target: {name: 'hostedVoiceActive', value: !hostedVoiceState}})}
            disabled={inputsDisabled}
          >
            Ja, activeer Vast Bellen
          </button>
          {!isSalesValue &&
          <span>
            {ProviderHelper.hasModuleEnabled(quotation, 'businessConnection') ?
              <button
                className={"btn doceri-btn-left " + (businessConnectState ? ' ratio-btn white-text active' : ' ratio-btn ratio-btn-white-right')}
                style={{marginLeft: 20}}
                onClick={() => onChangeCallback({
                  target: {
                    name: 'businessConnectActive',
                    value: !businessConnectState
                  }
                })} disabled={inputsDisabled}
              >
                Ja, activeer Bedrijf Connect
              </button> :
              <button
                style={{marginLeft: 20}}
                className={"btn doceri-btn-left " + (!hostedVoiceState ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right')}
                onClick={() => onChangeCallback({target: {name: 'hostedVoiceActive', value: !hostedVoiceState}})}
                disabled={inputsDisabled}
              >
                Nee, geen Vast Bellen gewenst
              </button>
            }
          </span>
          }
        </div>
        {
          existingTelephoneExchange && (
            <div className="col s2" style={{marginTop: '4.1rem'}}>
              <label className="active" style={{width: "100%", marginLeft: 0}} htmlFor="telephoneExchangeOrderId">Bestaande
                telefooncentrale OrderID</label>
              <DelayInput
                delayTimeout={1000}
                name="telephoneExchangeOrderId"
                type="text"
                value={existingTelephoneExchange.order_id}
                onChange={onOrderIdChange}
                required="required"
                style={{marginBottom: 0}}
                disabled={inputsDisabled}
              />
            </div>
          )
        }
        {hostedVoiceState && !isSalesValue && !isYielderProvider && showMobileSelections && organization && voipPerLocation ?
          <div className="col s12" style={{marginTop: 20}}>
            {organization.voip_per_location &&
            <button
              className={"btn doceri-btn-left " + (quotation.voicePerLocation ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right') + (inputsDisabled ? ' isSentToGrexx' : '')}
              onClick={() => updateQuotation({name: 'voicePerLocation', value: true})} disabled={inputsDisabled}
            >
              Instellen per locatie
            </button>}
            <button
              className={"btn doceri-btn-left " + (!quotation.voicePerLocation ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right') + (inputsDisabled ? ' isSentToGrexx' : '')}
              style={organization.voip_per_location ? {marginLeft: 20} : {}}
              onClick={() => updateQuotation({name: 'voicePerLocation', value: false})} disabled={inputsDisabled}
            >
              Instellen per offerte
            </button>
          </div> : ''}
      </div>)
  }
  </React.Fragment>
);

export default HostedVoiceToggle
