import React, {Component} from 'react';
import {Col, Icon, Input, Preloader, Row} from 'react-materialize';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import debounce from 'lodash/debounce';
import {poll} from "../../../utils/utils";
import {apiRoutes} from "../../../constants/apiRoutes";
import styled from "styled-components";
import {toastr} from 'react-redux-toastr';

import mobileActions from "../../../actions/workflow/mobileActions";
import quotationActions from "../../../actions/quotationActions";
import hardwareActions from "../../../actions/hardwareActions";
import hostedVoiceActions from '../../../actions/workflow/hostedVoiceActions';
import stepsActions from "../../../actions/stepsActions";

import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";
import CustomHardwareWithCategories from "../../common/CustomHardwareWithCategories";
import ExistingMobileData from '../../common/ExistingMobileData';
import QuotationType from "../../../constants/quotationType";
import MobileTelephoneNumbersTable from "../../common/MobileTelephoneNumbersTable";
import UpgradeMobileTelephoneNumbersTable from "../../common/UpgradeMobileTelephoneNumbersTable";
import ProviderHelper from "../../../helper/providerHelper";
import LoaderComponent from '../../../containers/common/LoaderComponent';

import MobileToggle from "../../common/MobileToggle";
import RegularSelect from "../../../components/RegularSelect";
import MobileProfiles from "./MobileProfiles";
import GeneralDialog from "../../../containers/common/GeneralDialog";

import FileDownload from "js-file-download";
import Organization from "../../../constants/organization";

import providerType, {odidoProviders, ownBrandProviders} from "../../../constants/providerType";
import productActionTypes from "../../../constants/productActionTypes";
import {additionDataSim, additionVamo} from "../../../constants/telephoneNumberType";
import {DelayInput} from "react-delay-input";

class MobileStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      profilesProcessing: false,
      profilesLoader: false,
      hostedVoiceDialogState: false,
      showContractDialog: false,
      toResetAddons: [],
      resetAddonsDialogContent: 'Het is niet mogelijk minder contracten te hebben als Add-ons. U dient eerst de hoeveelheid add-ons te verlagen of alles weer op 0 te zetten en deze opnieuw in te stellen.',
      resetAddonsButtonLabel: 'Alle opties resetten',
      showMobileYears: false,
      showPerLocation: false,
      showMobileProducts: false,
      selectedMobileOptions: this.props.currentMobileProductOptions || [],
      selectedMobileProducts: this.props.currentMobileProducts || [],
      dialogAKBOpened: false,
      mobileFormErrors: {},
      showMobileYearDialog: false,
      mobileYear: null,
      mobilePerLocation: this.props.authentication.providers[0] ? this.props.authentication.providers[0].organization.mobile_per_location : false,
      voipPerLocation: this.props.authentication.providers[0] ? this.props.authentication.providers[0].organization.voip_per_location : false,
      isUsingExistingData: null,
      existingMobileData: [],
      upgradingMobileData: [],
      isDataSaved: true,
      isQuantityTouched: false,
      quotationType: this.props.quotation.currentQuotation.type,
      exceptionBundleSelection: null,
      exceptionBundleIndex: null,
      dataRetrievalInProcess: false,
      hasActiveAddons: this.props.quotation.currentQuotation.hasActiveAddons,
      hasActiveEndDateOptions: this.props.quotation.currentQuotation.hasActiveEndDateOptions,
      showAddonsImportDialog: false,
      globalProfileSelected: null,
      globalVamoSelected: null,
      globalDataSimAmountSelected: null,
      presetDiscountsInvalid: this.props.warnings.presetDiscountsInvalid || false,
      mainActionsView: true,
      existingPhoneNumberSearch: '',
      odidoBillingAccountCode: this.props.customer.selectedCustomer?.odidoBillingAccountCode || ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentMobileProductOptions !== this.props.currentMobileProductOptions) {
      this.setState({
        mobileOptions: this.props.currentMobileProductOptions,
      });
    }
    if (prevProps.currentMobileProducts !== this.props.currentMobileProducts) {
      this.setState({
        selectedMobileProducts: this.props.currentMobileProducts,
      });
    }
    if (prevProps.locations.selectedLocation.id !== this.props.locations.selectedLocation.id) {
      let year = this.props.quotation.currentQuotation.mobileOfferYear;
      this.getMobileProducts(year, this.props.locations.selectedLocation.id);
    }

    if (prevProps.quotation.currentQuotation.useExistingMobileData !== this.props.quotation.currentQuotation.useExistingMobileData) {
      this.setState({
        isUsingExistingData: this.props.quotation.currentQuotation.useExistingMobileData
      });
    }

    if (prevProps.existingMobileData !== this.props.existingMobileData && this.props.existingMobileData.length) {
      this.setState({
        existingMobileData: this.props.existingMobileData
      });
    }

    if (prevProps.quotation.upgradingData !== this.props.quotation.upgradingData) {
      this.setState({
        upgradingMobileData: this.props.quotation.upgradingData
      });
    }

    if (prevProps.quotation.currentQuotation.hasActiveAddons !== this.props.quotation.currentQuotation.hasActiveAddons) {
      this.setState({ hasActiveAddons: this.props.quotation.currentQuotation.hasActiveAddons });
    }

    if (prevProps.quotation.currentQuotation.hasActiveEndDateOptions !== this.props.quotation.currentQuotation.hasActiveEndDateOptions) {
      this.setState({ hasActiveEndDateOptions: this.props.quotation.currentQuotation.hasActiveEndDateOptions });
    }

    let isVerlengenQuotation = this.props.quotation.currentQuotation.type === QuotationType.TYPE_VERLENGEN;

    if (prevProps.loading.existingMobileDataRetrieval !== this.props.loading.existingMobileDataRetrieval && isVerlengenQuotation) {
      this.setState({ dataRetrievalInProcess: this.props.loading.existingMobileDataRetrieval })

      if (! this.props.loading.existingMobileDataRetrieval) {
        this.getExistingMobileData();
      }
    }

    if (prevProps.profilesLoader !== this.props.profilesLoader) {
      this.setState({ profilesLoader: this.props.profilesLoader });
    }

    if (prevProps.quotation.currentQuotation.mobileOfferYear !== this.props.quotation.currentQuotation.mobileOfferYear && !prevProps.quotation.currentQuotation.mobileOfferYear) {
      this.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locations.selectedLocation.id);
    }

    if (prevProps.warnings.presetDiscountsInvalid !== this.props.warnings.presetDiscountsInvalid) {
      this.setState({presetDiscountsInvalid: this.props.warnings.presetDiscountsInvalid});
    }
  }

  componentWillMount() {
    const { isPIT } = this.props.authentication;
    const { mobileNeeded, mobileOfferYear, provider, type } = this.props.quotation.currentQuotation;

    // get mobile hardware products
    this.props.hardwareActions.getCustomHardwareOptions('mobiel', this.props.quotationId);
    this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'mobiel');

    //retrieve all hardware selections
    this.props.hardwareActions.getAllCustomHardwareOptionsSelections(this.props.quotationId);

    // Get voice products
    this.props.hardwareActions.getCustomHardwareOptions('voice', this.props.quotationId);
    this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'voice');

    if (isPIT) {
      this.resetExistingMobileData(false);
      if (!mobileOfferYear) {
        this.getMobileYears(false, true, mobileNeeded);
      }
    }

    if (provider === providerType.VODAFONE) {
      this.getExeptionEndDateProducts();
    }
  }

  componentDidMount() {
    const {mobileOfferYear, mobilePerLocation, useExistingMobileData, mobileNeeded, type} = this.props.quotation.currentQuotation;
    if (mobileOfferYear || useExistingMobileData) {
      this.initializeMobileYears(mobilePerLocation)
          .then(() => this.getMobileProducts(mobileOfferYear, this.props.locations.selectedLocation.id))
          .then(() => this.props.mobileActions.retrieveExistingMobileData(this.props.quotation.currentQuotation.id))
          .then(() => this.setState({ loading: false, isUsingExistingData: useExistingMobileData }));

      this.sendUpdateMobileProducts = debounce(this.sendUpdateMobileProducts, 1000);
    } else {
      if (mobileNeeded) {
        this.initializeMobileYears(mobilePerLocation);
      }
    }

    if (!mobileOfferYear && !useExistingMobileData || this.props.authentication.company === Organization.PIT) {
      this.setState({loading: false})
    }

    if (mobileNeeded && ! this.state.showMobileYears) {
      this.setState({ showMobileYears: true });
    }

    if (mobileNeeded && mobilePerLocation) {
      this.setState({ showPerLocation: true });
    }

    if (this.props.loading.existingMobileDataRetrieval) {
      this.setState({ dataRetrievalInProcess: true })
    }

    this.continuePoll();
  }

  continuePoll = () => {
    if (! this.props.quotation.currentQuotation.pendingMobileTemplate) {
      return;
    }

    let url = apiRoutes.mobileRelated.getMobileBulkXlsExample
        .replace('{quotationId}', this.props.quotationId)
        .replace(/{locationId}/, this.props.locations.selectedLocation.id);

    poll(url, { responseType: 'arraybuffer' }) // Use array buffer instead of blob, see link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
        .then((response) => FileDownload(response,  this.props.quotationId + '-Bulk-upload-ratio-mobiele-telefoonnummers.xlsx'))
        .catch((e) => toastr.error('Kan het voorbeeldbestand niet downloaden', e.message));
  }

  addNewProductSelection = () => {
    this.props.mobileActions.addNewProductSelection({id: "", productId: "", quantity: 1});
  };

  closeContractDialog = () => {
    this.setState({
      showContractDialog: false,
      resetAddonsNames: [],
    });
  };

  resetAddons = () => {
    this.state.toResetAddons.forEach((name) => {
      this.props.mobileActions.updateMobileOptionsState(name, 0);
      this.updateMobileOptions(name, 0);
    });

    this.setState({
      showContractDialog: false,
      resetAddonsNames: [],
    });
  };

  updateMobileOptions = (name, value) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.props.mobileActions.updateMobileOptions(this.props.quotationId, name, value)
  };

  initializeMobileYears = (perLocation) => {
    return this.props.mobileActions.mobileProductsOfferYears(this.props.quotationId).then(() => {
      this.setState({
        showMobileYears: true,
        showPerLocation: perLocation
      })
    });
  };

  getMobileYears = (perLocation, edit, isMobileNeeded) => {
    let { currentQuotation } = this.props.quotation;
    let mobilePerLocation = this.state.mobilePerLocation;
    let perLocationCondition = (mobilePerLocation && (currentQuotation.mobilePerLocation === perLocation) && currentQuotation.mobileNeeded);
    let disableCondition = (! mobilePerLocation && isMobileNeeded) || perLocationCondition;
    let perLocationMobileNeeded = mobilePerLocation && ! currentQuotation.mobileNeeded;
    const isOdidoProvider = odidoProviders.includes(this.props.quotation.currentQuotation.provider);

    if (disableCondition) {
      this.disableMobileConfiguration();
    }

    if (edit) {
      const data = {
        target: {
          name: 'mobilePerLocation',
          value: perLocation
        }
      };
      this.updateQuotationProperty(data);
    }

    if (! mobilePerLocation || disableCondition || perLocationMobileNeeded) {
      const mobileNeeded = {
        target: {
          name: 'mobileNeeded',
          value: !isMobileNeeded,
        }
      };

      this.updateQuotationProperty(mobileNeeded);
    }

    if (isOdidoProvider) {
      const mobileOfferYear = {
        target: {
          name: 'mobileOfferYear',
          value: currentQuotation.mobileOfferYear === "x" ? null : "x"}
      };

      this.updateQuotationProperty(mobileOfferYear);
    }

    return this.props.mobileActions.mobileProductsOfferYears(currentQuotation.id).then(() => {
      this.setState({
        showMobileYears: !disableCondition,
        showPerLocation: perLocation
      })
    });
  };

  odidoGetOrResetData = () => {
    let { currentQuotation } = this.props.quotation;

  }

  disableMobileConfiguration = () => {
    this.unCheckMobileYears();

    let newTarget = {
      target: {
        name: 'mobileOfferYear',
        value: '',
        key: 'nee'
      }
    };

    this.updateQuotationProperty(newTarget);
  };

  getExistingMobileData = () => {
      const data = {
          target: {
              name: 'usingExistingData',
              value: true
          }
      };

    this.setState({loading: true});

    this.props.mobileActions.retrieveExistingMobileData(this.props.quotation.currentQuotation.id)
        .then(() => this.props.quotationActions.updateQuotationProperty(data.target))
        .then(() => this.setState({isUsingExistingData: true, loading: false}));
  };

  resetExistingMobileData = (reset = true) => {
    if (reset) {
      this.props.mobileActions.resetExistingMobileData(this.props.quotation.currentQuotation.id);
    }

    const data = {
      target: {
        name: 'usingExistingData',
        value: false
      }
    };

    this.props.quotationActions.updateQuotationProperty(data.target)
    if (this.props.quotation.currentQuotation.mobileOfferYear) {
      this.props.mobileActions.mobileOfferProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.quotationId, this.props.locations.selectedLocation)
        .then(() => this.setState({isUsingExistingData : false}));
    } else {
      this.setState({isUsingExistingData : false})
    }
  };

  unCheckMobileYears = () => {
    this.props.mobileActions.disableMobileSelections(this.props.quotationId).then(() => {
      this.setState({
        showMobileYears: false,
        selectedMobileProducts: [],
        selectedMobileOptions: [],
        exceptionBundleSelection: null,
        exceptionBundleIndex: null,
      });
    });
  };

  getMobileProducts = (year, locationId, updatePendingDataRetrieval = false) => {
    let { currentQuotation } = this.props.quotation;
    let selectedLocation = currentQuotation.mobilePerLocation ? locationId : -1;

    this.props.mobileActions.mobileOfferProducts(year, this.props.quotationId, selectedLocation).then(() => {
      if (year) {
        this.setState({
          showMobileProducts: true,
          showMobileYears: true
        });
      }

      if (odidoProviders.includes(currentQuotation.provider) && !year) {
        this.setState({
          showMobileProducts: false
        })
      }

      if (updatePendingDataRetrieval) {
        this.props.mobileActions.updateDataRetrieval([]);
      }
    });

    if (odidoProviders.includes(currentQuotation.provider)) {
      this.props.quotationActions.getPresetDiscountCheck(this.props.quotationId);
    }
  };

  updateQuotationProperty = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let { currentQuotation } = this.props.quotation;

    if (
      target.name === 'mobileOfferYear' &&
      currentQuotation.mobileOfferYear === target.value &&
      currentQuotation.provider === 2
    ) {
      this.setState({ exceptionBundleSelection: null, exceptionBundleIndex: null });
      this.disableMobileConfiguration();
      return;
    }

    if (['mobileContractDuration', 'mobilePerLocation'].includes(target.name)) {
      this.updateQuotationContractDuration(target);
    } else {
      this.props.quotationActions.updateQuotationProperty(target);

      if (target.name === 'mobileOfferYear' && target.value === 'Flex' && currentQuotation.mobileContractDuration === 1) {
        let mobileContractDuration = {
          name: 'mobileContractDuration',
          value: 12
        };

        this.props.quotationActions.updateQuotationProperty(mobileContractDuration);
      }

      if (target.name !== 'mobileOfferYear') {
        this.setState({
          showMobileProducts: false,
        });
      }

      this.updateQuotationMobileStep(target, currentQuotation);
    }
  };

  updateQuotationContractDuration(target) {
    const {currentQuotation} = this.props.quotation;

    this.props.quotationActions.updateQuotationProperty(target);

    if (this.updateQuotationTimeout) {
      clearTimeout(this.updateQuotationTimeout);
    }
    this.updateQuotationTimeout = setTimeout(() => {
      this.props.quotationActions.updateQuotation(this.props.quotation.currentQuotation).then(() => {
        if (currentQuotation.provider === providerType.VODAFONE) {
          this.getMobileProducts(currentQuotation.mobileOfferYear, this.props.locations.selectedLocation.id);
        }
      });
    }, 250);
  }

  updateQuotationMobileStep(target, currentQuotation) {
    if (this.updateQuotationTimeout) {
      clearTimeout(this.updateQuotationTimeout);
    }
    this.updateQuotationTimeout = setTimeout(() => {

      this.props.mobileActions.updateQuotationMobileStep(this.props.quotation.currentQuotation).then(() => {

        if (! ['mobileNeeded', 'mobileType', 'odidoBillingAccountCode'].includes(target.name)) {
          this.getMobileProducts(target.value, this.props.locations.selectedLocation.id);
        }

        if (target.name === 'odidoBillingAccountCode') {
          this.getMobileProducts(currentQuotation.mobileOfferYear, this.props.locations.selectedLocation.id);
        }

        if (['mobileType'].includes(target.name)) {
          this.props.mobileActions.disableMobileSelections(this.props.quotationId).then(() => {
            this.setState({
              exceptionBundleSelection: null,
              exceptionBundleIndex: null,
              selectedMobileProducts: [],
              selectedMobileOptions: [],
            });
          });

          this.getMobileProducts(currentQuotation.mobileOfferYear, this.props.locations.selectedLocation.id);
        }
      }
      );
    }, 250);
  }

  onChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    this.props.mobileActions.updateProfilesLoader(true);

    // check whether the bundle is changed or its amount
    let bundleIsChanged = !selectionProductId;
    // save either the product id of the bundle or the value of its amount
    const productId = selectionProductId ? selectionProductId : +value;

    let currentSelectedProducts = this.props.currentMobileProducts;

    //if the selected value was already added in another select box
    // => sum quantity in the existing selection and delete the current select box
    let deletedCurrentSelection = false;
    let newQuantity = 0;
    let newVerlengenQuantity = 0;
    if (bundleIsChanged) {
      currentSelectedProducts.forEach((obj, index) => {
        if ((!selectionId || obj.id !== selectionId)
          && productId == currentSelectedProducts[index]['productId']) {

          if (selectionId) {
            const foundIndex = currentSelectedProducts.findIndex((productSelection) => productSelection && productSelection.id === selectionId);
            if (foundIndex !== -1) {
              newQuantity = currentSelectedProducts[index]['quantity'] += currentSelectedProducts[foundIndex].quantity;
              newVerlengenQuantity = currentSelectedProducts[index]['newQuantity'] += currentSelectedProducts[foundIndex].newQuantity;

              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
                currentSelectedProducts[index]['newQuantity'] = newVerlengenQuantity;
              }
              delete currentSelectedProducts[foundIndex];
            } else {
              newQuantity = currentSelectedProducts[index]['quantity'] + 1;
              newVerlengenQuantity = currentSelectedProducts[index]['newQuantity'] + 1;
              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
                currentSelectedProducts[index]['newQuantity'] = newVerlengenQuantity;
              }
            }
          } else {
            newQuantity = currentSelectedProducts[index]['quantity'] + 1;
            newVerlengenQuantity = currentSelectedProducts[index]['newQuantity'] + 1;
            if (newQuantity <= 999) {
              currentSelectedProducts[index]['quantity'] = newQuantity;
              currentSelectedProducts[index]['newQuantity'] = newVerlengenQuantity;
            }
          }

          deletedCurrentSelection = true;
        }
      });
    }

    if (!deletedCurrentSelection) {
      if (selectionId) {
        currentSelectedProducts.forEach((obj, index) => {
          if (obj.id === selectionId) {
            if (productId === 0) {
              delete currentSelectedProducts[index];
            } else {
              currentSelectedProducts[index]['productId'] = productId;
              currentSelectedProducts[index]['quantity'] = 1;
              currentSelectedProducts[index]['newQuantity'] = 1;
              if (selectionProductId) {
                currentSelectedProducts[index]['quantity'] = value;
                currentSelectedProducts[index]['newQuantity'] = value;
              }
            }
          }
        });
      } else {
        const product = {
          id: "",
          productId: productId,
          quantity: 1,
          newQuantity: 1
        };

        currentSelectedProducts.push(product);
      }
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null && obj.productId !== '');

    // update local state
    this.setState({
      selectedMobileProducts: currentSelectedProducts,
    });

    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    // update db values
    this.props.mobileActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      currentSelectedProducts,
      this._getLatestSelectedOptions(bundleIsChanged ? selectionId : false)
    )
  };

  onQuantityChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    // save either the product id of the bundle or the value of its amount
    const productId = selectionProductId ? selectionProductId : +value;
    const isVerlengenType = this.state.quotationType === QuotationType.TYPE_VERLENGEN;

    //product is linked => count > value => warning
    const selectedCount = this.props.telephoneNumbers.filter(element => element.type == 'mobile' && element.number !== '' && !element.isFetched)
      .reduce((acc, crtElem) => {
        return crtElem.product == productId ? ++acc : acc;
      }, 0);

    if (selectedCount > value) {
      this.openAKBWarningDialog();
      return;
    }

    let currentSelectedProducts = this.props.currentMobileProducts;

    if (selectionId) {
      currentSelectedProducts.forEach((obj, index) => {
        if (obj.id === selectionId) {
          if (productId === 0) {
            delete currentSelectedProducts[index];
          } else {
            currentSelectedProducts[index]['productId'] = productId;
            currentSelectedProducts[index]['quantity'] = 1;
            currentSelectedProducts[index]['newQuantity'] = 1;
            this.setState({isQuantityTouched: true});

            if (selectionProductId) {
              value = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(value)));

              currentSelectedProducts[index]['quantity'] = isVerlengenType ? currentSelectedProducts[index]['quantity'] + value : value;
              currentSelectedProducts[index]['newQuantity'] =  value

              this.setState({isQuantityTouched: true});
            }
          }
        }
      });
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);

    // update local state
    this.setState({
      selectedMobileProducts: currentSelectedProducts,
    });
  };

  onQuantitySaveAction = () => {
    const {isQuantityTouched} = this.state;

    if (isQuantityTouched) {
      this.props.mobileActions.updateProfilesLoader(true);
      this.setState({ profilesProcessing: true });
      this.sendUpdateMobileProducts();
      this.setState({ isQuantityTouched: false });
    }
  }

  renderOptionProduct = ({name, id}) => {
    return (<option key={id} value={id}>{name}</option>)
  };

  static applySelectedProduct = (acc, current) => {
    current.selectedProducts.filter(obj => obj.id !== -1).forEach(obj => {
      acc[obj.id] = (acc[obj.id] ?? 0) + (obj.quantity ?? 0);
    });

    return {...acc};
  };

  getSelectedProducts = (mobileOptions) =>
    mobileOptions.reduce(MobileStep.applySelectedProduct, {});

  handleExceptionBundleChange = (selectionId, index) => (event, value) => {
    const id = +value;
    let productId = value;

    // update local state
    let allOptions = this.state.mobileOptions;
    let options = allOptions[selectionId];
    let newSelection = {
      id: id,
      quantity: 1,
      telephone_number_id: null
    };

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => {
        return selectedProduct && parseInt(selectedProduct.id) === parseInt(productId);
      });

      if (foundIndex !== -1 || (foundIndex === -1 && options[index].selectedProducts.length > 0)) {
        options[index]['selectedProducts'] = [];
      }

      options[index]['selectedProducts'].push(newSelection);

      allOptions[selectionId] = options;

      this.setState({
        mobileOptions: allOptions,
        exceptionBundleSelection: options[index]
      });
    }

    //update options selected in database
    this.sendUpdateMobileProductsNow();
  }

  handleMultipleOptionsChange = (productId = null, quantity = 1, max = null, selectionId, index, numberId = null) => (event, value) => {
    value = value.filter(el => parseInt(el) !== -1);

    // update local state
    let allOptions = this.state.mobileOptions;
    let options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    value.forEach(function (element, key) {
      element = parseInt(element);

      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => {
        const condition = selectedProduct && selectedProduct.id === element;

        if (numberId) {
          return condition && selectedProduct.telephone_number_id === numberId;
        }

        return condition;
      });

      if (foundIndex !== -1) {
        delete options[index]['selectedProducts'][foundIndex];
      }

      options[index]['selectedProducts'].push({
        id: element,
        quantity: quantity,
        telephone_number_id: numberId
      });

      allOptions[selectionId] = options;
    });

    options[index].selectedProducts = options[index].selectedProducts.filter(el => {
      return value.includes(`${el.id}`);
    });

    allOptions[selectionId] = options;

    this.setState({mobileOptions: allOptions});

    this.sendUpdateMobileProductsNow();
  };

  handleOptionsChange = (productId, quantity = 1, max = null, selectionId, index, numberId = null) => (event, value) => {
    this.props.mobileActions.updateProfilesLoader(true);

    const id = +value;
    // update local state
    let allOptions = this.state.mobileOptions;
    let options = allOptions[selectionId];
    let newSelection = {
      id: id,
      quantity: quantity,
      telephone_number_id: numberId
    };

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => {
        const condition = selectedProduct && selectedProduct.id === productId;

        if (numberId) {
          return condition && selectedProduct.telephone_number_id === numberId;
        }

        return condition;
      });

      if (foundIndex !== -1) {
        delete options[index]['selectedProducts'][foundIndex];
      }
      options[index]['selectedProducts'].push(newSelection);

      allOptions[selectionId] = options;

      this.setState({mobileOptions: allOptions});
    }

    //update options selected in database
    this.sendUpdateMobileProductsNow();
  };

  handleOptionsQuantityChange = (productId, min, max, selectionId, index) => (event, value) => {
    let quantity = +value;

    quantity = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(quantity)));

    // update local state
    let allOptions = this.state.mobileOptions;

    //only for non AKB telephone numbers
    //get current profile by selectionId
    if (!this.props.authentication.isSalesValue) {
      const currentProfile = this.state.selectedMobileProducts.find(element => element.id == selectionId);

      //filter telephone numbers with current profile linked
      //count amount of telephone numbers linked to this option and profile
      const selectedOptionCount = this.props.telephoneNumbers.filter(element => {
        return element.type === 'mobile' && (!currentProfile || element.product == currentProfile.productId)
      }).reduce((acc, crtElem) => {
        return crtElem.options && crtElem.options.indexOf(productId) !== -1 ? ++acc : acc;
      }, 0);

      if (selectedOptionCount > quantity) {
        this.openAKBWarningDialog();
        return;
      }
    }

    this.props.mobileActions.updateProfilesLoader(true);

    let options = allOptions[selectionId];
    const isUpgradeCategory = options[index] && options[index].category &&
      options[index].category.name !== 'World upgrades' && options[index].category.name.indexOf('upgrades') !== -1;

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    const {provider} = this.props.quotation.currentQuotation;

    if (odidoProviders.includes(provider)) {
      const selectedProductsQuantity = Object.values(options[index].selectedProducts)
        .filter(selectedProduct => selectedProduct.id !== productId)
        .reduce((acc, obj) => acc + obj.quantity, 0);
      const bundleProductQuantity = options[index].bundleSelectionQuantity;
      const selectedProduct = options[index].selectedProducts.find(product => product.id === productId);
      const quantitySelectedProduct = selectedProduct ? selectedProduct.quantity : 0;
      const isDecreasingQuantity = quantity < quantitySelectedProduct;

      if (!isDecreasingQuantity && (options[index].selectedProducts.length > 1 && quantity + selectedProductsQuantity) > bundleProductQuantity) {
        toastr.warning(`Het maximale aantal van ${options[index].category.name} is bereikt.`);
        this.props.mobileActions.updateProfilesLoader(false);
        return;
      }
    }

    if (options[index].category.name === 'Vast-mobiel opties' || isUpgradeCategory) {
      const profileQuantity = options[index].bundleSelectionQuantity;
      const secondProduct = options[index].selectedProducts.find(product => product.id !== productId);
      const quantitySecondProduct = secondProduct ? secondProduct.quantity : 0;

      if (quantity + quantitySecondProduct > profileQuantity) {
        toastr.warning(`Het maximale aantal van ${options[index].category.name} is bereikt.`);
        return;
      }
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      if (foundIndex !== -1) {
        options[index]['selectedProducts'][foundIndex]['quantity'] = quantity;
      }

      allOptions[selectionId] = options;
      this.setState({mobileOptions: allOptions});
    }

    this.sendUpdateMobileProducts();
  };

  _getLatestSelectedOptions = (bundleIsChanged = false) => {
    let allSelectedOptions = {};

    const isOdidoApiProviderException = odidoProviders.includes(this.props.quotation.currentQuotation.provider);

    // if bundle has been changed, then don't send back anymore last options that have been previously selected
    // if (this.state.mobileOptions && this.state.mobileOptions.length) {
      Object.keys(this.state.mobileOptions).map(selId => {
        if (bundleIsChanged != selId) {
          if (this.state.quotationType === QuotationType.TYPE_VERLENGEN && this.state.isUsingExistingData && !isOdidoApiProviderException) {
            let rObj = {};

            this.state.mobileOptions[selId].forEach(function (value, index) {
              value.selectedProducts.filter(obj => obj.id !== -1).forEach(obj => {
                if (!rObj[obj.id]) {
                  rObj[obj.id] = [];
                }

                rObj[obj.id].push({
                  quantity: obj.quantity,
                  telephone_number_id: obj.telephone_number_id
                });
              });
            });

            allSelectedOptions[selId] = rObj;
          } else {
            allSelectedOptions[selId] = this.getSelectedProducts(this.state.mobileOptions[selId]);
          }
        }
      });
    // }

    return allSelectedOptions;
  };

  addOption = (index, option) => {
    option.selectedProducts.push({id: -1});

    let options = this.state.mobileOptions;
    options[option.selectionId][index] = option;

    this.setState({mobileOptions: options})
  };

  removeOption = (index, productId, selectionId) => {
    let allOptions = this.state.mobileOptions;
    const options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      options[index].selectedProducts.splice(foundIndex, 1);

      allOptions[selectionId] = options;
      this.setState({mobileOptions: allOptions});
    }

    if (productId !== -1) {
      // update options selected in database
      this.sendUpdateMobileProductsNow();
    }
  };

  sendUpdateMobileProducts = () => {
    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    this.props.mobileActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation,
        this.getSelectedMobileProducts(), this._getLatestSelectedOptions()).then(() => {
      this.setState({ profilesProcessing: false });
    });
  };

  sendUpdateMobileProductsNow = () => {
    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    this.props.mobileActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation,
        this.state.selectedMobileProducts, this._getLatestSelectedOptions());
  };

  changeMobileYear = (mobileYear) => {
    const data = {
      target: {
        name: 'mobileOfferYear',
        value: mobileYear
      }
    };
    this.updateQuotationProperty(data);
    this.closeMobileYearDialog();
  };

  openMobileYearDialog = (e) => {
    const {currentQuotation} = this.props.quotation;

    if(!currentQuotation.mobileOfferYear) {
      this.changeMobileYear(e.target.value);
      return;
    }

    this.setState({
      showMobileYearDialog: true,
      mobileYear: e.target.value
    });
  };

  closeMobileYearDialog = () => {
    this.setState( {
      showMobileYearDialog: false,
      mobileYear: null
    });
  };

  closePresetDiscountsDialog = () => {
    this.setState({presetDiscountsInvalid: false});
  };

  openAddonsImportDialog = () => {
    const { currentQuotation } = this.props.quotation;
    const isVodafoneProvider = currentQuotation.provider = providerType.VODAFONE;
    const isOdidoPortfolio = odidoProviders.includes(currentQuotation.provider);

    if (isVodafoneProvider || isOdidoPortfolio) {
      return;
    }

    this.setState({ showAddonsImportDialog: true });
  };

  closeAddonsImportDialog = () => {
    this.setState({ showAddonsImportDialog: false });

    this.updateMobileAddons({target: {value: true }}, true);
  };

  getUpgradingData = (orderId, phoneNumber) => {
    this.props.mobileActions.retrieveExistingMobileData(this.props.quotation.currentQuotation.id, orderId, phoneNumber);
  };

  renderOptionFields = (option, index) => {
    const excludedOptionCategories = [
      'Add-ons',
      'Add-ons Onbeperkt',
      'Voordeelmodules',
      'Data SIM add-ons',
      'Add-ons Data SIM',
      'Een Einddatum',
      'Mobile Postpaid SIM-Card opties',
      'SIM Card opties',
      'Data-only Postpaid SIM-Card opties',
    ];

    if (excludedOptionCategories.includes(option.category.name)) {
      return;
    }

    const {isSalesValue} = this.props.authentication;

    if (option.category.product_number === 'ACTIES1MKB' && isSalesValue) {
      return;
    }

    const { inputsDisabled, provider } = this.props.quotation.currentQuotation;
    const {selectedProducts = [{id: -1}]} = option || {};
    let value = {id: -1};

    const selectedProductsArray = selectedProducts && selectedProducts.length ? selectedProducts : [value];

    let rows = [];
    let profilesLoader = this.state.profilesLoader;

    const childOptions = this.getChildSelections(option);

    selectedProductsArray.forEach((value, indexField) => {
      const removeButtonVisible = (option.category.max_subproducts === null) ||
        ((option.category.max_subproducts * option.bundleSelectionQuantity) > 1) ||
        option.products.length > 1;

      let maxQuantity = value.allowed_quantities ?
        value.allowed_quantities.max * option.bundleSelectionQuantity :
        option.bundleSelectionQuantity;

      // Remove limit of quotation type is not regular
      if (![QuotationType.TYPE_STANDARD, QuotationType.TYPE_QA, QuotationType.TYPE_VERGELIJKING, QuotationType.TYPE_HUUR, QuotationType.TYPE_KOOP].includes(this.state.quotationType)) {
        maxQuantity = 999;
      }

      const selectWidth = removeButtonVisible && (value.id !== -1) && maxQuantity > 1 ? 9
        : removeButtonVisible ? 10 : (value.id !== -1) ? 11 : 12;

      const profileQuantity = option.bundleSelectionQuantity;
      const isUpgradeCategory = option.category.name !== 'World upgrades' && option.category.name.indexOf('upgrades') !== -1;
      const isVodafoneQuotation = provider === providerType.VODAFONE;
      const isTMobileQuotation = odidoProviders.includes(provider);
      const selectedProductsQuantity = Object.values(selectedProducts).reduce((acc, obj) => acc + obj.quantity, 0);

      const addButtonVisible = (option.category.name === "Vast-mobiel opties" && option.selectedProducts.length < 2) ||
        (isVodafoneQuotation && option.selectedProducts.length < profileQuantity && selectedProductsQuantity < profileQuantity) ||
        (isTMobileQuotation && option.products.length > 1 && selectedProductsQuantity < profileQuantity);

      let row = (
        <>
          <Row key={`${option.category.name}-${indexField}-${value.id}`} style={{marginBottom: 0}}>
            {value.id !== -1 && maxQuantity > 1 ? (
              <Input
                s={1}
                type="number"
                label="Aantal"
                value={value.quantity > 0 ? value.quantity : 1}
                disabled={inputsDisabled || profilesLoader}
                onChange={this.handleOptionsQuantityChange(
                  value.id,
                  value.allowed_quantities ? value.allowed_quantities.min : 1,
                  maxQuantity,
                  option.selectionId,
                  index
                )}
                min={value.allowed_quantities ? value.allowed_quantities.min : 1}
                max={maxQuantity <= 999 ? maxQuantity : 999}
              />) : null}

            <Input
              s={selectWidth}
              type="select"
              label={option.category.name}
              onChange={this.handleOptionsChange(
                value.id,
                value.quantity,
                maxQuantity,
                option.selectionId,
                index
              )}
              multiple={false}
              disabled={inputsDisabled || profilesLoader}
              value={value.id}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              {option.products.map(this.renderOptionProduct)}
            </Input>

            {removeButtonVisible ? (
              <Col s={2}>
                <button
                  className="btn doceri-btn-right ratio-btn-right"
                  disabled={inputsDisabled || profilesLoader}
                  onClick={() => this.removeOption(index, value.id, option.selectionId)}
                  style={profilesLoader ? {minWidth: '150px'} : null}
                >
                  {
                    profilesLoader ?
                      (
                        <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                          <span className="indeterminate overviewkopbggroen"/>
                        </span>
                      ) : (
                        'Verwijder optie'
                      )
                  }
                  <Icon right>
                    close
                  </Icon>
                </button>
              </Col>
            ) : null}
          </Row>

          <Row>
            {addButtonVisible &&
            <Col s={12}>
              <button
                className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                onClick={() => this.addOption(index, option)}
                style={profilesLoader ? {minWidth: '125px', marginBottom: 30} : {marginBottom: 30}}
                disabled={inputsDisabled || profilesLoader}
              >
                {
                  profilesLoader ?
                    (
                      <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                            <span className="indeterminate overviewkopbggroen"/>
                          </span>
                    ) : (
                      'Voeg optie toe'
                    )
                }
                <Icon right>
                  add
                </Icon>
              </button>
            </Col>}
          </Row>
        </>
      );

      rows.push(row);

      if (isVodafoneQuotation) {
        const filteredChildOptions = childOptions.filter((el) => el.selectionId === value.selection_id);
        if (filteredChildOptions) {
          filteredChildOptions.forEach((newOption, newOptionIndex) => {
            rows.push(this.renderExtraSelect(newOption, newOptionIndex));
          });
        }
      }
    });

    return rows;
  };

  renderExtraSelect = (option, index) => {
    let selectedProductsQuantity = 0;
    let profilesLoader = this.state.profilesLoader;
    option.selectedProducts.forEach((value) => {
      selectedProductsQuantity += value.quantity ? parseInt(value.quantity) : 0;
    });

    const addButtonVisible = (option.category.max_subproducts === null) ||
      (option.category.max_subproducts > 1 &&
        (selectedProductsQuantity < (option.category.max_subproducts * option.bundleSelectionQuantity)) &&
        (option.products.length > 1)
      );

    if (this.props.quotation.currentQuotation.provider === 2 && option.category.product_number === 'FLEXDBUNDS') {
      if (! this.state.exceptionBundleSelection) {
        this.setState({ exceptionBundleSelection: option, exceptionBundleIndex: index });
      }
      return null;
    }

    let { inputsDisabled } = this.props.quotation.currentQuotation;

    return (
      <div key={index}>
        {this.renderOptionFields(option, index)}

        {addButtonVisible && (option.products.length !== option.selectedProducts.length) ? (
          <Row>
            <Col s={12}>
              <button
                className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                onClick={() => this.addOption(index, option)}
                style={profilesLoader ? {minWidth: '125px', marginBottom: 30} : {marginBottom: 30}}
                disabled={inputsDisabled || profilesLoader}
              >
                {
                  profilesLoader ? (
                    <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                        <span className="indeterminate overviewkopbggroen"/>
                    </span>
                  ) : ('Voeg optie toe')
                }
                <Icon right>
                  add
                </Icon>
              </button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  getChildSelections = (selection) => {
    const selections = [];
    selection.selectedProducts.forEach((selectedProduct) => {
      if (selectedProduct.selection_id && this.state.mobileOptions[selectedProduct.selection_id]) {
        this.state.mobileOptions[selectedProduct.selection_id].forEach((element) => {
          element.selectionId = selectedProduct.selection_id;
          element.bundleSelectionQuantity = selection.bundleSelectionQuantity
            ? selection.bundleSelectionQuantity * selectedProduct.quantity : selectedProduct.quantity;
          selections.push(element);
        })
      }
    });

    return selections;
  };

  getSelectedMobileProducts = () => {
    return this.state.selectedMobileProducts.filter(obj => obj.productId !== null && !obj.isOption);
  };

  getSelectedMobileDataProducts = () => {
    let products = [];
    const productOptions = this.props.currentMobileProductOptions;
    Object.keys(productOptions).forEach(function (key) {
      const options = productOptions[key];

      options.map(option => {
        if (option.category && option.category.name === 'Optie Data SIM') {
          option.selectedProducts.map(product => products.push(product));
        }
      });
    });

    return products;
  };

  getSelectedProfilesData = () => {
    let profiles = [];

    const { currentQuotation } = this.props.quotation;
    const isOdidoPortfolio = odidoProviders.includes(currentQuotation.provider);

    this.getSelectedMobileProducts().forEach(selected => {
      const found = this.props.mobileProducts.find(function (element) {
        return element.id === selected.productId;
      });

      if (found) {
        let foundProfile = {
          id: selected.productId,
          name: found.name,
          quantity: selected.quantity,
          newQuantity: selected.newQuantity
        };

        if (isOdidoPortfolio) {
          foundProfile['availableScenarios'] = selected['availableScenarios'];
        }

        profiles.push(foundProfile);
      }
    });

    // Mobile data SIMS
    this.getSelectedMobileDataProducts().forEach(product => {
      const found = this.props.mobileDataProducts.find(element => element.id === product.id);

      if (found) {
        profiles.push({
          id: product.id,
          name: found.name,
          quantity: product.quantity
        });
      }
    });

    return profiles;
  };

  closeAKBWarningDialog = () => {
    this.setState({dialogAKBOpened: false});
    if (this.state.profilesProcessing) {
      this.setState({ profilesProcessing: false });
    }
  };

  openAKBWarningDialog = () => {
    this.setState({dialogAKBOpened: true});
  };

  validateField = (fieldName, index, value, length) => {
    let isValid = true;
    const dateRegex = /^(?:(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/;

    switch (fieldName) {
      case 'number':

        isValid = (value.length === length && value.match(/^\d+$/));
        break;
      case 'portingDate':
        isValid = (value.length === length && value.match(dateRegex));
        break;
      case 'contractEndDate':
        isValid = (value.length === length && value.match(dateRegex));
        break;
      default:
        return isValid;
    }

    if (!isValid) {
      this.addNumberFieldError(index, fieldName);
    } else {
      this.removeNumberFieldError(index, fieldName);
    }

    return isValid;
  }

  removeNumberFieldError = (index, field) => {
    let {mobileFormErrors} = this.state;
    if (mobileFormErrors) {
      if (mobileFormErrors[index]) {
        //remove error only from fieldName(send as parameter)
        if (field) {
          const elemIndex = mobileFormErrors[index].indexOf(field);
          if (elemIndex !== -1) {
            mobileFormErrors[index].splice(elemIndex, 1);
          }

          if (!mobileFormErrors[index].length) {
            delete mobileFormErrors[index];
          }
        } else {
          delete mobileFormErrors[index];
        }
      }

      this.setState({
        mobileFormErrors: mobileFormErrors
      });
    }
  };

  extractSelectedMobileOptions = () => {
    let mobileOptions = this.state.mobileOptions;
    let selectedMobileOptions;

    selectedMobileOptions = Object.keys(mobileOptions).map((key) => mobileOptions[key].filter((el) => el.category.name === 'Optie Data SIM')).filter(el => el.length > 0);
    return selectedMobileOptions;
  };

  getArrayElement = (item) => {
    return item[0] !== undefined ? item[0] : [];
  };

  extractOptionQuantity = (selectedMobileOptions) => {
    let option = this.getArrayElement(selectedMobileOptions);
    option = option.selectedProducts !== undefined ? this.getArrayElement(option.selectedProducts) : 0;

    return option.quantity !== undefined ? option.quantity : 0;
  };

  addPhoneNumber = () => {
    this.setState({ profilesProcessing: true });

    const isVerlengenType = this.state.quotationType === QuotationType.TYPE_VERLENGEN;
    let selectedProfiles = this.state.selectedMobileProducts;
    let dataSimSelections = this.extractSelectedMobileOptions();
    let profilesAllowedAmount;
    let dataAllowedAmount;

    dataSimSelections = dataSimSelections.map((el) => parseInt(this.extractOptionQuantity(el)));
    dataAllowedAmount = dataSimSelections.reduce((quantity, el) => quantity + el, 0);
    if (isVerlengenType) {
      profilesAllowedAmount = selectedProfiles.reduce((quantity, el) => quantity + el.newQuantity, 0);
    } else {
      profilesAllowedAmount = selectedProfiles.reduce((quantity, el) => quantity + el.quantity, 0);
    }

    let allowedAmount = dataAllowedAmount + profilesAllowedAmount;
    let usingExistingData = this.state.isUsingExistingData;

    let telephoneNumbers = this.props.telephoneNumbers ? this.props.telephoneNumbers.filter(element => {
      const productInCurrentPortfolio = this.props.mobileProducts.find(el => el.id === element.product);

      if (element.type === 'mobile') {
        if (isVerlengenType) {
          return (!usingExistingData && !element.isFetched) || (usingExistingData && element.isFetched)
        } else {
          return !element.product || (productInCurrentPortfolio && !usingExistingData);
        }
      } else {
        return false;
      }
    }) : [];

    if (telephoneNumbers.length >= allowedAmount) {
      this.setState({ profilesProcessing: false });
      toastr.error('U kunt niet meer telefoonnummers toevoegen dan geselecteerde profielen');
      return;
    }

    const newNumber = {
      action: 1,
      blockCallAbroad: false,
      blockCallRoaming: false,
      blockNumberRecognition: false,
      blockPaidServices: false,
      device: null,
      devicePayment: null,
      extraData: false,
      fixedMobileIntegration: false,
      guideListing: false,
      id: null,
      internetWorldBundle: false,
      mobileCallEU: false,
      mobileCallOutsideEU: false,
      mobileCallToEU: false,
      mobileInternetEU: false,
      newSimCart: false,
      number: "",
      product: null,
      type: "mobile",
      custom: true
    };

    this.props.quotationActions.addAKBTelephoneNumber(this.props.quotationId, this.props.locations.selectedLocation.id, newNumber)
      .then(() => {this.setState({ profilesProcessing: false });});
  };

  addPhoneNumbersForEachProfileSelected = () => {
    this.setState({ profilesProcessing: true});

    const newNumber = {
      action: 1,
      blockCallAbroad: false,
      blockCallRoaming: false,
      blockNumberRecognition: false,
      blockPaidServices: false,
      device: null,
      devicePayment: null,
      extraData: false,
      fixedMobileIntegration: false,
      guideListing: false,
      id: null,
      internetWorldBundle: false,
      mobileCallEU: false,
      mobileCallOutsideEU: false,
      mobileCallToEU: false,
      mobileInternetEU: false,
      newSimCart: false,
      number: "",
      product: null,
      type: "mobile",
      custom: true
    };

    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    this.props.quotationActions.addTelephoneNumbersForEachProfile(this.props.quotationId, selectedLocation, newNumber)
        .then(() => this.props.mobileActions.mobileOfferProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.quotationId, selectedLocation)
          .then(() => {this.setState({ profilesProcessing: false });}));
  }

  addNumberFieldError = (index, field) => {
    let {mobileFormErrors} = this.state;

    if (!mobileFormErrors[index]) {
      mobileFormErrors[index] = [];
    }

    mobileFormErrors[index].push(field);

    this.setState({
      mobileFormErrors: mobileFormErrors
    });
  };

  toggleIsDataSaved = (value) => {
    this.setState( {
      isDataSaved: value
    });
  };

  updateMobileAddons = (event, isImport) => {
    let hasActiveAddons;
    if (isImport) {
      hasActiveAddons = event.target.value === true || event.target.value === 'true';
      this.setState({ showAddonsImportDialog: false });
    } else {
      hasActiveAddons = event;
    }

    this.props.mobileActions.updateProfilesLoader(true);

    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    this.props.mobileActions.updateMobileAddons(this.props.quotation.currentQuotation, selectedLocation, hasActiveAddons);
  };

  updateEndDateOptions = (event) => {
    let hasActiveEndDateOptions = event.target.value === true || event.target.value === 'true';
    let selectedLocation = this.props.quotation.currentQuotation.mobilePerLocation ?
      this.props.locations.selectedLocation.id : -1;

    this.props.mobileActions.updateEndDateOptions(this.props.quotation.currentQuotation, selectedLocation, hasActiveEndDateOptions);
  };

  hasEndDateOptions = (options) => {
    const product = options.find(option => {
      return option.category.name === 'Een Einddatum';
    });

    return product && !this.containsExceptionProducts();
  }

  containsExceptionProducts = () => {
    const selectedMobileProducts = this.state.selectedMobileProducts;
    const selectedProductIds = selectedMobileProducts.map(selection => selection.productId);

    const selectedExceptionProducts = selectedProductIds.filter(productId => this.props.exceptionEndDateProducts.includes(productId));

    return !!selectedExceptionProducts.length;
  }

  getExeptionEndDateProducts = () => {
    this.props.mobileActions.getExceptionEndDateProducts();
  }

  selectAvailableProfiles = (event) => {
    this.setState({ profilesProcessing: true });

    let {value} = event.target;
    const {isSalesValue} = this.props.authentication;
    const {quotationId, locations, quotation} = this.props;

    let selectedProduct = this.props.mobileProducts.find((el) => el.id === parseInt(value, 10));
    const upgradeTelephoneNumbers = this.props.telephoneNumbers ? this.props.telephoneNumbers.filter((element) => {
      let foundUpgrade = false;
      if (selectedProduct) {
        foundUpgrade = !!element.profiles.find((el) => isSalesValue ? el.productNumber : el.product_id === selectedProduct.productNumber)
      }

      return element.type === 'mobile' && [productActionTypes.UPGRADE, productActionTypes.RETENTIE].includes(element.action) && foundUpgrade;
    }) : [];
    let availableTelephoneNumbers = upgradeTelephoneNumbers.map((phoneNumber) => phoneNumber.id);

    this.setState({globalProfileSelected: value});

    this.props.quotationActions.saveAllAKBTelephoneNumber({ telephoneNumbers: availableTelephoneNumbers, product: value }, this.props.quotationId)
        .then((val) => {
          if (val && val.error) {
            this.setState({
              data: upgradeTelephoneNumbers
            });
          }
        })
        .then(() => this.props.mobileActions.mobileOfferProducts(quotation.currentQuotation.mobileOfferYear, quotationId, locations.selectedLocation.id))
        .then(() => this.setState({ profilesProcessing: false }));
  };

  selectGlobalDataSimAmount = (event) => {
    this.setState({ profilesProcessing: true });

    let {value} = event.target;
    const {quotationId, locations, quotation} = this.props;

    let telephoneNumbers = [];
    if (this.props.telephoneNumbers) {
      telephoneNumbers = this.props.telephoneNumbers.filter((phoneNumber) => {
        if (!phoneNumber.hasAddition || !additionDataSim.includes(phoneNumber.additionType)) {
          return false;
        }

        return phoneNumber.dataSimOption;
      });
    }

    let availableTelephoneNumbers = telephoneNumbers.map((phoneNumber) => phoneNumber.id);
    this.setState({globalDataSimAmountSelected: value});

    this.props.mobileActions.updateDataSimAmountForTelephoneNumbers({ telephoneNumbers: availableTelephoneNumbers, dataSimAmount: value }, quotationId)
      .then(() => this.props.mobileActions.mobileOfferProducts(quotation.currentQuotation.mobileOfferYear, quotationId, locations.selectedLocation.id))
      .then(() => this.setState({ profilesProcessing: false }));
  };

  renderDataSimAmountOptions = () => {
    let options = [];
    options.push(<option value={0} key={0}>Selecteer een optie</option>);

    [1, 2, 3].forEach((dataSimCount) => {
      options.push(<option value={dataSimCount} key={`${dataSimCount}-data-sim-count`}>{`${dataSimCount} datasim per profiel`}</option>);
    });

    return options;
  };

  selectGlobalVamoProfiles = (event) => {
    this.setState({ profilesProcessing: true });

    let {value} = event.target;
    const {quotationId, locations, quotation} = this.props;

    let telephoneNumbers = [];
    if (this.props.telephoneNumbers) {
      telephoneNumbers = this.props.telephoneNumbers.filter((phoneNumber) => {
        if (!phoneNumber.hasAddition || !additionVamo.includes(phoneNumber.additionType)) {
          return false;
        }

        return phoneNumber.vamoOptions.includes(value) || !value;
      });
    }

    let availableTelephoneNumbers = telephoneNumbers.map((phoneNumber) => phoneNumber.id);
    this.setState({globalVamoSelected: value});

    this.props.mobileActions.updateVAMOForTelephoneNumbers({ telephoneNumbers: availableTelephoneNumbers, vamoNumber: value }, quotationId)
      .then(() => this.props.mobileActions.mobileOfferProducts(quotation.currentQuotation.mobileOfferYear, quotationId, locations.selectedLocation.id))
      .then(() => this.setState({ profilesProcessing: false }));
  };

  renderVamoProductOptions = (data) => {
    let filteredData = data.filter((telephoneNumber) => telephoneNumber.hasAddition);
    let options = [];
    options.push(<option value="" key={0}>Selecteer een optie</option>);

    if (!filteredData.length) {
      return options;
    }

    let processedProducts = [];
    let distinctFilteredData = filteredData.filter((telephoneNumber) => {
      if (processedProducts.includes(telephoneNumber.productId)) {
        return false;
      }

      processedProducts.push(telephoneNumber.productId);
      return telephoneNumber.vamoOptions.length;
    });

    let distinctVamoOptions = [];
    distinctFilteredData.forEach((telephoneNumber) => {
      telephoneNumber.vamoOptions.forEach((vamoProductNumber) => {
        if (distinctVamoOptions.includes(vamoProductNumber)) {
          return;
        }

        distinctVamoOptions.push(vamoProductNumber);
      })
    });

    this.props.vamoProducts.forEach((vamoProduct) => {
      if (!distinctVamoOptions.includes(vamoProduct.productNumber)) {
        return;
      }

      options.push(<option value={vamoProduct.productNumber} key={vamoProduct.id}>{vamoProduct.name}</option>)
    });

    return options;
  };

  renderUpgradeProductOptions = (data) => {
    let options = [];
    let upgradeOptions = [];
    const {isSalesValue} = this.props.authentication;

    options.push(<option value="" key={0}>Selecteer een optie</option>);

    data.forEach((el) => {
      if (el.profiles) {
        el.profiles.forEach((profile) => {
          const profileProductNumber = isSalesValue ? profile.productNumber : profile.product_id;
          const product = this.props.mobileProducts.find((item) => item.productNumber === profileProductNumber);
          const existingProduct = upgradeOptions.find((item) => product && item.productNumber === product.productNumber);

          if (product && !existingProduct) {
            upgradeOptions.push(product);
          }
        });
      }
    });

    upgradeOptions.forEach(el => {
      options.push(<option value={el.id} key={el.id}>{el.name}</option>);
    });

    return options;
  };

  switchExistingDataTableActionsView  = (actionsView) => {
    const isUsingExistingData = this.state.isUsingExistingData;

    if (!isUsingExistingData) {
      const data = {
        target: {
          name: 'usingExistingData',
          value: true
        }
      };

      this.setState({loading: true});

      this.props.mobileActions.retrieveExistingMobileData(this.props.quotation.currentQuotation.id)
          .then(() => this.props.quotationActions.updateQuotationProperty(data.target))
          .then(() => this.setState({isUsingExistingData: true, loading: false, mainActionView: false}));
    } else {
      this.setState({mainActionsView: !actionsView});
    }
  };

  onSearch = ({target}) => {
    let {value} = target;

    this.setState({existingPhoneNumberSearch: value});
  };

  getFilteredExistingMobileData = () => {
    let {existingPhoneNumberSearch, existingMobileData} = this.state;

    if (!existingPhoneNumberSearch) {
      return existingMobileData;
    }

    return existingMobileData.filter((existingOrder) => {
      return existingOrder.phone_number.includes(existingPhoneNumberSearch);
    });
  };

  handleChangeBillingAccountCode = ({target}) => {
    this.setState({odidoBillingAccountCode: target.value});
  };

  updateBillingAccountCode = ({target}) => {
    this.updateQuotationProperty({target});
  };

  render() {
    const {quotationType} = this.state;
    const {currentQuotation} = this.props.quotation;
    const {provider} = currentQuotation;
    const selectedYear = currentQuotation.mobileOfferYear;
    const isExistingType = quotationType === QuotationType.TYPE_EXISTING;
    const isVerlengenType = quotationType === QuotationType.TYPE_VERLENGEN;
    const selectedLocationId = this.props.locations.selectedLocation.id;
    let {mobileYears, mobileProducts} = this.props;

    const isKpnProvider = provider === providerType.KPN;
    const isOdidoProvider = provider === providerType.ODIDO;
    const isVodafoneQuotation = provider === providerType.VODAFONE;
    const isOdidoPortfolio = odidoProviders.includes(provider);
    const isOwnBrandProvider = ownBrandProviders.includes(provider);

    let selectedMobileProducts = this.state.selectedMobileProducts;
    let { inputsDisabled } = this.props.quotation.currentQuotation;
    let usingExistingData = this.state.isUsingExistingData;
    let mainActionsView  = this.state.mainActionsView;
    let odidoBillingAccountCode  = this.state.odidoBillingAccountCode;
    let telephoneNumbersList = this.props.telephoneNumbers ? this.props.telephoneNumbers : [];
    let telephoneNumbers = [];

    if (isVerlengenType && isOdidoPortfolio) {
      telephoneNumbers = telephoneNumbersList
    } else {
      telephoneNumbers = telephoneNumbersList.filter(element => {
        const productInCurrentPortfolio = this.props.mobileProducts.find(el => el.id === element.product);

        if (element.type === 'mobile') {

          if (isVerlengenType) {
            return (!usingExistingData && !element.isFetched) || (usingExistingData && (element.action && [6, 7, 9, 10].includes(element.action) && element.isFetched));
          } else {
            return !element.product || productInCurrentPortfolio;
          }
        } else {
          return false;
        }
      });
    }

    const upgradeTelephoneNumbers = telephoneNumbersList.filter(element => element.type === 'mobile' && [productActionTypes.UPGRADE, productActionTypes.RETENTIE].includes(element.action));
    let newVerlengenSelectedMobileProducts;

    if (isVerlengenType && ! usingExistingData) {
      mobileProducts = mobileProducts.filter((product) => !product.fetchedFromGrexx);
    }

    if (isVerlengenType && ! usingExistingData) {
      newVerlengenSelectedMobileProducts = selectedMobileProducts.filter((selection) => selection.newQuantity > 0 || !selection.productId);

      if(!newVerlengenSelectedMobileProducts.length) {
        newVerlengenSelectedMobileProducts = [{id: "", productId: ""}];
      }
    }

    if (!selectedMobileProducts.length) {
      selectedMobileProducts = [{id: "", productId: ""}];
    }

    let exceptionBundleSelection = this.state.exceptionBundleSelection;
    let exceptionBundleIndex = this.state.exceptionBundleIndex;
    let exceptionBundleSelectedProducts = exceptionBundleSelection ? exceptionBundleSelection.selectedProducts : [];
    let exceptionBundleValue = exceptionBundleSelectedProducts.length ? exceptionBundleSelectedProducts[0] : { id: -1};

    let vodafoneContractDurations = currentQuotation.mobileOfferYear === '4.0' ? [1, 12, 24, 36] : [12, 24, 36];
    let vamoBulkSelectorNeeded = isVerlengenType && usingExistingData && !!telephoneNumbers.filter((el) => el.hasAddition && additionVamo.includes(el.additionType)).length;
    let dataSimBulkSelectorNeeded = isVerlengenType && usingExistingData && !!telephoneNumbers.filter((el) => el.hasAddition && additionDataSim.includes(el.additionType)).length;

    let existingMobileData = this.getFilteredExistingMobileData();

    const contractDurations = [12, 24, 36, 48, 60];
    const odidoContractDurations = [1, 12, 24, 36, 48, 60];
    const vodafoneMobileYears = ["Flex", "Business mobile", "Red Pro"];

    const salesValueContractDurations = [24, 36];
    const isSalesValueUser = this.props.authentication.isSalesValue;
    const isPITUser = this.props.authentication.isPIT;

    let availableContractDurations = contractDurations;

    if (isSalesValueUser) {
      availableContractDurations = salesValueContractDurations;
    }
    if (isVodafoneQuotation) {
      availableContractDurations = vodafoneContractDurations;
    }
    if (isOdidoPortfolio) {
      availableContractDurations = odidoContractDurations;
    }

    const showContractLevel = !this.state.showPerLocation || (this.state.showPerLocation && selectedLocationId === -1);
    const showMobileSelections = (!this.state.showPerLocation || (this.state.showPerLocation && selectedLocationId > 0))
      && (! isExistingType || (isExistingType && usingExistingData === false) || isVerlengenType);
    const showMobileYearOffers = isExistingType || isVerlengenType || currentQuotation.mobileNeeded;

    const upgradingMobileData = this.state.upgradingMobileData;

    const { theme } = this.props.authentication;

    const PreloaderParent = styled.div`
      .preloader-wrapper {
        width: 35px; 
        height: 35px; 
        display: flex;
      }
      
      .spinner-layer {
         border-color: white;
       }
    `;

    return (
      <div>
        {this.state.loading
          ? <LoaderComponent theme={theme} /> :
          (
            <React.Fragment>
              <div className="row" id="Forms" style={{display: 'grid'}}>
                <GeneralDialog
                  dialogState={this.state.dialogAKBOpened}
                  onRequestClose={this.closeAKBWarningDialog}
                  headerText="Aantal verlagen niet mogelijk"
                  closeHandler={this.closeAKBWarningDialog}
                  dialogBodyContent={(
                    <DefaultDialogBody
                      text="Op dit moment kan dit aantal niet verlaagd worden. Daarvoor moet er ten minste één
                             koppeling tussen een telefoonnummer en dit type profiel opgeheven worden."
                    />
                  )}
                  rightButtonAction={this.closeAKBWarningDialog}
                  rightButtonText={"Ik begrijp het."}
                />

                <GeneralDialog
                  dialogState={this.state.showContractDialog}
                  onRequestClose={this.closeContractDialog}
                  headerText="Aantal contracten aanpassen niet mogelijk"
                  closeHandler={this.closeContractDialog}
                  dialogBodyContent={(
                    <DefaultDialogBody text={this.state.resetAddonsDialogContent}/>
                  )}
                  leftButtonAction={this.closeContractDialog}
                  leftButtonText={"Contracten niet aanpassen"}
                  leftButtonLeft={true}
                  rightButtonAction={this.resetAddons}
                  rightButtonText={this.state.resetAddonsButtonLabel}
                />

                <GeneralDialog
                  dialogState={this.state.showMobileYearDialog}
                  onRequestClose={this.closeMobileYearDialog}
                  headerIcon="remove_circle_outline"
                  headerText="Veranderen mobiele propositie"
                  dialogBodyContent={(
                    <DefaultDialogBody
                      text="LET OP: Alle producten van de huidige mobiele propositie worden hiermee verwijderd. Wilt u doorgaan?"/>
                  )}
                  leftButtonAction={() => this.changeMobileYear(this.state.mobileYear)}
                  leftButtonText={"Ja"}
                  leftButtonLeft={true}
                  rightButtonAction={this.closeMobileYearDialog}
                  rightButtonText={'Nee'}
                />

                <GeneralDialog
                  dialogState={this.state.showAddonsImportDialog}
                  onRequestClose={this.closeAddonsImportDialog}
                  headerIcon="edit"
                  headerText="Extra's activeren"
                  dialogBodyContent={(
                    <Input
                      s={12}
                      name={"quotationHasActiveAddons"}
                      type='select'
                      label="Wilt u voor deze offerte alle voordeelmodules activeren?"
                      disabled={inputsDisabled}
                      onChange={(event) => this.updateMobileAddons(event, true)}
                      value={this.state.hasActiveAddons}
                      multiple={false}
                    >
                      <option value={true}>Ja</option>
                      <option value={false}>Nee</option>
                    </Input>
                  )}
                  rightButtonAction={this.closeAddonsImportDialog}
                  rightButtonText={'Laat het standaard geactiveerd'}
                />

                <GeneralDialog
                  dialogState={this.state.presetDiscountsInvalid}
                  closeHandler={this.closePresetDiscountsDialog}
                  headerText={'Netwerkactiekorting fout'}
                  dialogBodyContent={(
                    <DefaultDialogBody text='U heeft niet genoeg mandaat om de netwerkactiekortingen toe te passen. Neem contact op met sales Support om dit om te lossen.'/>
                  )}
                  rightButtonAction={this.closePresetDiscountsDialog}
                  rightButtonText={'Begrepen'}
                />

                <MobileToggle
                  showContractLevel={showContractLevel}
                  currentQuotation={currentQuotation}
                  isSalesValue={this.props.authentication.isSalesValue}
                  mobilePerLocation={this.state.mobilePerLocation}
                  showMobileYears={this.state.showMobileYears}
                  showPerLocation={currentQuotation.mobilePerLocation}
                  showMobileProducts={this.state.showMobileProducts}
                  availableContractDurations={availableContractDurations}
                  inputsDisabled={inputsDisabled}
                  isExistingType={isExistingType}
                  isVerlengenType={isVerlengenType}
                  usingExistingData={usingExistingData}
                  updateQuotationProperty={this.updateQuotationProperty}
                  getMobileYears={this.getMobileYears}
                  getExistingMobileData={this.getExistingMobileData}
                  resetExistingMobileData={this.resetExistingMobileData}
                  vodafoneMobileYears={vodafoneMobileYears}
                  isDataSaved={this.state.isDataSaved}
                  company={this.props.authentication.company}
                  updateMobileAddons={this.updateMobileAddons}
                  hasActiveAddons={this.state.hasActiveAddons}
                  profilesLoader={this.state.profilesLoader}
                  mainActionsView={mainActionsView}
                  switchExistingDataTableActionsView={this.switchExistingDataTableActionsView}
                  isOdidoPortfolio={isOdidoPortfolio}
                />

                {showMobileYearOffers && showContractLevel && [providerType.KPN].includes(currentQuotation.provider) &&
                (isExistingType ? !usingExistingData && usingExistingData !== null : true) ?
                  <div className="col s12">
                    <div className="row">
                      {this.state.showMobileYears && mobileYears.map((year) => {
                        return (![2016, 2018].includes(parseInt(year, 10)) ? <div className={'col s4'} style={!isVerlengenType ? {paddingLeft: 0} : {}}>
                          <button
                            key={year}
                            name="mobileOfferYear"
                            value={year}
                            disabled={inputsDisabled}
                            className={`btn doceri-btn-left ratio-btn white-text ${String(selectedYear) === String(year) ? "active" : "ratio-btn-right"}`}
                            style={{width: '100%'}}
                            onClick={this.openMobileYearDialog}>{parseInt(year, 10) >= 2019 ? "MKB" : "Zakelijk"} Mobiel {year}</button>
                        </div> : '')
                      })
                      }
                    </div>
                  </div>: ''}

                {
                  currentQuotation.provider === providerType.VODAFONE && currentQuotation.mobileOfferYear && showContractLevel && (
                    <div className="col s12">
                      <div className="row">
                        { ["regular", "sim-only"].map((el) =>
                          <button
                            key={el}
                            disabled={inputsDisabled}
                            name="mobileType"
                            value={el}
                            style={el === "regular" ? {marginRight: 15} : {}}
                            className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${currentQuotation.mobileType === el ? "active" : ""}`}
                            onClick={this.updateQuotationProperty}>{el === "regular" ? "Regulier" : "Sim Only"}
                          </button>)
                        }
                      </div>
                    </div>
                  )
                }
                {showContractLevel && currentQuotation.provider === providerType.VODAFONE && currentQuotation.mobileOfferYear && !isVerlengenType &&
                <div className="col s12">
                  <div className="row">
                    <button
                      className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${this.state.showMobileYears && this.state.showPerLocation ? "active" : ""}`}
                      style={{marginRight: 15}}
                      onClick={() => this.getMobileYears(true, true, currentQuotation.isMobileNeeded)} disabled={inputsDisabled}
                    >
                      Mobiel instellen per locatie
                    </button>
                    <button
                      className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${this.state.showMobileYears && !this.state.showPerLocation ? "active" : ""}`}
                      onClick={() => this.getMobileYears(false, true, currentQuotation.isMobileNeeded)} disabled={inputsDisabled}
                    >
                      Mobiel instellen per offerte
                    </button>
                    <Input
                      name="mobileContractDuration"
                      type="select"
                      label="Contractduur *"
                      onChange={this.updateQuotationProperty}
                      value={currentQuotation.mobileContractDuration}
                      multiple={false}
                      required
                      disabled={inputsDisabled}
                      className={(inputsDisabled) || "validate"}
                    >
                      <option value=""/>
                      {availableContractDurations.map((x) => (
                        <option value={x}>{x} {x === 1 ? 'maand' : 'maanden'}</option>
                      ))}
                    </Input>
                  </div>
                </div>
                }

                { showContractLevel && isOdidoPortfolio && (
                  <div className="col s12">
                    <div className="row">
                      <Input
                        name="mobileContractDuration"
                        type="select"
                        label="Contractduur *"
                        onChange={this.updateQuotationProperty}
                        value={currentQuotation.mobileContractDuration}
                        multiple={false}
                        required
                        disabled={inputsDisabled}
                        className={inputsDisabled || "validate"}
                      >
                        <option value=""/>
                        {availableContractDurations.map((x) => (
                          <option value={x}>{x} {x === 1 ? 'maand' : 'maanden'}</option>
                        ))}
                      </Input>
                    </div>
                  </div>
                )}

                {showContractLevel && isOdidoProvider && (
                    <div className="col s12">
                      <div className="row">
                        <Input
                          s={12}
                          m={4}
                          l={3}
                          type="text"
                          name="odidoBillingAccountCode"
                          label="Odido Billing Account Code"
                          style={{marginBottom: '20px'}}
                          onChange={this.handleChangeBillingAccountCode}
                          onBlur={this.updateBillingAccountCode}
                          value={odidoBillingAccountCode}
                          disabled={inputsDisabled}
                        />
                      </div>
                      {
                        ! odidoBillingAccountCode.length && (
                          <div className="error-small">
                            Om te bepalen of de Samen Unlimited-bundel beschikbaar is, geeft u de billingAccountCode op
                          </div>
                        )
                      }
                    </div>
                  )
                }

                {(!isVerlengenType || (isVerlengenType && isOdidoPortfolio)) &&
                <MobileProfiles
                  isVerlengenType={isVerlengenType}
                  inputsDisabled={inputsDisabled}
                  showMobileSelections={showMobileSelections}
                  showMobileProducts={this.state.showMobileProducts}
                  showMobileYears={this.state.showMobileYears}
                  isUsingExistingData={this.state.isUsingExistingData}
                  isVodafoneQuotation={isVodafoneQuotation}
                  hasActiveEndDateOptions={this.state.hasActiveEndDateOptions}
                  selectedMobileProducts={selectedMobileProducts}
                  mobileOptions={this.state.mobileOptions}
                  mobileProducts={mobileProducts}
                  provider={currentQuotation.provider}
                  onQuantityChangeCallback={this.onQuantityChangeCallback}
                  onQuantitySaveAction={this.onQuantitySaveAction}
                  onChangeCallback={this.onChangeCallback}
                  renderExtraSelect={this.renderExtraSelect}
                  getChildSelections={this.getChildSelections}
                  addNewProductSelection={this.addNewProductSelection}
                  existingData={this.state.existingMobileData}
                  hasEndDateOptions={this.hasEndDateOptions}
                  updateEndDateOptions={this.updateEndDateOptions}
                  profilesLoader={this.state.profilesLoader}
                  isOdidoPortfolio={isOdidoPortfolio}
                />
                }

                {showMobileSelections && this.state.showMobileProducts && this.state.showMobileYears && !isVodafoneQuotation && !isOdidoPortfolio && !(isVerlengenType || isExistingType) &&
                <Col s={12}>
                  <Row style={{paddingTop: '20px'}}>
                    <div className="line tussenkop">Extra's activeren</div>
                  </Row>
                  <Row>
                    <Input
                      s={12}
                      name={"quotationHasActiveAddons"}
                      type='select'
                      label="Wilt u voor deze offerte alle voordeelmodules activeren?"
                      disabled={inputsDisabled || this.state.profilesLoader}
                      onChange={(event) => this.updateMobileAddons(event, true)}
                      value={this.state.hasActiveAddons}
                      multiple={false}
                    >
                      <option value={true}>Ja</option>
                      <option value={false}>Nee</option>
                    </Input>
                  </Row>
                </Col>
                }
              </div>

              {isVerlengenType && !usingExistingData && !isOdidoPortfolio && (
                <div id="Forms" style={{paddingBottom: 10}}>
                  <div className="row">
                    <div className="col s12">
                      <div className="row">
                        <h1 className={'ratio-section-title'}>
                        <span className={'ratio-section-title'}>
                            <i className="small material-icons left ratio-section-title">phone_android</i>
                          Nieuwe mobiele producten bestellen
                        </span>
                        </h1>
                      </div>
                    </div>
                    <MobileProfiles
                      isVerlengenType={isVerlengenType}
                      inputsDisabled={inputsDisabled}
                      showMobileSelections={showMobileSelections}
                      showMobileProducts={this.state.showMobileProducts}
                      showMobileYears={this.state.showMobileYears}
                      isUsingExistingData={this.state.isUsingExistingData}
                      isVodafoneQuotation={isVodafoneQuotation}
                      hasActiveEndDateOptions={this.state.hasActiveEndDateOptions}
                      selectedMobileProducts={newVerlengenSelectedMobileProducts}
                      mobileOptions={this.state.mobileOptions}
                      mobileProducts={mobileProducts}
                      provider={currentQuotation.provider}
                      onQuantityChangeCallback={this.onQuantityChangeCallback}
                      onQuantitySaveAction={this.onQuantitySaveAction}
                      onChangeCallback={this.onChangeCallback}
                      renderExtraSelect={this.renderExtraSelect}
                      getChildSelections={this.getChildSelections}
                      addNewProductSelection={this.addNewProductSelection}
                      existingData={this.state.existingMobileData}
                      hasEndDateOptions={this.hasEndDateOptions}
                      updateEndDateOptions={this.updateEndDateOptions}
                      profilesLoader={this.state.profilesLoader}
                    />
                  </div>
                </div>
              )}

              {(isVerlengenType || isExistingType ) && usingExistingData && this.state.showMobileYears && !isOdidoPortfolio &&
              (
                <div id="Forms" style={{paddingBottom: 10}}>
                  <div className="row">
                    <div className="col s12 header-class">
                      <div className="row">
                        <div className="col s8">
                          <h1 className={'ratio-section-title'}>
                              <span className={'ratio-section-title'}>
                                <i className="small material-icons left ratio-section-title">phone_android</i>
                                {mainActionsView ? 'Bestaande mobiele orders wijzigen' : 'Producten toevoegen aan bestaand order'}
                              </span>
                          </h1>
                        </div>
                        {
                          mainActionsView && (
                            <div className="col s4">
                              <div className="overviewkop">
                                <form>
                                  <div className="input-field margingone inner-addon">
                                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                                    <DelayInput
                                      delayTimeout={2000}
                                      id="search"
                                      type="search"
                                      name="existingPhoneNumberSearch"
                                      value={this.state.existingPhoneNumberSearch}
                                      onChange={this.onSearch}
                                      placeholder="Telefoonnummer zoeken..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div className="col s12">
                      <ExistingMobileData
                        data={existingMobileData}
                        inputsDisabled={inputsDisabled}
                        upgradingData={this.state.upgradingMobileData}
                        isDataSaved={this.state.isDataSaved}
                        toggleIsDataSaved={this.toggleIsDataSaved}
                        dataRetrievalInProcess={this.state.dataRetrievalInProcess}
                        mainActionsView={mainActionsView}
                        getMobileProducts={this.getMobileProducts}
                        currentQuotation={currentQuotation}
                        selectedLocation={this.props.locations.selectedLocation.id}
                      />
                    </div>
                  </div>
                </div>
              )
              }

              { this.state.mobileOptions !== undefined && currentQuotation.mobileOfferYear === 'Flex' && exceptionBundleSelection && (
                <div id="Forms" style={{paddingBottom: "10px"}}>
                  <Row>
                    <Col s={12}>
                      <div>
                        <h1 className={'ratio-section-title'}>
                        <span className="ratio-section-title">
                          <i className="small material-icons left ratio-section-title">phone_android</i> Wenst u een Company Data Bundle?
                        </span>
                        </h1>
                      </div>
                    </Col>
                    <Col s={12}>
                      <Row key={`${exceptionBundleSelection.category.name}`} style={{marginBottom: 0}}>
                        <Input
                          s={12}
                          type="select"
                          label='Flex Company Data bundle (EU incl. NL)'
                          onChange={
                            this.handleExceptionBundleChange(exceptionBundleSelection.selectionId, exceptionBundleIndex)
                          }
                          multiple={false}
                          disabled={inputsDisabled}
                          value={exceptionBundleValue.id}
                        >
                          <option key={-1} value={-1}>
                            Niets geselecteerd
                          </option>
                          {exceptionBundleSelection.products.map(this.renderOptionProduct)}
                        </Input>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              {isVerlengenType && usingExistingData && upgradeTelephoneNumbers.length > 0 && (
                <div id="Forms" style={{paddingBottom: "10px"}}>
                  <Row>
                    <Col s={12}>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h1 className={'ratio-section-title'}>
                            <span className="ratio-section-title">
                              <i className="small material-icons left ratio-section-title">settings_phone</i>{upgradeTelephoneNumbers.length} Telefoonnummers:
                            </span> {isSalesValueUser ? 'retentie opties' : 'upgrade opties'}
                        </h1>

                        <div style={{display: "flex", alignItems: 'center'}}>
                          <p style={{whiteSpace: 'nowrap', fontSize: '12px', fontWeight: 'bold', padding: '0 5px'}}>
                            Profiel toewijzen waar mogelijk:
                          </p>

                          <RegularSelect
                            s={12}
                            name="all_prodfiles"
                            onChange={(event) => this.selectAvailableProfiles(event)}
                            value={this.state.globalProfileSelected}
                            multiple={false}
                            disabled={inputsDisabled}
                          >
                            {this.renderUpgradeProductOptions(upgradeTelephoneNumbers)}
                          </RegularSelect>
                        </div>
                      </div>
                    </Col>
                    { this.state.profilesProcessing && (
                      <div className="loader-component" style={{ zIndex: 99 }}>
                        <div className="overlay" style={{ backgroundColor: `rgba(255, 255, 255, 0.3)` }}/>
                        <Preloader size='medium'/>
                      </div>
                    )}
                    {!isOdidoPortfolio &&
                    <UpgradeMobileTelephoneNumbersTable
                        data={upgradeTelephoneNumbers}
                        mobileProducts={this.getSelectedProfilesData()}
                        mobileOptions={this.state.mobileOptions}
                        getAllMobileProducts={this.props.mobileProducts}
                        addPhoneNumber={this.addPhoneNumber}
                        addPhoneNumbersForEachProfileSelected={this.addPhoneNumbersForEachProfileSelected}
                        quotationStatus={this.props.quotation.currentQuotation.status}
                        locationId={selectedLocationId}
                        getMobileProducts={this.getMobileProducts}
                        inputsDisabled={inputsDisabled}
                        PreloaderParent={PreloaderParent}
                        renderExtraSelect={this.renderExtraSelect}
                        getChildSelections={this.getChildSelections}
                        selectedMobileProducts={selectedMobileProducts}
                        handleOptionsChange={this.handleOptionsChange}
                        handleMultipleOptionsChange={this.handleMultipleOptionsChange}
                        upgradeData={upgradingMobileData}
                        existingMobileData={this.state.existingMobileData}
                    />
                    }
                  </Row>
                </div>
              )}

              {showMobileSelections && this.state.showMobileYears && !!ProviderHelper.hasModuleEnabled(this.props.quotation.currentQuotation, 'mobileTelephoneNumbers') &&
              <div id="Forms" style={{paddingBottom: "10px"}}>
                <Row>
                  <Col s={12}>
                    {vamoBulkSelectorNeeded || dataSimBulkSelectorNeeded ? (
                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h1 className={'ratio-section-title'}>
                            <span className="ratio-section-title">
                              <i className="small material-icons left ratio-section-title">settings_phone</i>{telephoneNumbers.length} Telefoonnummers:
                            </span> gewenste opties
                        </h1>

                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '20px'}}>
                          {dataSimBulkSelectorNeeded && (
                            <div style={{marginRight: '20px'}}>
                              <p style={{
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                padding: '0 5px',
                                marginBottom: '0'
                              }}>
                                Data sims
                              </p>

                              <RegularSelect
                                s={12}
                                name="dataSimAmount"
                                onChange={(event) => this.selectGlobalDataSimAmount(event)}
                                value={this.state.globalDataSimAmountSelected}
                                multiple={false}
                                disabled={inputsDisabled}
                              >
                                {this.renderDataSimAmountOptions(telephoneNumbers)}
                              </RegularSelect>
                            </div>)
                          }

                          {vamoBulkSelectorNeeded && (
                            <div>
                              <p style={{
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                padding: '0 5px',
                                marginBottom: '0'
                              }}>
                                VAMO toewijzen waar mogelijk:
                              </p>

                              <RegularSelect
                                s={12}
                                name="all_vamo"
                                onChange={(event) => this.selectGlobalVamoProfiles(event)}
                                value={this.state.globalVamoSelected}
                                multiple={false}
                                disabled={inputsDisabled}
                              >
                                {this.renderVamoProductOptions(telephoneNumbers)}
                              </RegularSelect>
                            </div>)
                          }
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1 className={'ratio-section-title'}>
                          <span className="ratio-section-title">
                              <i className="small material-icons left ratio-section-title">settings_phone</i>{telephoneNumbers.length} Telefoonnummers:
                          </span> gewenste opties
                        </h1>
                      </div>
                    )}
                  </Col>
                  { this.state.profilesProcessing && (
                    <div className="loader-component" style={{ zIndex: 99 }}>
                      <div className="overlay" style={{ backgroundColor: `rgba(255, 255, 255, 0.3)` }}/>
                      <Preloader size='medium'/>
                    </div>
                  )}
                  {
                    (!isOdidoPortfolio || (isOdidoPortfolio && this.state.showMobileProducts)) &&
                    <MobileTelephoneNumbersTable
                        data={telephoneNumbers}
                        mobileProducts={this.getSelectedProfilesData()}
                        getAllMobileProducts={this.props.mobileProducts}
                        addPhoneNumber={this.addPhoneNumber}
                        addPhoneNumbersForEachProfileSelected={this.addPhoneNumbersForEachProfileSelected}
                        quotationStatus={this.props.quotation.currentQuotation.status}
                        locationId={selectedLocationId}
                        getMobileProducts={this.getMobileProducts}
                        inputsDisabled={inputsDisabled}
                        PreloaderParent={PreloaderParent}
                        isPITUser={isPITUser}
                        isSalesValue={isSalesValueUser}
                        showAddonsImportDialog={this.openAddonsImportDialog}
                        isUsingExistingData={this.state.isUsingExistingData}
                        isVerlengenType={isVerlengenType}
                    />
                  }
                </Row>
              </div>
              }

              {/*{this.state.showMobileProducts && this.state.showMobileYears &&*/}
              <CustomHardwareWithCategories
                type="mobiel"
                connectionId={null}
                isGrouped={false}
                inputsDisabled={inputsDisabled}
                isUsingExistingData={this.state.isUsingExistingData}
                isVerlengenType={isVerlengenType}
              />
              {/*}*/}
            </React.Fragment>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, customer, mobile, hostedVoice, authentication, locations, loading, warnings}) => {
  return {
    locations,
    quotation,
    customer,
    quotationId: quotation.currentQuotation.id,
    mobileOptions: quotation.currentQuotation.mobileOptions,
    mobileYears: mobile.mobileYears,
    exceptionEndDateProducts: mobile.exceptionEndDateProducts,
    mobileProducts: mobile.mobileProducts,
    mobileDataProducts: mobile.mobileDataProducts,
    existingMobileData: mobile.existingMobileData,
    currentMobileProducts: mobile.currentMobileProducts,
    currentMobileProductOptions: mobile.currentMobileProductOptions,
    hostedVoiceOptions: quotation.currentQuotation.hostedVoiceOptions,
    hostedVoice: hostedVoice,
    authentication: authentication,
    telephoneNumbers: quotation.currentQuotation.telephone_numbers,
    profilesLoader: mobile.profilesLoader,
    vamoProducts: mobile.vamoProducts,
    loading,
    warnings,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    mobileActions: bindActionCreators(mobileActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch),
    hostedVoiceActions: bindActionCreators(hostedVoiceActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileStep);
