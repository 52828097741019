import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {DelayInput} from "react-delay-input";
import {Button, Icon, Input} from 'react-materialize';

import {bindActionCreators} from "redux";

import hostedVoiceActions from "../../../actions/workflow/hostedVoiceActions";

import GeneralDialog from "../../../containers/common/GeneralDialog";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";

import providerType from "../../../constants/providerType";

class HostedVoiceOptionsYielder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileOptions: this.props.uccOptions.profileOptions || [],
            profileCategories: this.props.uccOptions.profileCategories || [],
            functionalitiesList: this.props.uccOptions.functionalitiesList || [],
            telephoneNumberOptions: this.props.uccOptions.telephoneNumberOptions || [],
            oneTimeCostExtensionOptions: this.props.uccOptions.oneTimeCostExtensionOptions || [],
            profiles: [],
            selectedFunctionalities: [],
            profileSelectedCategories: [],
            telephoneNumberSelections: [],
            oneTimeCostExtensionSelections: [],
            profilesLoader: false,
            telephoneNumberLoader: false,
            oneTimeCostExtensionLoader: false,
        };
    }

    componentDidMount() {
        const quotationId = this.props.quotation.id;
        this.props.hostedVoiceActions.getUccOptions(quotationId);
        this.updateSelections();
    }

    componentDidUpdate(prevProps) {
        const {
            profileOptions,
            profileCategories,
            functionalitiesList,
            telephoneNumberOptions,
            oneTimeCostExtensionOptions
        } = this.props.uccOptions;

        if (prevProps.uccOptions  !== this.props.uccOptions) {
            this.setState({
                profileOptions: profileOptions,
                profileCategories: profileCategories,
                functionalitiesList: functionalitiesList,
                telephoneNumberOptions: telephoneNumberOptions,
                oneTimeCostExtensionOptions: oneTimeCostExtensionOptions
            });
        }
    }

    updateSelections = () => {
        const quotationId = this.props.quotation.id;
        this.props.hostedVoiceActions.getUccSelections(quotationId).then((response) => {
            if (response) {
                this.setState({
                    profiles: response.profiles,
                    profileSelectedCategories: response.profileSelectedCategories,
                    selectedFunctionalities: response.selectedFunctionalities,
                    telephoneNumberSelections: response.telephoneNumberSelections,
                    oneTimeCostExtensionSelections: response.oneTimeCostExtensionSelections
                });
            }
        });
    }

    onSelectUccProfileCategory = ({target}) => {
        let {value, checked} = target;
        let selectedProfileCategories = [];
        let {profileSelectedCategories} = this.state;

        if (checked) {
            selectedProfileCategories = [...profileSelectedCategories, value];
        } else {
            selectedProfileCategories = profileSelectedCategories.filter((selectedCategory) => selectedCategory !== value);
        }

        this.setState({
            ...this.state,
            profileSelectedCategories: selectedProfileCategories
        });
    }

    getCategoryLabel = (profileCategory) => {
        const {profileCategories} = this.state;

        const foundCategory = profileCategories.find((category) => category.value === profileCategory);

        return foundCategory ? foundCategory.name : '';
    }

    renderCategorySelections = (profileCategory) => {
        const categoryLabel = this.getCategoryLabel(profileCategory);
        const {profiles, profileOptions, profilesLoader} = this.state;

        const {inputsDisabled} = this.props.quotation;

        const categoryProfiles = profileOptions[profileCategory] || [];
        const selectedProfiles = profiles[profileCategory] || [{id: null, amount: 0}];

        return (
            <div className="row">
                <div className="col s12 line tussenkop">
                    <h1 className={'ratio-section-title'}>
                        {categoryLabel} gewenste opties
                    </h1>
                </div>

                <div className="col s12">
                    {selectedProfiles.map((selectedProfile) => {
                        return (
                            <div className="row">
                                {this.renderProfileSelection(selectedProfile, profileCategory, categoryProfiles)}
                            </div>
                        );
                    })}
                </div>

                <div className="col s12">
                    <button
                        name="addNewProfile"
                        className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                        onClick={() => this.addNewProfile(profileCategory)}
                        disabled={inputsDisabled}
                        style={profilesLoader ? {minWidth: '150px'} : null}
                    >
                        {profilesLoader ? (
                            <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                                <span className="indeterminate overviewkopbggroen"/>
                            </span>) : ('Nog een profiel toevoegen')
                        }
                        <Icon right>
                            add
                        </Icon>
                    </button>
                </div>
            </div>
        );
    }

    renderProfileSelection = (profile, profileCategory, profileOptions) => {
        const shouldHaveAmount = profile.id;
        const profileClass = shouldHaveAmount ? "col s11" : "col s12";

        const {profilesLoader} = this.state;
        const {inputsDisabled} = this.props.quotation;

        return (
            <div className="col s12">
                {
                    shouldHaveAmount && (
                        <Input
                            s={1}
                            name='amount'
                            type='number'
                            label='Aantal'
                            disabled={inputsDisabled || profilesLoader}
                            value={profile.amount}
                            onChange={this.onProfileQuantityChangeCallback(profile, profileCategory)}
                            onBlur={() => this.onProfileQuantitySave(profile.id, profileCategory)}
                            min={1}
                            max={999}
                        />
                    )
                }
                <Input
                    s={profileClass}
                    name="profile"
                    type="select"
                    label="Profielen"
                    disabled={inputsDisabled || profilesLoader}
                    onChange={this.onChangeProfile(profile.id, profileCategory)}
                    value={profile.id}
                    multiple={false}
                >
                    <option value={null}>Niets geselecteerd</option>
                    {
                        profileOptions.map((profileOption) => {
                            return <option key={profileOption.name} value={profileOption.id}>{profileOption.name}</option>
                        })
                    }
                </Input>
            </div>
        );
    }

    addNewProfile = (profileCategory) => {
        const {profiles} = this.state;
        const categoryProfiles = profiles[profileCategory] || [];

        let updatedCategoryProfiles = [...categoryProfiles, {id: null, amount: 1}];
        let updatedProfiles = profiles;

        updatedProfiles[profileCategory] = updatedCategoryProfiles;

        this.setState({profiles: updatedProfiles});
    }

    onProfileQuantityChangeCallback = (profile, profileCategory) => (event, value) => {
        const {target} = event;
        const {min, max} = target;

        if (min > value || max < value) {
            return;
        }

        const {profiles} = this.state;
        let updatedProfiles = profiles;
        let categoryProfiles = updatedProfiles[profileCategory];
        let updatedCategoryProfiles = [...categoryProfiles];
        let indexProfile = updatedCategoryProfiles.findIndex((profileItem) => profileItem.id === profile.id);
        if (indexProfile < 0) {
            return;
        }

        let updatedProfile = profile;
        updatedProfile.amount = value;

        updatedCategoryProfiles[indexProfile] = updatedProfile;
        updatedProfiles[profileCategory] = updatedCategoryProfiles;

        this.setState({profiles: updatedProfiles});
    }

    onProfileQuantitySave = (profileId, profileCategory) => {
        const {profiles} = this.state;
        const quotationId = this.props.quotation.id;
        const categoryProfiles = profiles[profileCategory];

        const foundProfile = categoryProfiles.find((profile) => profile.id === profileId);
        if (! foundProfile) {
            return;
        }

        const data = {
            operation: 'updateAmount',
            profile: foundProfile
        };

        this.setState({profilesLoader: true});
        this.props.hostedVoiceActions.patchUccProfiles(quotationId, data).then(() => {
            this.updateSelections();
            this.setState({profilesLoader: false});
        });
    }

    onChangeProfile = (profileId, profileCategory) => (event, value) => {
        value = value !== null ? parseInt(value) : null;

        if (!profileId && !value) {
            return;
        }
        if (profileId === value) {
            return;
        }

        let data;
        const {profiles} = this.state;
        const quotationId = this.props.quotation.id;
        const categoryProfiles = profiles[profileCategory];

        if (!profileId && value) {
            data = {
                operation: 'add',
                profile: {
                    id: value,
                    amount: 1
                }
            };

            this.setState({profilesLoader: true});
            this.props.hostedVoiceActions.patchUccProfiles(quotationId, data).then(() => {
                this.updateSelections();
                this.setState({profilesLoader: false});
            });

            return;
        }

        const foundProfile = categoryProfiles.find((profile) => profile.id === profileId);
        if (! foundProfile) {
            return;
        }

        if (profileId && !value) {
            data = {
                operation: 'remove',
                profile: {
                    id: profileId
                }
            };

            this.setState({profilesLoader: true});
            this.props.hostedVoiceActions.patchUccProfiles(quotationId, data).then(() => {
                this.updateSelections();
                this.setState({profilesLoader: false});
            });

            return;
        }

        const alreadySelectedProfile = categoryProfiles.find((profile) => profile.id === value);
        if (alreadySelectedProfile) {
            return;
        }

        data = {
            operation: 'change',
            profile: {
                newId: value,
                id: profileId
            }
        };

        this.setState({profilesLoader: true});
        this.props.hostedVoiceActions.patchUccProfiles(quotationId, data).then(() => {
            this.updateSelections();
            this.setState({profilesLoader: false});
        });
    }

    renderFunctionalities = () => {
        const {inputsDisabled} = this.props.quotation;
        const {functionalitiesList, selectedFunctionalities} = this.state;

        return (
            <div className="row">
                {functionalitiesList.map((functionality) => {
                    let selectedFunctionality = selectedFunctionalities[functionality.id] !== undefined ? selectedFunctionalities[functionality.id] : null;
                    let functionalityStatus = selectedFunctionality !== null;
                    let shouldHaveAmount = functionalityStatus;
                    let functionalitySizeClass = shouldHaveAmount ? 10 : 12;
                    let functionalityAmount = selectedFunctionality ? selectedFunctionality.amount : 0;

                    return (
                        <div className="col s6" style={{ display: "flex"}}>
                            <Input
                                s={2}
                                name='amount'
                                type='number'
                                label='Aantal'
                                disabled={inputsDisabled}
                                value={functionalityAmount}
                                onChange={this.onFunctionalityQuantityChangeCallback(functionality.id)}
                                onBlur={() => this.onFunctionalityQuantitySave(functionality.id)}
                                min={0}
                                max={999}
                            />

                            <div style={{
                                padding: '10px',
                                textAlign: 'center',
                                display: 'block',
                                position: 'relative',
                                width: '100%',
                                height: '3rem',
                                border: '1px solid #939393',
                                opacity: 1,
                                pointerEvents: 'initial',
                                borderRadius: '4px'
                            }}>
                                {functionality.name}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    onChangeFunctionality = (id, amount) => {
        let updatedSelectedFunctionalities = [];
        let {selectedFunctionalities} = this.state;

        id = parseInt(id);

        updatedSelectedFunctionalities = selectedFunctionalities;

        if (amount === 0 && updatedSelectedFunctionalities[id].amount > 0) {
            delete updatedSelectedFunctionalities[id];
        } else {
            if (!updatedSelectedFunctionalities[id]) {
                updatedSelectedFunctionalities[id] = {
                    id: id,
                    amount: amount,
                    name: ''
                }
            }
        }

        this.setState({
            selectedFunctionalities: updatedSelectedFunctionalities
        });
    }

    onFunctionalityQuantityChangeCallback = (functionalityId) => (event, value) => {
        const {target} = event;
        let {min, max} = target;

        min = parseInt(min);
        max = parseInt(max);
        value = parseInt(value);

        if (min > value || max < value) {
            return;
        }
        this.onChangeFunctionality(functionalityId, value);

        const {selectedFunctionalities} = this.state;
        let updatedFunctionalities = selectedFunctionalities;
        let foundFunctionality = updatedFunctionalities[functionalityId];
        if (foundFunctionality === undefined) {
            return;
        }

        updatedFunctionalities[functionalityId]['amount'] = value;

        this.setState({selectedFunctionalities: updatedFunctionalities});
    }

    onFunctionalityQuantitySave = (functionalityId) => {
        const {selectedFunctionalities} = this.state;
        const quotationId = this.props.quotation.id;

        let updatedFunctionalities = selectedFunctionalities;
        let foundFunctionality = updatedFunctionalities[functionalityId];
        if (foundFunctionality === undefined) {
            return;
        }

        const data = {
            operation: 'updateAmount',
            id: foundFunctionality.id,
            amount: foundFunctionality.amount
        };

        this.props.hostedVoiceActions.patchUccOptions(quotationId, data).then(() => this.updateSelections());
    }

    renderTelephoneNumbers = () => {
        const {inputsDisabled} = this.props.quotation;
        const {telephoneNumberLoader, telephoneNumberOptions} = this.state;
        const telephoneNumberSelections = this.state.telephoneNumberSelections || [{id:null, amount: 0}];

        return (
            <div className="row">
                <div className="col s12 line tussenkop">
                    <h1 className={'ratio-section-title'}>
                        Doorkiesreeks: gewenste opties
                    </h1>
                </div>

                {telephoneNumberSelections.map((telephoneNumber) => this.renderTelephoneNumber(telephoneNumber, telephoneNumberOptions))}

                <div className="col s12">
                    <button
                        name="addTelephoneNumber"
                        className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                        onClick={this.addNewTelephoneNumber}
                        disabled={inputsDisabled}
                        style={telephoneNumberLoader ? {minWidth: '150px'} : null}
                    >
                        {telephoneNumberLoader ? (
                            <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                                <span className="indeterminate overviewkopbggroen"/>
                            </span>) : ('Nog een telefoonnummer toevoegen')
                        }
                        <Icon right>
                            add
                        </Icon>
                    </button>
                </div>
            </div>
        );
    }

    renderTelephoneNumber = (telephoneNumber, telephoneNumberOptions) => {
        const shouldHaveAmount = telephoneNumber.id;
        const telephoneNumberClass = shouldHaveAmount ? "col s11" : "col s12";

        const {telephoneNumberLoader} = this.state;
        const {inputsDisabled} = this.props.quotation;

        return (
            <div className="col s12">
                {
                    shouldHaveAmount && (
                        <Input
                            s={1}
                            name='amount'
                            type='number'
                            label='Aantal'
                            disabled={inputsDisabled || telephoneNumberLoader}
                            value={telephoneNumber.amount}
                            onChange={this.onTelephoneNumberQuantityChangeCallback(telephoneNumber)}
                            onBlur={() => this.onTelephoneNumberQuantitySave(telephoneNumber.id)}
                            min={1}
                            max={999}
                        />
                    )
                }
                <Input
                    s={telephoneNumberClass}
                    type="select"
                    name="telephoneNumber"
                    label="Telefoonnummer"
                    disabled={inputsDisabled || telephoneNumberLoader}
                    onChange={this.onChangeTelephoneNumber(telephoneNumber.id)}
                    value={telephoneNumber.id}
                    multiple={false}
                >
                    <option value={null}>Niets geselecteerd</option>
                    {
                        telephoneNumberOptions.map((number) => {
                            return <option key={number.name} value={number.id}>{number.name}</option>
                        })
                    }
                </Input>
            </div>
        );
    }

    addNewTelephoneNumber = () => {
        const {telephoneNumberSelections} = this.state;
        let updatedTelephoneNumbers = [...telephoneNumberSelections, {id: null, amount: 1}];

        this.setState({telephoneNumberSelections: updatedTelephoneNumbers});
    }

    onTelephoneNumberQuantityChangeCallback = (telephoneNumber) => (event, value) => {
        const {target} = event;
        const {min, max} = target;

        if (min > value || max < value) {
            return;
        }

        const {telephoneNumberSelections} = this.state;
        let updatedTelephoneNumbers = [...telephoneNumberSelections];
        let indexTelephoneNumber = updatedTelephoneNumbers.findIndex((number) => number.id === telephoneNumber.id);
        if (indexTelephoneNumber < 0) {
            return;
        }

        let updatedTelephoneNumber = telephoneNumber;
        updatedTelephoneNumber.amount = value;

        updatedTelephoneNumbers[indexTelephoneNumber] = updatedTelephoneNumber;

        this.setState({telephoneNumberSelections: updatedTelephoneNumbers});
    }

    onTelephoneNumberQuantitySave = (telephoneNumberId) => {
        const {telephoneNumberSelections} = this.state;
        const quotationId = this.props.quotation.id;

        const foundTelephoneNumber = telephoneNumberSelections.find((telephoneNumber) => telephoneNumber.id === telephoneNumberId);
        if (! foundTelephoneNumber) {
            return;
        }

        const data = {
            operation: 'updateAmount',
            telephoneNumber: foundTelephoneNumber
        };

        this.setState({profilesLoader: true});
        this.props.hostedVoiceActions.patchUccTelephoneNumbers(quotationId, data).then(() => {
            this.updateSelections();
            this.setState({profilesLoader: false});
        });
    }

    onChangeTelephoneNumber = (telephoneNumberId) => (event, value) => {
        value = value ? parseInt(value) : null;

        if (!telephoneNumberId && !value) {
            return;
        }
        if (telephoneNumberId === value) {
            return;
        }

        let data;
        const {telephoneNumberSelections} = this.state;
        const quotationId = this.props.quotation.id;

        if (!telephoneNumberId && value) {
            data = {
                operation: 'add',
                telephoneNumber: {
                    id: value,
                    amount: 1
                }
            };

            this.setState({telephoneNumberLoader: true});
            this.props.hostedVoiceActions.patchUccTelephoneNumbers(quotationId, data).then(() => {
                this.updateSelections();
                this.setState({telephoneNumberLoader: false});
            });

            return;
        }

        const foundTelephoneNumber = telephoneNumberSelections.find((telephoneNumber) => telephoneNumber.id === telephoneNumberId);
        if (! foundTelephoneNumber) {
            return;
        }

        if (telephoneNumberId && !value) {
            data = {
                operation: 'remove',
                telephoneNumber: {
                    id: telephoneNumberId
                }
            };

            this.setState({telephoneNumberLoader: true});
            this.props.hostedVoiceActions.patchUccTelephoneNumbers(quotationId, data).then(() => {
                this.updateSelections();
                this.setState({telephoneNumberLoader: false});
            });

            return;
        }

        const alreadySelectedTelephoneNumber = telephoneNumberSelections.find((telephoneNumber) => telephoneNumber.id === value);
        if (alreadySelectedTelephoneNumber) {
            return;
        }

        data = {
            operation: 'change',
            telephoneNumber: {
                newId: value,
                oldId: telephoneNumberId
            }
        };

        this.setState({telephoneNumberLoader: true});
        this.props.hostedVoiceActions.patchUccTelephoneNumbers(quotationId, data).then(() => {
            this.updateSelections();
            this.setState({telephoneNumberLoader: false});
        });
    }

    render() {
        const {profileCategories, profileSelectedCategories} = this.state;

        return (
            <div className="row">
                <div id="Forms" className="col s12">
                    <div className="row">
                        <div className="col s12">
                            <h1 className={'ratio-section-title'}>
                <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">settings_phone</i>UCC Plus Profielen:
                </span> gewenste opties
                            </h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12">
                            <h1 className={'ratio-section-title'}>
                                Gebruik deze UCC-profielcategorie
                            </h1>
                        </div>

                        <div className="col s12">
                            {
                                profileCategories.map((uccProfileCategory) => {
                                    return (
                                        <Input
                                            key={uccProfileCategory.value}
                                            name='providers'
                                            type='checkbox'
                                            checked={profileSelectedCategories.includes(uccProfileCategory.value)}
                                            value={uccProfileCategory.value}
                                            label={uccProfileCategory.name}
                                            onChange={this.onSelectUccProfileCategory}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>

                    {
                        profileSelectedCategories.map((uccProfileSelectedCategory) => {
                            return this.renderCategorySelections(uccProfileSelectedCategory);
                        })
                    }
                </div>

                <div id="Forms" className="col s12">
                    <div className="row">
                        <div className="col s12">
                            <h1 className={'ratio-section-title'}>
                <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">settings_phone</i>UCC Plus functionaliteiten:
                </span> gewenste opties
                            </h1>
                        </div>
                    </div>

                    {this.renderFunctionalities()}
                </div>

                <div id="Forms" className="col s12">
                    <div className="row">
                        <div className="col s12">
                            <h1 className={'ratio-section-title'}>
                <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">settings_phone</i>UCC Plus telefoonnummers:
                </span> gewenste opties
                            </h1>
                        </div>
                    </div>

                    {this.renderTelephoneNumbers()}
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({quotation, uccOptions}) => {
    return {
        quotation: quotation.currentQuotation,
        uccOptions: uccOptions,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        hostedVoiceActions: bindActionCreators(hostedVoiceActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HostedVoiceOptionsYielder);