/* global _ */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input, Row} from "react-materialize";
import {bindActionCreators} from 'redux';
import ReactDOM from "react-dom";
import axios from 'axios';
import {isElInput, sleep, poll} from "../../utils/utils";
import {apiRoutes} from "../../constants/apiRoutes";
import {toastr} from "react-redux-toastr";
import moment from "moment";

import infoActions from '../../actions/workflow/infoStepActions';
import locationActions from '../../actions/locationActions';
import quotationActions from '../../actions/quotationActions';
import leadActions from '../../actions/leadActions';
import stepsActions from '../../actions/stepsActions';
import accessActions from "../../actions/workflow/accessActions";
import organizationActions from "../../actions/organizationActions";
import digitalSigningGroupsActions from "../../actions/digitalSigningGroupsActions";
import digitalSigningFieldActions from "../../actions/digitalSigningFieldActions";
import DigitalSigningCustomGroups from "../../containers/DigitalSigning/digitalSigningCustomGroups";

import {hasRequiredRole} from "../../utils/authorization";
import Autosuggest from 'react-autosuggest';
import ContactInfo from './contactInfo';

import quotationType, {STANDARD_QUOTATION_TYPES} from '../../constants/quotationType';
import providerType, {odidoProviders, ownBrandProviders} from "../../constants/providerType";
import ProviderHelper from "../../helper/providerHelper";

import DefaultDialogBody from '../../containers/common/KpnDialog/DefaultDialogBody'
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import LoaderComponent from '../../containers/common/LoaderComponent';
import Organization from "../../constants/organization";
import crmConnections from "../../constants/crmConnections";
import VerlengenUsageDialog from "../../containers/common/VerlengenUsageDialog";
import GeneralDialog from "../../containers/common/GeneralDialog";
import ChangeAddressDialog from "../common/ChangeAddressDialog";
import './styles/index.css';
import statuses from "../../constants/quotationStatus";
import OdidoVerlengenUsageDialog from "../../containers/common/OdidoVerlengenUsageDialog";
import DatePicker from "../../components/datePicker";
import OwnBrandVerlengenUsageDialog from "../../containers/common/OwnBrandVerlengenUsageDialog";
import provider from "react-redux/lib/components/Provider";

class InfoStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      changeAddressDialog: false,
      fieldNotFilled: false,
      changeKvkDialog: false,
      oldQuotationDialog: false,
      importDialogOpened: false,
      file: null,
      manually: props.customer.selectedCustomer.manually,
      contactDataCopy: false,
      contactDataCopyTechnical: false,
      showPriceChanged: false,
      showChanges: false,
      validity: 30,
      cancelToken: null,
      verlengenUsageDialogOpen: false,
      odidoVerlengenUsageDialogOpen: false,
      yipVerlengenUsageDialogOpen: false,
      dataRetrievalDialog: false,
      presetDiscountChangeDialog: false,
      technicalContactDialog: false,
      technicalContactVerified: true,
      presetDiscountsInvalid: this.props.warnings.presetDiscountsInvalid || false,
      enableSalesValueModal: {
        enabled: false,
        bodyMessage: ''
      },
      existingUserQuotations: [],
      existingQuotationsDialog: false,
      isInactiveCustomProductsDialog: false,
      inactiveCustomProducts: [],
      bendVerkoopkansId: '',
      odidoBillingAccountCode: '',
      yipCustomerNumber: props.customer.selectedCustomer.yipCustomerNumber,
      existingOpportunities: [],
      selectedCwOpportunity: null,
      existingOpportunitiesDialog: false,
      cwSync: false,
      hasSpecificValidityDate: false,
      connectWiseBlockingDialog: false,
      secondaryIrmaResults: [],
      showSecondaryIRMASearch: false,
      selectedIrmaCustomer: null
    };

    this.searchKvK = _.throttle(this.searchKvK.bind(this), 1000);
    this.onSameData = this.onSameData.bind(this);
  }

  isSalesValueUser = () => {
    return this.props.authentication.isSalesValue;
  };

  displayMissingCustomerDataAlert = () => {
    const selectedCustomer = this.props.customer.selectedCustomer;

    if (!selectedCustomer.id || !this.checkPageValidity()) {
      this.props.quotationActions.addSoftAlert('LET OP: Klant info mist!');

      return
    }

    if (selectedCustomer.id && this.checkPageValidity()) {
      this.props.quotationActions.removeSpecificSoftAlert('LET OP: Klant info mist!');
    }
  }

  componentDidMount() {
    let quotationId = this.props.quotation.currentQuotation.id;
    this.props.quotationActions.resetSoftAlerts();

    if (this.props.quotation.isLead) {
      this.props.leadActions.getLead(quotationId)
        .then(() => this.initializeQuotation(quotationId))
        .then(() => this.checkInputsForValidity(this.globalForm))
        .then(() => this.checkSyncStatus())
        .then(() => this.continuePoll());
    } else {
      this.props.quotationActions.getQuotation(quotationId)
        .then(() => this.initializeQuotation(quotationId))
        .then(() => this.checkInputsForValidity(this.globalForm))
        .then(() => this.checkDataRetrievalStatus(false, true))
        .then(() => this.checkSyncStatus())
        .then(() => this.checkQuotationStatus(true))
        .then(() => this.checkCWOpportunitySelection())
        // .then(() => this.checkSalesValueOpportunityEmailSync(quotationId))
        .then(() => this.continuePoll());
    }
  }

  componentWillMount() {
    this.props.organizationActions.getOrganizationDetails();
    this.props.digitalSigningGroupsActions.getAllDigitalSigningGroups();
    this.props.digitalSigningFieldActions.getAllDigitalSigningFields(this.props.quotation.currentQuotation.id);
    this.props.quotationActions.resetPresetDiscountsWarnings();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkPageValidity();

    let prevQuotation = prevProps.quotation.currentQuotation;
    let prevSelectedCustomer = prevProps.customer.selectedCustomer;
    let currentQuotation = this.props.quotation.currentQuotation;
    let currentSelectedCustomer = this.props.customer.selectedCustomer;

    if (prevQuotation.pendingDataRetrieval !== currentQuotation.pendingDataRetrieval) {
      this.checkDataRetrievalStatus(!currentQuotation.pendingDataRetrieval, false);
    }

    if (prevQuotation.opportunityNumberCW !== currentQuotation.opportunityNumberCW) {
      this.checkConnectWiseInstalledBase();
    }

    if (prevQuotation.useTechnicalContactForOrder !== currentQuotation.useTechnicalContactForOrder) {
      if (currentQuotation.useTechnicalContactForOrder && !this.checkTechnicalContactConfig()) {
        this.setState({technicalContactDialog: true, technicalContactVerified: false});
      }

      if (!currentQuotation.useTechnicalContactForOrder) {
        this.props.quotationActions.removeSpecificSoftAlert('LET OP: Technisch contactpersoon is niet ingevuld!');
      }
    }

    if (prevSelectedCustomer !== currentSelectedCustomer) {
      if (prevSelectedCustomer.manually !== currentSelectedCustomer.manually) {
        this.setState({manually: this.props.customer.selectedCustomer.manually});
      }

      if (prevSelectedCustomer.yipCustomerNumber !== currentSelectedCustomer.yipCustomerNumber) {
        this.setState({ yipCustomerNumber: this.props.customer.selectedCustomer.yipCustomerNumber});
      }

      this.setState({contactDataCopy: this.checkCustomerProps()});
    }

    if (prevProps.warnings.presetDiscountsInvalid !== this.props.warnings.presetDiscountsInvalid) {
      this.setState({presetDiscountsInvalid: this.props.warnings.presetDiscountsInvalid});
    }

    this.displayMissingCustomerDataAlert();
  }

  checkSalesValueOpportunityEmailSync = (quotationId) => {
    const {isSalesValue} = this.props.authentication;

    if (isSalesValue) {
      this.props.quotationActions.syncOpportunityEmail(quotationId);
    }
  };

  checkCWOpportunitySelection = () => {
    const {quotation, customer, authentication} = this.props;
    const {hasConnectWiseSync} = authentication;

    if (!hasConnectWiseSync) {
      return;
    }

    const {selectedCustomer} = customer;
    const {currentQuotation} = quotation;

    if (!selectedCustomer) {
      return;
    }

    if (selectedCustomer && !selectedCustomer.connectWiseId) {
      if (selectedCustomer.name) {
        toastr.error('Kan klant niet synchroniseren.');
      }

      return;
    }

    if (currentQuotation && !currentQuotation.opportunityNumberCW) {
      this.props.infoActions.getOpportunitiesByCompanyRecID(selectedCustomer).then((response) => {
        if (! response) {
          response = [];
        }

        this.setState({existingOpportunities: response, existingOpportunitiesDialog: response.length > 0});

        if (!response.length) {
          this.setState({connectWiseBlockingDialog: true});
          this.props.stepsActions.setAvailableToGoForward(false);
        }
      })
    }
  };

  checkConnectWiseInstalledBase = () => {
    const {quotation, customer, authentication} = this.props;
    const {hasConnectWiseSync} = authentication;

    if (!hasConnectWiseSync) {
      return;
    }

    const {selectedCustomer} = customer;
    const {currentQuotation} = quotation;
    const {provider, type} = currentQuotation;

    const isVerlengenType = type === quotationType.TYPE_VERLENGEN;
    if (!isVerlengenType) {
      return;
    }

    if (selectedCustomer && !selectedCustomer.connectWiseId) {
      return;
    }

    if (currentQuotation && !currentQuotation.opportunityNumberCW) {
      return;
    }

    const isOdidoProvider = provider === providerType.ODIDO;
    const isKpnProvider = provider === providerType.KPN;
    const isOwnBrandProvider = ownBrandProviders.includes(provider);

    let irmaID = selectedCustomer.api_id || selectedCustomer.apiId;
    let needsIrmaIdDialog = !(irmaID && irmaID.length > 0);
    let billingAccountCode = selectedCustomer.odidoBillingAccountCode;
    let yipCustomerNumber = selectedCustomer.yipCustomerNumber;

    if (isKpnProvider && needsIrmaIdDialog) {
      this.props.infoActions.doSecondaryIrmaSearch(currentQuotation.id).then((response) => {
        if (response && response.results) {
          if (response.results.length > 0) {
            this.setState({showSecondaryIRMASearch: true, secondaryIrmaResults: response.results});
          } else {
            this.setState({verlengenUsageDialogOpen: true});
          }
        } else {
          this.setState({verlengenUsageDialogOpen: true});
        }
      })
    }

    if (!billingAccountCode && isOdidoProvider) {
      this.setState({odidoVerlengenUsageDialogOpen: true});
    }

    if (!yipCustomerNumber && isOwnBrandProvider) {
      this.setState({yipVerlengenUsageDialogOpen: true});
    }
  };

  initializeQuotation = (quotationId) => {
    const {quotation, organizations} = this.props;
    const {isLead, currentQuotation} = quotation;
    const {organization} = organizations;

    this.props.locationActions.setQuotationLocations(quotationId, isLead);

    this.setState({contactDataCopy: this.checkCustomerProps()});
    this.setState({contactDataCopyTechnical: this.checkTechnicalCustomerProps()});

    const {createdAt, quotationValidity, changes, companySource} = currentQuotation;

    const quotationDate = moment(createdAt);
    const now = moment(new Date());

    let validity = quotationValidity || organization.quotationValidity || 30;
    let oldQuotation = false;
    if (['end_of_month', 'end_of_quarter'].includes(validity)) {
      let endOf = quotationDate.endOf('month');
      if (validity === 'end_of_quarter') {
        endOf = quotationDate.endOf('quarter');
      }
      if (now > endOf) {
        oldQuotation = true;
        validity = endOf.format('DD-MM-YYYY');
      }
    } else {
      if (quotationValidity === 'specific_validity_date') {
        this.setState({hasSpecificValidityDate: true});
      }

      const diffDays = moment.duration(now.diff(quotationDate)).asDays();

      if (diffDays >= validity) {
        oldQuotation = true;
        if (parseInt(validity) === 1) {
          validity += ' dag';
        } else {
          validity += ' dagen';
        }

      }
    }
    this.setState({
      oldQuotationDialog: oldQuotation,
      validity: validity
    });

    if (!isLead) {
      this.props.quotationActions.getQuotationChanges(quotationId).then(() => {
        if (changes?.length) {
          this.setState({showPriceChanged: true});
        }
      });
      if (odidoProviders.includes(currentQuotation.provider)) {
        this.props.quotationActions.getPresetDiscountChanges(quotationId).then((response) => {
          if (response && response.success) {
            this.setState({presetDiscountChangeDialog: true})
          }
        });

        this.props.quotationActions.getPresetDiscountCheck(quotationId);
      }

      this.props.infoActions.getInactiveCustomProducts(quotationId).then((response) => {
        let state = {
          inactiveCustomProducts: response.inactiveCustomProducts,
          isInactiveCustomProductsDialog: Object.keys(response.inactiveCustomProducts).length > 0
        };

        this.setState(state);
      })
    }

    const isPipedrive = companySource === 'pipedrive';
    const isConnectWise = companySource === 'connectWise';

    const customer = this.props.customer.selectedCustomer;
    const {organization_credentials, name} = organization;
    const isKpnProvider = currentQuotation.provider === providerType.KPN;
    const isOdidoProvider = currentQuotation.provider === providerType.ODIDO;
    const isVodafoneProvider = currentQuotation.provider === providerType.VODAFONE;
    const isOwnBrandProvider = ownBrandProviders.includes(currentQuotation.provider);
    const isVerlengenQuotation = currentQuotation.type === quotationType.TYPE_VERLENGEN;

    if (currentQuotation.useTechnicalContactForOrder && !this.checkTechnicalContactConfig()) {
      this.setState({technicalContactDialog: true, technicalContactVerified: false});
    }

    if (!currentQuotation.companySource) {
      let IRMACredentials = [];
      if (this.canIRMA(currentQuotation)) {
        IRMACredentials = currentQuotation.IRMACredentials;
      }

      let companySource = 'kvk';
      if (IRMACredentials.length && !isVodafoneProvider) {
        companySource = IRMACredentials[0].id;
      }

      if ([Organization.WR, Organization.WR_TELDACOM].includes(name) && organization_credentials && organization_credentials.find((el) => el.type === 'dynamics')) {
        companySource = 'dynamics'
      }

      this.updateQuotationProperty({
        target: {
          name: 'companySource',
          value: companySource,
          oldQuotation: true
        }
      });
    }

    if (!customer.kvk && isPipedrive) {
      this.setState({changeKvkDialog: true});
    }

    if (customer && isConnectWise && customer.connectWiseId && currentQuotation.opportunityNumberCW) {
      let irmaID = customer.api_id || customer.apiId;
      let needsIrmaIdDialog = !(irmaID && irmaID.length > 0) && isKpnProvider && isVerlengenQuotation;

      if (needsIrmaIdDialog) {
        this.props.infoActions.doSecondaryIrmaSearch(quotationId).then((response) => {
          if (response && response.results) {
            if (response.results.length > 0) {
              this.setState({showSecondaryIRMASearch: true, secondaryIrmaResults: response.results});
            } else {
              this.setState({verlengenUsageDialogOpen: true});
            }
          } else {
            this.setState({verlengenUsageDialogOpen: true});
          }
        });
      }

      let yipCustomerNumber = customer.yipCustomerNumber;
      let billingAccountCode = customer.odidoBillingAccountCode;
      let needsYipCustomerNumber = isOwnBrandProvider && isVerlengenQuotation;
      let needsOdidoBillingAccountCode = isOdidoProvider && isVerlengenQuotation;

      if (!billingAccountCode && needsOdidoBillingAccountCode) {
        this.setState({odidoVerlengenUsageDialogOpen: true});
      }

      if (!yipCustomerNumber && needsYipCustomerNumber) {
        this.setState({yipVerlengenUsageDialogOpen: true});
      }
    }

    if (customer && isPipedrive) {
      let irmaID = customer.api_id || customer.apiId;
      let needsIrmaIdDialog = !(irmaID && irmaID.length > 0) && isKpnProvider;

      if (needsIrmaIdDialog && isPipedrive) {
        this.setState({verlengenUsageDialogOpen: true})
      }
    }

    let odidoBillingCondition = !customer.odidoBillingAccountCode && customer.kvk?.length > 1;
    if (odidoBillingCondition && isVerlengenQuotation && isOdidoProvider && !isConnectWise) {
      this.setState({odidoVerlengenUsageDialogOpen: true});
    }

    let yipCustomerNumberCondition = !customer.yipCustomerNumber && customer.kvk?.length > 1;
    if (yipCustomerNumberCondition && isVerlengenQuotation && isOwnBrandProvider && !isConnectWise) {
      this.setState({yipVerlengenUsageDialogOpen: true});
    }

    this.checkQuotationStatus();

    this.setState({loading: false});
  };

  continuePoll = () => {
    if (!this.props.quotation.currentQuotation.pendingDataRetrieval) {
      return;
    }

    let quotationId = this.props.quotation.currentQuotation.id;
    let isLead = this.props.quotation.isLead;
    const url = apiRoutes.customersRelated.retrieveExistingData.replace('{quotationId}', quotationId);

    poll(url)
      .then(() => {
        this.props.quotationActions.updateDataRetrievalLoader('mobile', false);
        this.props.quotationActions.updateDataRetrievalLoader('voice', false);
        this.props.locationActions.setQuotationLocations(quotationId, isLead);
        this.checkDataRetrievalStatus(true, false);
      })
      .catch((e) => toastr.error('Kan bestaande gegevens niet ophalen', e.message));
  };

  checkDataRetrievalStatus = (knownStatus, initialTrigger) => {
    let {currentQuotation} = this.props.quotation;

    if (!currentQuotation.pendingDataRetrieval || knownStatus) {
      this.props.quotationActions.resetSoftAlerts();
      return;
    }

    this.setDataRetrievalAlert(initialTrigger);
  };

  setDataRetrievalAlert = (initialTrigger) => {
    let alertMessage = 'We zijn nog druk bezig alle bestaande orders op te halen. Je kunt gewoon door met je offerte, maar alle bestaande producten zijn misschien nog niet geladen';
    if (initialTrigger) {
      this.setState({dataRetrievalDialog: true});
    }

    this.props.quotationActions.addSoftAlert(alertMessage);
  };

  checkSyncStatus = () => {
    let {currentQuotation} = this.props.quotation;
    if (currentQuotation.syncStatus !== false) {
      return;
    }

    this.props.quotationActions.addHardAlert('LET OP: Dit is niet de meest recente versie van deze offerte.');
  };

  checkQuotationStatus = (isQuotationLocked = false) => {
    let {currentQuotation} = this.props.quotation;
    const STATUS_SEND_VALUE = 6;
    const isSentDigitalSigningOfferte = !!(currentQuotation?.status == STATUS_SEND_VALUE && currentQuotation?.sentAt);

    if (isSentDigitalSigningOfferte && !isQuotationLocked) {
      let alertMessage = 'Deze offerte is verzonden naar de klant en kan niet meer worden gewijzigd. Voor een wijziging, maak aub een nieuwe versie aan.';

      this.props.quotationActions.addSoftAlert(alertMessage);
    }

    if (currentQuotation.status !== statuses.STATUS_OPEN.value) {
      const alertMessage = 'Let op: Door de gekozen status van deze offerte, is deze vergrendeld!';

      this.props.quotationActions.addSoftAlert(alertMessage);
    }
  };

  checkCustomerProps() {
    const {commercialContact, technicalContact} = this.props.customer.selectedCustomer;

    const technicalContactFilled = technicalContact.email && technicalContact.phoneNumber && technicalContact.title &&
      technicalContact.fullName;

    return technicalContactFilled && commercialContact.email === technicalContact.email && commercialContact.fullName === technicalContact.fullName &&
      commercialContact.phoneNumber === technicalContact.phoneNumber && commercialContact.title === technicalContact.title;
  }

  checkTechnicalCustomerProps() {
    const {projectManager, technicalContact} = this.props.customer.selectedCustomer;

    const technicalContactFilled = technicalContact.email && technicalContact.phoneNumber && technicalContact.title &&
      technicalContact.fullName;

    const projectManagerFilled = projectManager.email && projectManager.phoneNumber && projectManager.title &&
      projectManager.fullName;

    return projectManagerFilled && technicalContactFilled && projectManager.email === technicalContact.email && projectManager.fullName === technicalContact.fullName &&
      projectManager.phoneNumber === technicalContact.phoneNumber && projectManager.title === technicalContact.title;
  }

  isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  isValidPhoneNumber = (phoneNumber) => {
    let {useTechnicalContactForOrder} = this.props.quotation.currentQuotation;
    if (useTechnicalContactForOrder) {
      return /^[0-9]{10}$/.test(phoneNumber) && phoneNumber.length === 10;
    }

    return /^[0-9]{10,}$/.test(phoneNumber) && phoneNumber.length >= 10;
  };

  checkTechnicalContactConfig = () => {
    let {useTechnicalContactForOrder} = this.props.quotation.currentQuotation;
    if (!useTechnicalContactForOrder) {
      return true;
    }

    const {technicalContact} = this.props.customer.selectedCustomer;
    let {fullName, title, email, phoneNumber} = technicalContact;

    let isValid = !!(fullName && title && this.isValidEmail(email) && this.isValidPhoneNumber(phoneNumber));

    if (!isValid) {
      this.props.quotationActions.addSoftAlert('LET OP: Technisch contactpersoon is niet ingevuld!');
    } else {
      this.props.quotationActions.removeSpecificSoftAlert('LET OP: Technisch contactpersoon is niet ingevuld!');
    }

    return isValid;
  };

  checkCompanyInputsForValidity = () => {
    let inputs = ReactDOM.findDOMNode(this.globalForm).getElementsByClassName('validate company');
    let allValid = true;

    Array.from(inputs).forEach((el) => {
      if (el.checkValidity() === false) {
        allValid = false;
      }
    });

    return allValid;
  };

  checkInputsForValidity = (el) => {
    if (el && el.children) {
      Array.from(el.children).forEach((child) => {
        this.checkInputsForValidity(child);
      });
    }

    if (this.props.quotation.currentQuotation.inputsDisabled) {
      el.classList.remove('valid');
      el.classList.remove('invalid');
      return;
    }

    if (isElInput(el) === false) {
      return;
    }

    const parentElement = el.parentElement;

    if (el.checkValidity() === false) {
      this.addInvalidClass(el, parentElement);
    }

    if (el.getAttribute('required') !== null && el.checkValidity() === true) {
      el.classList.remove('invalid');
      el.classList.add('valid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  addInvalidClass = (el, parentElement) => {
    el.classList.remove('valid');
    el.classList.add('invalid');

    if (parentElement.classList.contains('select-wrapper')) {
      parentElement.classList.remove('valid');
      parentElement.classList.add('invalid');
    }
  }

  hasDanger = () => {
    return !(this.props.quotation.currentQuotation.contactEmail ||
      this.props.quotation.currentQuotation.contactPhone ||
      this.props.quotation.currentQuotation.contactSms ||
      this.props.quotation.currentQuotation.contactNone);
  };

  hasInvalidTransferReason = () => {
    const {currentQuotation} = this.props.quotation;

    if (currentQuotation.transferService === 1) {
      return true;
    }

    return currentQuotation.transferService === 0 && !currentQuotation.transferServiceReason;
  };

  handleCustomerNameChange = (customerName) => {
    this.props.infoActions.setSelectedCustomerName(customerName);

    this.searchKvK(customerName);
  };

  searchKvK = (customerName) => {
    if (this.state.cancelToken) {
      this.state.cancelToken.cancel();
      this.setState({cancelToken: null});
    }

    const cancelTokenSource = axios.CancelToken.source();
    this.setState({cancelToken: cancelTokenSource});

    const quotation = this.props.quotation.currentQuotation;
    this.props.infoActions.getCustomersForAutocomplete(quotation.id, customerName, this.props.quotation.isLead, cancelTokenSource.token);
  };

  callSelectCustomer = (selectedCustomer) => {
    if (this.updateCustomerTimeout) {
      clearTimeout(this.updateCustomerTimeout);
    }

    this.updateCustomerTimeout = setTimeout(() => {
      const quotation = this.props.quotation.currentQuotation;
      const isLead = this.props.quotation.isLead;
      const quotationId = quotation.id;
      this.props.infoActions
        .setSelectedCustomer(selectedCustomer, quotationId, isLead)
        .then(() =>
          this.props.locationActions.setQuotationLocations(quotationId, isLead),
        )
    }, 1000);
  };

  initiateExistingDataRetrieval = (quotationId) => {
    this.props.quotationActions.removeSpecificSoftAlert('LET OP: Klant info mist!');
    this.setDataRetrievalAlert(true);
    this.props.quotationActions.updateDataRetrievalLoader('mobile', true);
    this.props.quotationActions.updateDataRetrievalLoader('voice', true);

    const url = apiRoutes.customersRelated.retrieveExistingData.replace('{quotationId}', quotationId);
    this.props.infoActions.retrieveExistingData(quotationId)
      .then(() => poll(url))
      .then(() => {
        this.props.quotationActions.updateDataRetrievalLoader('mobile', false);
        this.props.quotationActions.updateDataRetrievalLoader('voice', false);
        this.checkDataRetrievalStatus(true, false);
        this.props.locationActions.setQuotationLocations(quotationId, this.props.quotation.isLead);
      })
      .catch((e) => toastr.error('Kan bestaande gegevens niet ophalen', e.message));
  };

  selectCustomer = (customerName, customer) => {
    let selectedCustomer;
    const isAutotask = this.isAutotaskEnabledForAxoftUser();
    const {currentQuotation, isLead} = this.props.quotation;
    const {hasConnectWiseSync, company, isVenecoOrganization} = this.props.authentication;
    const quotation = currentQuotation;
    const {provider, type, companySource} = quotation;

    const isFlashProvider = provider === providerType.FLASH;
    const isOdidoProvider = provider === providerType.ODIDO;
    const isOwnBrandProvider = ownBrandProviders.includes(provider);
    const isOdidoPortfolioExistingQuotation = odidoProviders.includes(provider) && type === quotationType.TYPE_VERLENGEN;

    if (this.isSalesValueUser() || isAutotask || isOdidoPortfolioExistingQuotation) {
      selectedCustomer = customer;
    } else {
      selectedCustomer = this.props.customer.matchingCustomers.find((x) => x.name === customer.name);
    }

    const quotationId = quotation.id;
    const irmaID = selectedCustomer.api_id || selectedCustomer.apiId;
    const needsIrmaIdDialog = !(irmaID && irmaID.length > 0);
    const isVerlengen = type === quotationType.TYPE_VERLENGEN;
    const needsBillingAccountCodeDialog = selectedCustomer && selectedCustomer.kvk;
    const needsYipCustomerNumberDialog = selectedCustomer && selectedCustomer.kvk;
    const isKvkSource = companySource === 'kvk';
    const isConnectWiseSource = companySource === 'connectWise';

    let cwsync = false;

    if (selectedCustomer.name) {
      this.props.infoActions.setSelectedCustomer(selectedCustomer, quotationId, isLead)
        .then(async (response) => {
          if (isKvkSource || isConnectWiseSource) {
            customer = response
          }

          if (hasConnectWiseSync) {
            if (isKvkSource) {
              await this.props.infoActions.syncCwCustomer(customer).then((response) => {
                if (response) {
                  cwsync = response.success;
                } else {
                  toastr.error('Kan klant niet synchroniseren.');
                }
              })
            }

            if (isConnectWiseSource) {
              cwsync = true;
            }
          }

          if ((isVerlengen && !needsIrmaIdDialog)) {
            this.initiateExistingDataRetrieval(quotationId);
          }

          if (isVerlengen && needsIrmaIdDialog && !isAutotask && !isOdidoPortfolioExistingQuotation && !isConnectWiseSource) {
            this.setState({verlengenUsageDialogOpen: true});
          }

          if (isOdidoPortfolioExistingQuotation && !isConnectWiseSource) {
            if (isOdidoProvider && needsBillingAccountCodeDialog) {
              this.setState({odidoVerlengenUsageDialogOpen: true});
            }
            if (isOwnBrandProvider && needsYipCustomerNumberDialog) {
              this.setState({yipVerlengenUsageDialogOpen: true})
            }
          }

          if (!quotation.initialized && !isVerlengen && !isFlashProvider) {
            this.props.infoActions.initialPrequal(quotationId);
          }

          if (isAutotask || this.isSalesValueUser()) {
            quotation.customer = response.id;
          }
        })
        .then(() => {
          if (this.isSalesValueUser() && customer.opportunityId) {
            quotation.opportunityNumber = customer.opportunityId;
          } else {
            quotation.ckrNumber = customer?.accountNumber;
          }

          if (!isAutotask) {
            if (selectedCustomer.id || selectedCustomer.kvk) {
              quotation.customer = selectedCustomer.id || selectedCustomer.kvk;
            } else {
              quotation.customer = customer.id;
            }
          } else {
            quotation.name = selectedCustomer.quotationTitle;
          }
        })
        .then(() => {
          this.props.quotationActions.updateQuotation(quotation, isLead)
            .then(() => isLead ? this.props.leadActions.getLead(quotationId) : this.props.quotationActions.getQuotation(quotationId))
            .then((customer) => {
              if (!this.isSalesValueUser()) {
                let street = this.props.customer.selectedCustomer.street;
                let houseNumber = this.props.customer.selectedCustomer.houseNumber;
                const isOwnBrandProvider = ownBrandProviders.includes(quotation.provider);

                if (isAutotask) {
                  street = customer.street;
                  houseNumber = customer.houseNumber;
                }

                if (!isVenecoOrganization || !isOwnBrandProvider) {
                  this.setState({
                    changeAddressDialog: !street || !houseNumber ? true : !houseNumber?.trim()
                  });
                }

                this.props.locationActions.setQuotationLocations(quotationId, isLead)
              }
            })
            .then(() => {
              this.props.locationActions.clearSelectedLocation();
              this.props.locationActions.fillGlobalLocationProperties(customer);

              sleep(1500).then(() => {
                this.checkInputsForValidity(this.globalForm);
              });
            });
        })
        .then(() => {
          if (![Organization.WR, Organization.SV].includes(company) && !isOdidoPortfolioExistingQuotation) {
            this.props.infoActions.getQuotationsByKvkIrmaIds(quotationId).then((response) => {
              this.setState({
                existingUserQuotations: response.result,
                existingQuotationsDialog: response.result.length > 0
              })
            })
          }
        })
        .then(async () => {
          if (hasConnectWiseSync && cwsync) {
            const {selectedCustomer} = this.props.customer;

            this.setState({cwSync: cwsync});
            await this.props.infoActions.getOpportunitiesByCompanyRecID(selectedCustomer).then((response) => {
              this.setState({existingOpportunities: response, existingOpportunitiesDialog: response.length > 0});

              if (!response.length) {
                this.setState({connectWiseBlockingDialog: true});
                this.props.stepsActions.setAvailableToGoForward(false);
              }
            })
          }
        })
    }
  };

  contactInfoChange = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.props.infoActions.updateContactInfo(target);
    this.checkPageValidity();
    let {selectedCustomer} = this.props.customer;
    if (selectedCustomer.id) {
      this.updateCustomer();
    }
  };

  checkPageValidity = () => {
    if (!this.checkTechnicalContactConfig()) {
      this.props.stepsActions.setAvailableToGoForward(false);
      return;
    }

    if (this.isPageValid() && this.checkBoxesAreValid() && this.checkConnectWiseConstraint()) {
      this.props.stepsActions.setAvailableToGoForward(true);

      return true;
    } else {
      this.props.stepsActions.setAvailableToGoForward(false);

      return false;
    }
  };

  checkConnectWiseConstraint = () => {
    const {quotation, customer, authentication} = this.props;
    const {hasConnectWiseSync} = authentication;

    if (!hasConnectWiseSync) {
      return true;
    }

    const {selectedCustomer} = customer;
    const {currentQuotation} = quotation;

    if (!selectedCustomer) {
      return false;
    }

    if (selectedCustomer && !selectedCustomer.connectWiseId) {
      return false;
    }

    return !(currentQuotation && !currentQuotation.opportunityNumberCW);
  };

  checkBoxesAreValid = () => {
    //is not Sales Value user => set as valid
    if (!this.isSalesValueUser()) {
      return true;
    }

    const quotation = this.props.quotation.currentQuotation;
    if (quotation.contactNone) {
      quotation.contactEmail = false;
      quotation.contactPhone = false;
      quotation.contactSms = false;
    }
    if (!quotation.firstAgreement || !quotation.secondAgreement || !quotation.thirdAgreement) {
      return false;
    }

    if (quotation.transferService === null) {
      return false;
    }

    if (quotation.transferService === 0 && !quotation.transferServiceReason) {
      return false;
    }

    let propsToCheck = [quotation.contactEmail, quotation.contactPhone, quotation.contactSms, quotation.contactNone];
    return propsToCheck.filter(x => x === true).length;
  };

  //done due to time pressure - should be reworked immediately after rush-phase
  isPageValid = () => {
    const contactInfoComponentsRefs = [
      this.commercialContactRef,
      this.technicalContactRef,
      this.projectmanagerContactRef,
      this.financialContactRef,
      this,
    ];

    for (let component of contactInfoComponentsRefs) {
      if (component) {
        let inputsInComponent = Object.keys(component.refs);
        if (!inputsInComponent.length && component.wrappedInstance) {
          component = component.wrappedInstance;
          inputsInComponent = Object.keys(component.refs)
        }

        for (let inputKey of inputsInComponent) {
          let input = component.refs[inputKey];

          if (input.refs) {
            if (input.isSelect && input.isSelect()) {
              if (input.selectInput.validity.valueMissing) {
                return false;
              }
            } else if (input.props.name.includes('birthdate')) {
              if (!input.props.selected) {
                return false;
              }
            } else {
              input = input.refs['input'];

              if (input.value === '') {
                return false;
              }
            }
          } else {
            if (!input.checkValidity()) {
              return false;
            }
          }
        }
      }
    }

    let kvk = this.props.customer.selectedCustomer.kvk;
    if (this.props.quotation.currentQuotation.companySource === 'manually' && kvk !== undefined) {
      return kvk !== '' && kvk !== null && kvk.match(/[0-9]{8}/);
    }

    return true;
  };

  updateContactInfoAdditionalProperties = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    this.props.infoActions.updateContactInfoAdditionalProperties(target);
    this.checkPageValidity();
    let {selectedCustomer} = this.props.customer;
    if (selectedCustomer.id) {
      this.updateCustomer();
    } else {
      sleep(1500).then(() => {
        if (this.checkCompanyInputsForValidity()) {
          this.callSelectCustomer(this.props.customer.selectedCustomer);
        }
      });
    }

    if (target.checkValidity()) {
      target.classList.remove('invalid');
      target.classList.add('valid');

      const parentElement = target.parentElement;
      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  clearMainLocation = (callback) => {
    const mainLocation = this.props.locations.allLocations.filter(location => (
      location.id > 0 && location.main === true
    ));

    if (mainLocation.length) {
      this.props.locationActions.deleteLocation(mainLocation[0].id)
        .then(() => {
          callback();
        });
    } else {
      callback();
    }
  };

  saveLocationDetails = () => {
    const {selectedCustomer} = this.props.customer;
    const {isLead, currentQuotation} = this.props.quotation;

    if (!selectedCustomer.houseNumber) {
      this.setState({fieldNotFilled: true});
      return;
    }

    let mainLocation = this.props.locations.allLocations.filter(location => (
      location.id > 0 && location.main === true
    ));

    if (mainLocation.length) {
      mainLocation = mainLocation[0];
      mainLocation = {
        ...mainLocation,
        house_number: selectedCustomer.houseNumber,
        house_number_extension: selectedCustomer.houseNumberExtension,
        name: `${selectedCustomer.street} ${selectedCustomer.houseNumber} ${selectedCustomer.houseNumberExtension || ''} ${mainLocation.city}`,
        street: selectedCustomer.street
      };

      this.props.locationActions.setSelectedLocation(mainLocation);
      this.props.locationActions.updateSelectedLocation(mainLocation.id, mainLocation.quotation, isLead);
    }

    this.setState({
      changeAddressDialog: false,
      fieldNotFilled: false,
    });

    if (this.isAutotaskEnabledForAxoftUser() && !selectedCustomer.kvk) {
      this.setState({
        changeKvkDialog: true
      });
    }

    if (selectedCustomer) {
      let irmaID = selectedCustomer.api_id || selectedCustomer.apiId;
      let needsIrmaIdDialog = !(irmaID && irmaID.length > 0);

      const isPipedrive = currentQuotation.companySource === 'pipedrive';

      if (needsIrmaIdDialog && isPipedrive) {
        this.setState({verlengenUsageDialogOpen: true});
      }
    }
  };

  updateCompanyInfo = ({target}) => {
    let {name} = target;
    if (this.props.quotation.currentQuotation.companySource !== 'manually' &&
      !this.state.changeAddressDialog &&
      !this.state.changeKvkDialog && name !== 'legalForm'
    ) {
      return;
    }

    this.updateContactInfoAdditionalProperties({target: target});
    this.checkPageValidity();
  };

  updateQuotationProperty = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    if (target.name === 'opportunityNumber' && this.isAutotaskEnabledForAxoftUser()) {
      this.getQuotationAutotaskData(target.value);
    }

    if (target.name === 'opportunityNumber' && this.isSalesValueUser()) {
      if (target.value.length > 3 && target.value.slice(0, 3).toString() !== '006') {
        this.setState({
          enableSalesValueModal: {
            enabled: true,
            bodyMessage: 'Opportunity nummer dient te starten met 006 en dient 18 karakters te hebben.'
          }
        });

        return;
      }

      this.fillCustomerData(target);
    }


    this.props.quotationActions.updateQuotationProperty(target);

    if (target.name === 'quotationValidity' && target.value === 'specific_validity_date') {
      this.setState({hasSpecificValidityDate: true});
    }

    if (target.name === 'quotationValidity' && target.value !== 'specific_validity_date' && this.state.hasSpecificValidityDate) {
      this.setState({hasSpecificValidityDate: false});
    }

    if (target.name === 'companySource' && !target.oldQuotation) {
      let resetCustomerCallback = (() => {
        // get customer id
        let customerId = this.props.customer.selectedCustomer.id;
        // reset customer data
        this.props.infoActions.resetCustomerDetails();

        let quotationFields = ['opportunityNumber'];
        // reset quotation fields
        quotationFields.map((item) => {
          this.props.quotationActions.updateQuotationProperty({
            name: item,
            value: ''
          });
        });

        // now bring back the id in order to update the current customer
        this.props.infoActions.updateContactInfoAdditionalProperties({
          name: 'id',
          value: customerId
        });
        // and then mark as checked the manual data button
        this.props.infoActions.updateContactInfoAdditionalProperties({
          name: target.name,
          value: target.value === 'manually'
        });
      });

      if (target.value === 'manually') {
        this.clearMainLocation(resetCustomerCallback);
      } else {
        resetCustomerCallback();
      }

      sleep(1500).then(() => {
        this.checkInputsForValidity(this.globalForm);
      });
    }

    this.checkPageValidity();
    this.updateQuotation();
  };

  onChangeSpecificValidityDate = (date) => {
    let event = {
      target: {
        name: 'specificValidityDate',
        value: date,
      }
    }

    this.updateQuotationProperty(event);
  }

  getQuotationAutotaskData(opportunityId) {
    if (this.getQuotationAutotaskDataTimeout) {
      clearTimeout(this.getQuotationAutotaskDataTimeout);
    }

    this.getQuotationAutotaskDataTimeout = setTimeout(() => {
      if (opportunityId.length < 1) {
        return;
      }

      this.props.quotationActions.getQuotationAutotaskData(this.props.quotation.currentQuotation.id, opportunityId)
        .then((response) => {
          if (response) {
            this.selectCustomer(response.name, response);
          }
        })
    }, 1500);
  }

  fillCustomerData(target) {
    if (this.fillCustomerDataTimeout) {
      clearTimeout(this.fillCustomerDataTimeout);
    }

    this.fillCustomerDataTimeout = setTimeout(() => {
      if (target.value.length <= 3) {
        return;
      }

      this.props.quotationActions.getCustomerData(this.props.quotation.currentQuotation.id, target.value)
        .then((response) => {
          if (response) {
            this.selectCustomer(response.name, response);
          }
        })
        .then(() => this.checkSalesValueMessage())
        .then(() => this.checkOpportunityPricebook());
    }, 1500);
  }

  checkSalesValueMessage() {
    const {currentQuotation} = this.props.quotation;

    if (currentQuotation.salesValueMessage) {
      this.setState({
        enableSalesValueModal: {
          enabled: true,
          bodyMessage: currentQuotation.salesValueMessage
        }
      });
    }
  }

  checkOpportunityPricebook() {
    const {currentQuotation} = this.props.quotation;

    if (!currentQuotation.syncStatus) {
      this.setState({
        enableSalesValueModal: {
          enabled: true,
          bodyMessage: 'De opgegeven opportunity bevat een verouderde prijslijst in SalesForce. Hierdoor zal deze ' +
            'offerte vanuit Ratio niet worden gestuurd naar SalesForce.'
        }
      });
    }
  }

  updateQuotation() {
    if (this.updateQuotationTimeout) {
      clearTimeout(this.updateQuotationTimeout);
    }

    this.updateQuotationTimeout = setTimeout(() => {
      let currentQuotation = this.props.quotation.currentQuotation;

      if (!currentQuotation.customer && this.props.customer.selectedCustomer) {
        currentQuotation.customer = this.props.customer.selectedCustomer;
      }

      if (this.props.quotation.isLead) {
        this.props.leadActions.updateLead(currentQuotation);
      } else {
        this.props.quotationActions.updateQuotation(currentQuotation)
          .then(() => this.checkInputsForValidity(this.globalForm));
      }
    }, 1500);
  }

  updateCustomer() {
    if (this.updateCustomerTimeout) {
      clearTimeout(this.updateCustomerTimeout);
    }

    let quotationId = this.props.quotation.currentQuotation.id;
    let isLead = this.props.quotation.isLead;

    this.updateCustomerTimeout = setTimeout(() => {
      this.props.infoActions.updateCustomer(this.props.customer.selectedCustomer, quotationId, isLead)
        .then(() => {
          this.checkInputsForValidity(this.globalForm);

          if (this.props.customer.selectedCustomer.manually) {
            this.props.locationActions.setQuotationLocations(quotationId, isLead);
          }
        });
    }, 1500);
  }

  renderSuggestion = (suggestion) => {
    if (this.isSalesValueUser() || this.isAutotaskEnabledForAxoftUser()) {
      return;
    }

    return <div className="autocomplete-item">{suggestion.name}</div>;
  };

  getSuggestion = (suggestion) => {
    return suggestion.name;
  };

  handleSuggestionSelected = (e, suggestionObj) => {
    const {suggestion} = suggestionObj;
    const { isVenecoOrganization, hasConnectWiseSync } = this.props.authentication;
    const { currentQuotation } = this.props.quotation;

    if (isVenecoOrganization) {
      let name = currentQuotation.providerName;

      if (name === 'KPN') {
        name = 'KPN_EEN';
      } else if (name === 'Vodafone') {
        name = 'VF';
      } else if (name === 'Odido') {
        name = 'ODIDO'
      } else if (name === 'Venéco') {
        name = 'VNC'
      }  else if (name === 'PHC') {
        name = 'PHC'
      } else if (name === 'Yielder') {
        name = 'YLD';
      }

      if (hasConnectWiseSync) {
        currentQuotation.name = name + "_"
            + currentQuotation.opportunityName + "_"
            + this.props.authentication.nameInitials.substring(0, 3) + "_"
            + moment().format("YYMMDD") + "_"
            + "v1.0";
      } else {
        currentQuotation.name = name + "_"
            + suggestion.name.substring(0, 8) + "_"
            + suggestion.kvk.substring(suggestion.kvk.length - 4) + "_"
            + this.props.authentication.nameInitials.substring(0, 3) + "_"
            + moment().format("YYMMDD") + "_"
            + "v1.0";
      }

      this.props.quotationActions.updateQuotation(this.props.quotation.currentQuotation)
    }

    this.selectCustomer(suggestion.name, suggestion);
    this.props.infoActions.setSelectedCustomerName(suggestion.name);
  };

  onSuggestionsFetchRequested = (input) => {
    if (this.isSalesValueUser() || this.isAutotaskEnabledForAxoftUser()) {
      return;
    }

    this.searchKvK(input.value);
  };

  onSuggestionsClearRequested = () => {
    //this.props.infoActions.setSelectedCustomerName('');
  };

  shouldRenderSuggestions = (value) => {
    if (value.length <= 1) {
      return false;
    }
    return true;
  };

  onChange = (event, {newValue}) => {
    this.props.infoActions.setSelectedCustomerName(newValue);
  };

  onSameData = (checked) => {
    let fullNameContact = this.props.customer.selectedCustomer.commercialContact.fullName;
    let emailContact = this.props.customer.selectedCustomer.commercialContact.email;
    let titleContact = this.props.customer.selectedCustomer.commercialContact.title;
    let phoneNumberContact = this.props.customer.selectedCustomer.commercialContact.phoneNumber;

    if (!checked) {
      fullNameContact = '';
      emailContact = '';
      titleContact = '';
      phoneNumberContact = '';
    }

    this.props.infoActions.updateTechnicalContact(fullNameContact, emailContact, phoneNumberContact, titleContact, true);
    this.setState({contactDataCopy: checked});

    this.updateCustomer();
  };

  onSameDataTechnical = (checked) => {
    let fullNameContact = this.props.customer.selectedCustomer.technicalContact.fullName;
    let emailContact = this.props.customer.selectedCustomer.technicalContact.email;
    let titleContact = this.props.customer.selectedCustomer.technicalContact.title;
    let phoneNumberContact = this.props.customer.selectedCustomer.technicalContact.phoneNumber;

    if (!checked) {
      fullNameContact = '';
      emailContact = '';
      titleContact = '';
      phoneNumberContact = '';
    }

    this.props.infoActions.updateProjectManagerContact(fullNameContact, emailContact, phoneNumberContact, titleContact, true);
    this.setState({contactDataCopyTechnical: checked});

    this.updateCustomer();
  };

  renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} id="kvk-autocomplete"/>
      <label htmlFor="kvk-autocomplete" style={{left: 0}}>
        {this.getCustomerSearchLabel()}
      </label>
    </div>
  );

  canIRMA = (quotation) => {
    return quotation.type && ![quotationType.TYPE_STANDARD, quotationType.TYPE_QA, quotationType.TYPE_VERGELIJKING, quotationType.TYPE_HUUR, quotationType.TYPE_KOOP].includes(quotation.type) && !this.isSalesValueUser();
  };

  getCustomerSearchLabel = () => {
    const {currentQuotation} = this.props.quotation;
    const {organization: {organization_credentials: credentials, name}} = this.props.organizations;
    const dynamics = credentials && credentials.find((el) => el.type === 'dynamics');

    const companySource = Number(currentQuotation.companySource);
    if (this.canIRMA(currentQuotation) && companySource) {
      const credential = currentQuotation.IRMACredentials.filter(each => each.id === companySource);
      return `Bedrijfsnaam of ${credential.length ? credential[0].name : 'IRMA'} nummer *`;
    }

    if (this.isConnectedToPipedrive() || dynamics) {
      return 'Bedrijfsnaam *';
    }

    if (currentQuotation.companySource === 'kvk' || this.isSalesValueUser()) {
      return 'Bedrijfsnaam of KvK nummer *';
    }

    if (currentQuotation.companySource === 'odido') {
      return "Bedrijfsnaam of billing account code *"
    }

    if (currentQuotation.companySource === 'connectWise') {
      return "Bedrijfsnaam of kvk nummer *"
    }

    return 'Bedrijfsnaam *';
  };

  closeOldQuotationDialog = () => {
    this.setState({oldQuotationDialog: false});
  };

  closeDataRetrievalDialog = () => {
    this.setState({dataRetrievalDialog: false});
  };

  closeTechnicalContactDialog = () => {
    this.setState({technicalContactDialog: false});
  };

  closePresetDiscountChangeDialog = () => {
    this.setState({presetDiscountChangeDialog: false});
  };

  closePresetDiscountsDialog = () => {
    this.setState({presetDiscountsInvalid: false});
  };

  closeChangeKvkDialog = () => {
    if (!this.props.customer.selectedCustomer.kvk) {
      return;
    }

    this.setState({changeKvkDialog: false});

    const isAutotask = this.isAutotaskEnabledForAxoftUser();
    const isVerlengen = this.props.quotation.currentQuotation.type === quotationType.TYPE_VERLENGEN;

    if (isVerlengen && isAutotask) {
      this.setState({verlengenUsageDialogOpen: true});
    }
  };

  _handleDialogClose = () => {
    this.setState({importDialogOpened: false});
  };

  save = () => {
    this.props.quotationActions.uploadAKB(this.props.quotation.currentQuotation.id, this.state.file);

    this._handleDialogClose();
  };

  hidePriceChanged = () => {
    this.setState({showPriceChanged: false})
  };

  closeSalesValueModal = () => {
    this.setState({
      enableSalesValueModal: {
        enabled: false
      }
    });
  };

  showProductsChangesList = () => {
    this.setState({showPriceChanged: false, showChanges: true});
  }

  closeChangesDialog = () => {
    this.setState({showChanges: false});
  }

  closeVerlengenUsageDialog = () => {
    this.setState({verlengenUsageDialogOpen: false});
  };

  closeOdidoVerlengenUsageDialog = () => {
    this.setState({odidoVerlengenUsageDialogOpen: false});
  };

  closeYipVerlengenUsageDialog = () => {
    this.setState({yipVerlengenUsageDialogOpen: false});
  };

  updateIrmaId = (irmaID) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    let selectedCustomerId = this.props.customer.selectedCustomer.id;

    this.props.infoActions.updateCustomerIrmaId(quotationId, selectedCustomerId, {irmaID: irmaID}).then((response) => {
      if (response) {
        this.props.infoActions.getQuotationsByKvkIrmaIds(quotationId)
          .then((response) => this.setState({
            existingUserQuotations: response.result,
            existingQuotationsDialog: response.result.length > 0
          }));
        this.initiateExistingDataRetrieval(quotationId)
      }
    });

    this.setState({verlengenUsageDialogOpen: false});
  };

  updateCustomerBillingAccountCode = (odidoBillingAccountCode) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    let selectedCustomerId = this.props.customer.selectedCustomer.id;

    this.props.infoActions.updateCustomerOdidoBillingAccountCode(quotationId, selectedCustomerId, {odidoBillingAccountCode: odidoBillingAccountCode})
      .then((response) => {
        if (response) {
          this.initiateExistingDataRetrieval(quotationId)
        }
      })

    this.setState({odidoVerlengenUsageDialogOpen: false});
  }

  updateYipCustomerNumber = (yipCustomerNumber) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    let selectedCustomerId = this.props.customer.selectedCustomer.id;

    this.props.infoActions.updateYipCustomerNumber(quotationId, selectedCustomerId, {yipCustomerNumber: yipCustomerNumber})
        .then((response) => {
          if (response) {
            this.initiateExistingDataRetrieval(quotationId)
          }
        })

    this.setState({yipVerlengenUsageDialogOpen: false});
  }

  updateYipCustomerNumberForNew = ({target}) => {
    const yipCustomerNumber = target.value;
    const quotationId = this.props.quotation.currentQuotation.id;
    const selectedCustomerId = this.props.customer.selectedCustomer.id;

    this.props.infoActions.updateYipCustomerNumber(quotationId, selectedCustomerId, {yipCustomerNumber: yipCustomerNumber});
  }

  onChangeYipCustomerNumber = ({target}) => {
    const {value} = target;

    this.setState({yipCustomerNumber: value});
  }

  refreshQuotationPrices = () => {
    this.props.quotationActions.refreshQuotationPrices(this.props.quotation.currentQuotation.id).then(() => {
      this.setState({showPriceChanged: false, showChanges: false});
    });
  };

  refreshPresetDiscounts = () => {
    this.props.quotationActions.refreshPresetDiscounts(this.props.quotation.currentQuotation.id).then(() => {
      this.setState({presetDiscountChangeDialog: false})
    });
  };

  isConnectedToPipedrive = () => {
    const isPipedriveCustomer = this.props.quotation.currentQuotation.companySource === 'pipedrive';

    return this.props.authentication.crmConnection === crmConnections.PIPEDRIVE && isPipedriveCustomer;
  };

  generateContractDurationOptions = (options) => {
    const {currentQuotation} = this.props.quotation;

    const contractDurationOptions = options.map(item => <option value={item}>{item} maanden</option>);

    if (currentQuotation.provider === 3) {
      contractDurationOptions.unshift(<option value={1}>1 maand/eenmalig</option>);
    }

    return contractDurationOptions;
  };

  isAutotaskEnabledForAxoftUser = () => {
    return this.props.authentication.isAxoftUser && this.props.authentication.crmConnection === crmConnections.AUTOTASK;
  };

  createExistingQuotationMessage = (currentQuotation) => {
    return (
      <div>
        {[providerType.KPN, providerType.VODAFONE, ...odidoProviders].includes(currentQuotation.provider) && currentQuotation.type === quotationType.TYPE_STANDARD &&
        <div className="existing-quotations-dialog-header">Puur ter info laten we u hier een overzicht zien van offertes
          die gemaakt zijn voor dezelfde klantnaam.</div>
        }

        {[providerType.KPN].includes(currentQuotation.provider) && currentQuotation.type === quotationType.TYPE_VERLENGEN &&
        <div className="existing-quotations-dialog-header">
          Puur ter info laten we u hier een overzicht zien van offertes die gemaakt zijn voor dezelfde Irma ID.
        </div>
        }

        <div className="existing-quotations-dialog">
          {
            this.state.existingUserQuotations.map((existingQuotation, index) => {
              return (
                <div key={index}>
                  {
                    `${(existingQuotation.id)} - ${existingQuotation.quotationName} - ${existingQuotation.userWhoMadeTheQuotation} - ${existingQuotation.lastUpdated}`
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  };

  setSelectedQuotationForOpportunity = () => {
    const quotationID = this.props.quotation.currentQuotation.id;
    const {selectedCwOpportunity} = this.state;

    this.props.infoActions.setSelectedQuotationForOpportunity(quotationID, selectedCwOpportunity)
      .then(async () => {
        await this.props.quotationActions.getQuotation(quotationID);
        this.setState({existingOpportunitiesDialog: false});
      }).then(() => this.checkCWDataRetrieval());
  };

  checkCWDataRetrieval = () => {
    const {quotation, customer, authentication} = this.props;
    const {hasConnectWiseSync} = authentication;

    if (!hasConnectWiseSync) {
      return;
    }

    const {selectedCustomer} = customer;
    const {currentQuotation} = quotation;

    const isVerlengenType = currentQuotation.type === quotationType.TYPE_VERLENGEN;
    if (!isVerlengenType) {
      return;
    }

    if (selectedCustomer && !selectedCustomer.connectWiseId) {
      return;
    }

    if (currentQuotation && !currentQuotation.opportunityNumberCW) {
      return;
    }

    const isKpnProvider = currentQuotation.provider === providerType.KPN;
    const isOdidoProvider = currentQuotation.provider === providerType.ODIDO;
    const isOwnBrandProvider = ownBrandProviders.includes(currentQuotation.provider);

    let irmaID = selectedCustomer.api_id || selectedCustomer.apiId;
    let needsIrmaIdDialog = !(irmaID && irmaID.length > 0);
    let yipCustomerNumber = selectedCustomer.yipCustomerNumber;
    let billingAccountCode = selectedCustomer.odidoBillingAccountCode;

    let kpnTrigger = isKpnProvider && !needsIrmaIdDialog;
    let odidoTrigger = isOdidoProvider && billingAccountCode;
    let ownBrandTrigger = isOwnBrandProvider && yipCustomerNumber;

    if (kpnTrigger || odidoTrigger || ownBrandTrigger) {
      this.initiateExistingDataRetrieval(currentQuotation.id);
    }
  };

  closeConnectWiseBlockingDialog = () => {
    this.setState({connectWiseBlockingDialog: false})
  };

  createInactiveCustomProductForm = () => {
    return (
      <div>
        <div style={{marginBottom: '30px'}}>
          Er zijn in deze offerte partner producten gevonden die niet meer op actief staan. Deze producten staan nog wel
          in de offerte. Wij geven u hier de mogelijkheid deze producten te verwijderen of het aantal te wijzigen.
        </div>
        <div style={{marginBottom: '40px'}}>
          De aan dit product gekoppelde subproducten worden met dezelfde aantallen gewijzigd
        </div>
        {
          Object.keys(this.state.inactiveCustomProducts).map((id) => {
            const inactiveCustomProduct = this.state.inactiveCustomProducts[id];
            return (
              <Row key={id} style={{marginLeft: '-10px'}}>
                <div className="input-field col s6">
                  <input
                    type="text"
                    ref="customProductName"
                    name="customProductName"
                    value={inactiveCustomProduct.name}
                    required
                  />
                  <label htmlFor="customProductName"> Product </label>
                </div>

                <div className="input-field col s3">
                  <input
                    type="text"
                    ref="customProductNumber"
                    name="customProductNumber"
                    value={inactiveCustomProduct.productNumber}
                  />
                  <label htmlFor="customProductNumber"> ProductNr </label>
                </div>

                <div className="input-field col s3">
                  <input
                    type="number"
                    ref="customProductQuantity"
                    min={0}
                    max={999}
                    name="customProductQuantity"
                    value={inactiveCustomProduct.quantity}
                    onChange={(e) => this.onChangeInactiveCustomProductQuantity(e, inactiveCustomProduct.id)}
                    required
                  />
                  <label htmlFor="customProductQuantity"> Aantal </label>
                </div>
              </Row>
            )
          })
        }

        <button
          className='btn doceri-btn-right ratio-btn-right-fullwidth right'
          name="submitInactiveCustomProducts"
          onClick={this.updateInactiveProducts}
        >
          Aanpassingen definitief doorvoeren
        </button>
      </div>
    )
  };

  closeExistingQuotationsDialog = () => {
    this.setState({existingQuotationsDialog: false})
  };

  closeExistingOpportunitiesDialog = (quotation) => {
    //this.props.infoActions.createCwOpportunity(quotation)
    this.setState({existingOpportunitiesDialog: false})
  };

  closeInactiveCustomProductsDialog = () => {
    this.setState({isInactiveCustomProductsDialog: false})
  };

  onChangeInactiveCustomProductQuantity = ({target: {value, name}}, id) => {
    let state = {
      inactiveCustomProducts: {
        ...this.state.inactiveCustomProducts,
        [id]: {
          ...this.state.inactiveCustomProducts[id],
          quantity: value
        }
      }
    };
    this.setState(state);
  };

  updateInactiveProducts = () => {
    this.props.infoActions.updateInactiveCustomProducts(this.props.quotation.currentQuotation.id, this.state.inactiveCustomProducts)
      .then((response) =>
        this.setState({inactiveCustomProducts: response.inactiveCustomProducts, isInactiveCustomProductsDialog: false})
      );
  };

  onCwOpportunitySelect = ({target}) => {
    const opportunity = this.state.existingOpportunities.find((opportunity) => opportunity.id === parseInt(target.value));
    this.setState({selectedCwOpportunity: opportunity});
  };

  onSelectedIrmaCustomer = ({target}) => {
    this.setState({selectedIrmaCustomer: target.value});
  };

  onSaveIrmaCustomer = () => {
    const {selectedIrmaCustomer} = this.state;

    const quotationId = this.props.quotation.currentQuotation.id;
    let selectedCustomerId = this.props.customer.selectedCustomer.id;

    this.props.infoActions.updateCustomerIrmaId(quotationId, selectedCustomerId, {irmaID: selectedIrmaCustomer})
      .then((response) => {
        if (response) {
          this.props.infoActions.getQuotationsByKvkIrmaIds(quotationId)
            .then((response) => this.setState({
              existingUserQuotations: response.result,
              existingQuotationsDialog: response.result.length > 0
            }));
          this.initiateExistingDataRetrieval(quotationId);
        }

        this.setState({showSecondaryIRMASearch: false, selectedIrmaCustomer: null});
      });
  };

  openConnectWiseOpportunityLink = () => {
    const {hasConnectWiseSync} = this.props.authentication;
    if (!hasConnectWiseSync) {
      return;
    }

    const {currentQuotation} = this.props.quotation;
    const {connectWiseOpportunityUrl} = currentQuotation;

    if (!(connectWiseOpportunityUrl && connectWiseOpportunityUrl.length > 0)) {
      return;
    }

    window.open(connectWiseOpportunityUrl, '_blank');
  };

  getContactLabel = (contactType) => {
    const {authentication, quotation} = this.props;
    const {currentQuotation} = quotation;
    const {isVenecoOrganization} = authentication;
    const {provider} = currentQuotation;
    const isYielderProvider = provider === providerType.YIELDER;

    if (contactType === 'commercialContact') {
      return 'gegevens tekenbevoegde';
    }

    if (contactType === 'technicalContact') {
      return 'gegevens technisch contactpersoon';
    }

    if (contactType === 'projectManager') {
      return 'gegevens projectmanager';
    }

    if (contactType === 'financialContact') {
      if (isYielderProvider) {
        return 'Factuurgegevens klant (niet verplicht)';
      } else {
        return 'factuurgegevens';
      }
    }

    return '';
  }

  render() {
    const {allDigitalSigningGroups} = this.props.digitalSigningGroups;
    const {allDigitalSigningFields} = this.props.digitalSigningFields;

    const {selectedCustomer, matchingCustomers} = this.props.customer;
    const {
      commercialContact,
      technicalContact,
      projectManager,
      financialContact,
    } = selectedCustomer;

    const {currentQuotation, isLead} = this.props.quotation;
    const {provider, type, inputsDisabled, companySource} = currentQuotation;

    const {authentication, organizations} = this.props;
    const {roles, hasConnectWiseSync, theme, isPHC, isVenecoOrganization} = authentication;
    const {organization} = organizations;

    const isSalesValueUser = this.isSalesValueUser();
    const isAutotask = this.isAutotaskEnabledForAxoftUser();
    const isWRUser = [Organization.WR, Organization.WR_TELDACOM].includes(organization.name);

    const isKpnProvider = provider === providerType.KPN;
    const isOdidoProvider = provider === providerType.ODIDO;
    const isVodafoneProvider = provider === providerType.VODAFONE;
    const isOwnBrandProvider = ownBrandProviders.includes(provider);
    const isDienstenOfferteProvider = provider === providerType.DBIT;
    const isYielderProvider = provider === providerType.YIELDER;

    const isVerlengen = type === quotationType.TYPE_VERLENGEN;
    const isStandardQuotation = STANDARD_QUOTATION_TYPES.includes(type);

    const inputProps = {
      placeholder: 'Zoekterm...',
      value: selectedCustomer.name,
      onChange: this.onChange,
      readOnly: this.isSalesValueUser() || isAutotask,
      s: 12,
      title: this.getCustomerSearchLabel(),
    };

    const kpnContractDurations = [12, 24, 36, 60];
    const vodafoneContractDurations = [12, 24, 36, 48, 60];
    const salesValueContractDurations = [24, 36];
    const odidoContractDurations = [1, 12, 24, 36, 48, 60];
    const ownBrandContractDurations = [1, 12, 24, 36, 48, 60];

    let availableContractDurations = !isSalesValueUser
      ? (isVodafoneProvider ? vodafoneContractDurations : kpnContractDurations)
      : salesValueContractDurations;

    if (isOdidoProvider) {
      availableContractDurations = odidoContractDurations;
    }
    if (isOwnBrandProvider) {
      availableContractDurations = ownBrandContractDurations;
    }

    let quotationValidityDurations = [];
    if (isYielderProvider) {
      quotationValidityDurations.push(1);
    }
    quotationValidityDurations.push(15, 30, 45, 60, 90);

    const isPipedrive = companySource === 'pipedrive';
    const isVodafoneException = isVodafoneProvider && (['kvk', 'manually'].includes(companySource));
    const hasAdminAndSalesSupportAccess = hasRequiredRole(roles, ['ROLE_ADMIN', 'ROLE_SALES_SUPPORT']);

    let IRMACredentials = [];
    if (this.canIRMA(currentQuotation) && !isWRUser && !isDienstenOfferteProvider) {
      IRMACredentials = currentQuotation.IRMACredentials;
    }

    let irmaID = selectedCustomer.apiId || selectedCustomer.api_id;

    const manually = currentQuotation.companySource === 'manually';

    const credentials = organization.organization_credentials;
    const dynamicsCredentials = credentials && !!credentials.find((el) => el.type === 'dynamics');
    let renderCredentials = [];

    if (isOdidoProvider || isOwnBrandProvider) {
      IRMACredentials = IRMACredentials.filter((el) => el.name !== 'Nina');
    }

    if (dynamicsCredentials) {
      renderCredentials.push({value: 'dynamics', name: 'Dynamics'})
    }

    if (isPipedrive) {
      renderCredentials.push({value: 'pipedrive', name: 'Pipedrive'})
    }

    if (isAutotask) {
      renderCredentials.push({value: 'autotask', name: 'Autotask'})
    }

    if (!hasConnectWiseSync) {
      if ((isStandardQuotation || isVodafoneException || isDienstenOfferteProvider) && !isWRUser && !isAutotask) {
        renderCredentials.push({value: 'manually', name: 'Handmatige invoer'})
      }
    }

    if (hasConnectWiseSync) {
      renderCredentials.push({value: 'connectWise', name: 'ConnectWise'})
    }

    if (!hasConnectWiseSync) {
      let IRMACredentialCondition = !IRMACredentials.length && !isWRUser && !isPipedrive && !isAutotask;

      if (IRMACredentialCondition || isVodafoneException || isOdidoProvider || isOwnBrandProvider) {
        renderCredentials.push({value: 'kvk', name: 'KvK'})
      } else {
        IRMACredentials.forEach((credential) => {
          renderCredentials.push({value: credential.id, name: credential.name});
        })
      }
    }

    const legalFormOptions = [
      'BV', 'BR', 'CV', 'EK', 'MP', 'NV', 'PLM', 'SG', 'VOF', 'VG'
    ];

    const extraDigitalSigningGroups = allDigitalSigningGroups.filter(item => !item.isDigitalSigning);

    const {
      loading,
      validity,
      contactDataCopy,
      contactDataCopyTechnical,
      selectedCwOpportunity,
      existingOpportunities,
      hasSpecificValidityDate,
      oldQuotationDialog,
      dataRetrievalDialog,
      verlengenUsageDialogOpen,
      odidoVerlengenUsageDialogOpen,
      yipVerlengenUsageDialogOpen,
      connectWiseBlockingDialog,
      technicalContactDialog,
      presetDiscountChangeDialog,
      changeAddressDialog,
      fieldNotFilled,
      changeKvkDialog,
      existingQuotationsDialog,
      existingOpportunitiesDialog,
      presetDiscountsInvalid,
      showPriceChanged,
      enableSalesValueModal,
      showChanges,
      isInactiveCustomProductsDialog,
      showSecondaryIRMASearch,
      secondaryIrmaResults,
      selectedIrmaCustomer,
      yipCustomerNumber
    } = this.state;

    return (
      <form ref={(ref) => (this.globalForm = ref)}>
        {loading
          ? <LoaderComponent theme={theme}/>
          : <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  {isLead ? 'Lead' : 'Offerte'} {isSalesValueUser ? 'naam' : 'details'}
                </h1>
              </div>

              <VerlengenUsageDialog
                verlengenDialogOpen={verlengenUsageDialogOpen}
                closeVerlengenUsageDialog={this.closeVerlengenUsageDialog}
                updateIrmaId={this.updateIrmaId}
              />

              <OdidoVerlengenUsageDialog
                verlengenDialogOpen={odidoVerlengenUsageDialogOpen}
                closeVerlengenUsageDialog={this.closeOdidoVerlengenUsageDialog}
                updateOdidoillingAccountCode={this.updateCustomerBillingAccountCode}
              />

              <OwnBrandVerlengenUsageDialog
                  dialogState={yipVerlengenUsageDialogOpen}
                  closeDialog={this.closeYipVerlengenUsageDialog}
                  updateCustomerProperty={this.updateYipCustomerNumber}
              />

              <div className={'row'}>
                <div className="input-field col s12 height-50px">
                  <input
                    type="text"
                    required
                    ref="name"
                    name="name"
                    value={currentQuotation.name}
                    onChange={this.updateQuotationProperty}
                    className="validate"
                    disabled={isPHC}
                  />
                  {!isSalesValueUser &&
                  <label htmlFor="name">{isLead ? 'Lead' : 'Offerte'} naam</label>
                  }
                </div>
              </div>

              {!isSalesValueUser && !isLead &&
              <div className={'row'} style={{paddingTop: 20}}>
                <div className="input-field col s12 height-50px">
                  <input
                    type="text"
                    ref="internalReference"
                    name="internalReference"
                    value={currentQuotation.internalReference}
                    onChange={this.updateQuotationProperty}
                  />
                  <label htmlFor="internalReference">Interne Referentie</label>
                </div>
              </div>
              }
            </div>

            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  bedrijfsgegevens
                </h1>
              </div>

              <div className={'row'}>
                {!isSalesValueUser &&
                <Input
                  s={12}
                  m={`${this.isConnectedToPipedrive() ? 2 : 3}`}
                  l={`${this.isConnectedToPipedrive() ? 2 : 3}`}
                  ref="companySource"
                  name="companySource"
                  type="select"
                  label="Bron bedrijfsgegevens *"
                  onChange={this.updateQuotationProperty}
                  value={currentQuotation.companySource}
                  multiple={false}
                  required
                  className="validate"
                >
                  {renderCredentials.map(({value, name}) => <option value={value}>{name}</option>)}
                </Input>
                }

                {this.isConnectedToPipedrive() &&
                <div
                  className={`input-field col s12 m7`}
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <input
                    type="text"
                    required
                    ref="dealName"
                    name="dealName"
                    value={currentQuotation.dealName}
                    onChange={this.updateQuotationProperty}
                    className="validate"
                  />
                  <label htmlFor="street">Deal</label>
                </div>
                }

                {this.isConnectedToPipedrive() &&
                <div
                  className={`input-field col s12 m3`}
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <input
                    type="text"
                    required
                    ref="dealId"
                    name="dealId"
                    value={currentQuotation.dealId}
                    onChange={this.updateQuotationProperty}
                    className="validate"
                  />
                  <label htmlFor="street">Deal ID</label>
                </div>
                }

                <div
                  className={`input-field col s12 ${this.isConnectedToPipedrive() ? "m8" : "l9 m9"} ${hasAdminAndSalesSupportAccess ? "s10" : "s12"}`}>
                  {hasAdminAndSalesSupportAccess && manually ?
                    <React.Fragment>
                      <input
                        type="text"
                        ref="name"
                        name="name"
                        value={selectedCustomer.name}
                        readOnly={currentQuotation.companySource !== 'manually'}
                        onChange={this.updateCompanyInfo}
                        className="validate company"
                        required
                      />
                      <label htmlFor="name">Bedrijfsnaam *</label>
                    </React.Fragment>
                    :
                    <Autosuggest
                      suggestions={matchingCustomers}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={this.getSuggestion}
                      renderSuggestion={this.renderSuggestion}
                      shouldRenderSuggestions={
                        this.shouldRenderSuggestions
                      }
                      renderInputComponent={this.renderInputComponent}
                      inputProps={inputProps}
                      onSuggestionSelected={
                        this.handleSuggestionSelected
                      }
                    />
                  }
                </div>

                {this.isConnectedToPipedrive() &&
                <div className={"input-field col " + (isVerlengen ? "s4 m2" : "s12 m4")}>
                  <input
                    type="text"
                    required
                    ref={(ref) => (this.kvk = ref)}
                    name="kvk"
                    value={selectedCustomer.kvk}
                    onChange={this.updateCompanyInfo}
                    readOnly={!!selectedCustomer.id && !manually}
                    minLength="8"
                    maxLength="8"
                    pattern="[0-9]{8}"
                    placeholder="8 cijfers"
                    className="validate company"
                  />
                  <label htmlFor="kvk">KvK nummer *</label>
                </div>
                }
                {this.isConnectedToPipedrive() && isVerlengen && !isOdidoProvider && !isOwnBrandProvider && (
                  <div className="input-field col s4 m2">
                    <input
                      id="customerIrmaID"
                      type="text"
                      name="customerIrmaID"
                      value={irmaID || ''}
                      readOnly={true}
                    />
                    <label htmlFor="customerIrmaID">IRMA ID *</label>
                  </div>
                )}
              </div>

              <div className={'row'}>
                <div className="input-field col s12 m8">
                  <input
                    type="text"
                    ref="street"
                    name="street"
                    value={selectedCustomer.street || ''}
                    readOnly={!manually}
                    onChange={this.updateCompanyInfo}
                    className={manually ? "validate company" : "company"}
                    required
                  />
                  <label htmlFor="street">Straat *</label>
                </div>

                <div className="input-field col s4 m2">
                  <input
                    type="number"
                    ref="houseNumber"
                    name="houseNumber"
                    value={selectedCustomer.houseNumber || ''}
                    readOnly={!manually}
                    onChange={this.updateCompanyInfo}
                    className={(manually || currentQuotation.companySource === 'dynamics') ? "validate company" : "company"}
                    pattern="[0-9]+"
                    required
                  />
                  <label htmlFor="houseNumber">huisnr. *</label>
                </div>

                <div className="input-field col s4 m2">
                  <input
                    type="text"
                    ref="houseNumberExtension"
                    name="houseNumberExtension"
                    minLength="0"
                    maxLength="20"
                    pattern="[a-zA-Z0-9]{20}"
                    value={
                      selectedCustomer.houseNumberExtension
                        ? '' +
                        selectedCustomer.houseNumberExtension
                        : ''
                    }
                    readOnly={!manually}
                    onChange={this.updateCompanyInfo}
                  />
                  <label htmlFor="houseNumberExtension">toev.</label>
                </div>
              </div>

              <div className={'row'}>
                <div className="input-field col s12 m2">
                  <input
                    type="text"
                    ref="postalCode"
                    name="postalCode"
                    value={
                      selectedCustomer.postalCode
                    }
                    readOnly={!manually}
                    onChange={this.updateCompanyInfo}
                    className={manually ? "validate company" : "company"}
                    pattern="[1-9][0-9]{3}[a-zA-Z]{2}"
                    required
                  />
                  <label htmlFor="postalCode">Postcode *</label>
                </div>

                <div className={"input-field col s12 m10"}>
                  <input
                    type="text"
                    ref="city"
                    name="city"
                    value={selectedCustomer.city}
                    readOnly={!manually}
                    onChange={this.updateCompanyInfo}
                    className={manually ? "validate company" : "company"}
                    pattern="[a-zA-Z\\'\\`\\-]+"
                    required
                  />
                  <label htmlFor="city">Plaats *</label>
                </div>
              </div>

              <div className={'row'}>
                {!this.isConnectedToPipedrive() &&
                <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                  <input
                    id="kvk"
                    type="text"
                    required
                    ref={(ref) => (this.kvk = ref)}
                    name="kvk"
                    value={selectedCustomer.kvk}
                    onChange={this.updateCompanyInfo}
                    readOnly={(!!selectedCustomer.id && !manually) || isAutotask}
                    minLength="8"
                    maxLength="8"
                    pattern="[0-9]{8}"
                    placeholder="8 cijfers"
                    className={manually ? "validate company" : "company"}
                  />
                  <label htmlFor="kvk">KvK nummer *</label>
                </div>
                }

                {(isOdidoProvider || isOwnBrandProvider) && (
                    <Input
                      s={12}
                      m={`${isVerlengen ? 2 : 3}`}
                      l={`${isVerlengen ? 2 : 3}`}
                      ref="legalForm"
                      name="legalForm"
                      type="select"
                      label="Rechtsvorm *"
                      onChange={this.updateCompanyInfo}
                      value={selectedCustomer.legalForm}
                      multiple={false}
                      required
                      className="validate"
                    >
                      <option value=""/>
                      {legalFormOptions.map((value) => <option value={value}>{value}</option>)}
                    </Input>
                  )
                }

                {isOdidoProvider && isVerlengen &&
                <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                  <input
                    id="odidoBillingAccountCode"
                    type="text"
                    required
                    ref="odidoBillingAccountCode"
                    name="odidoBillingAccountCode"
                    onChange={this.updateQuotationProperty}
                    readOnly={!!selectedCustomer.id && !manually}
                    value={selectedCustomer.odidoBillingAccountCode}
                  />
                  <label htmlFor="odidoBillingAccountCode">Odido Billing Account Code</label>
                </div>
                }

                {isOwnBrandProvider &&
                    <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                      <input
                          id="yipCustomerNumber"
                          type="text"
                          ref="yipCustomerNumber"
                          name="yipCustomerNumber"
                          onChange={this.onChangeYipCustomerNumber}
                          onBlur={this.updateYipCustomerNumberForNew}
                          value={yipCustomerNumber}
                          readOnly={isVerlengen}
                      />
                      <label htmlFor="yipCustomerNumber">YIP klant nummer</label>
                    </div>
                }

                {(isSalesValueUser || isAutotask) &&
                <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                  <input
                    id="opportunityNumber"
                    type="text"
                    required
                    ref="opportunityNumber"
                    name="opportunityNumber"
                    maxLength="18"
                    value={currentQuotation.opportunityNumber}
                    onChange={this.updateQuotationProperty}
                  />
                  <label htmlFor="opportunityNumber">Opportunity ID *</label>
                </div>
                }

                {(hasConnectWiseSync) &&
                <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                  <input
                    id="opportunityNumberCW"
                    type="text"
                    required
                    ref="opportunityNumberCW"
                    name="opportunityNumberCW"
                    maxLength="18"
                    style={{color: 'blue', cursor: 'pointer'}}
                    value={currentQuotation.opportunityNumberCW}
                    onClick={() => this.openConnectWiseOpportunityLink()}
                    readOnly={true}
                  />
                  <label htmlFor="opportunityNumber">Opportunity ID *</label>
                </div>
                }

                {!isOdidoProvider && !isOwnBrandProvider && !isSalesValueUser && !this.isConnectedToPipedrive() && !isAutotask &&
                <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                  <input
                    id="crmNumber"
                    type="text"
                    ref="crmNumber"
                    name="crmNumber"
                    value={currentQuotation.crmNumber}
                    onChange={this.updateQuotationProperty}
                    readOnly={isAutotask}
                    className="validate"
                    pattern="^.{0,20}$"
                    maxLength="20"
                  />
                  <label htmlFor="crmNumber">CRM nummer</label>
                </div>
                }

                {!isSalesValueUser && !isAutotask && !isStandardQuotation &&
                ProviderHelper.hasModuleEnabled(currentQuotation, 'ckrNumber') ?
                  <div className={"input-field col s12 m" + (isVerlengen ? "3" : "4")}>
                    <input
                      id="ckrNumber"
                      type="text"
                      ref="ckrNumber"
                      name="ckrNumber"
                      value={currentQuotation.ckrNumber}
                      onChange={this.updateQuotationProperty}
                    />
                    <label htmlFor="ckrNumber">CKR nummer</label>
                  </div> : ''
                }
                {!this.isConnectedToPipedrive() && isVerlengen && !isOdidoProvider && !isOwnBrandProvider && (
                  <div className="input-field col s12 m3">
                    <input
                      id="customerIrmaID"
                      type="text"
                      name="customerIrmaID"
                      value={irmaID || ''}
                      readOnly={true}
                    />
                    <label htmlFor="customerIrmaID">IRMA ID</label>
                  </div>
                )}
              </div>

              <ContactInfo
                config={commercialContact}
                ref={(ref) => (this.commercialContactRef = ref)}
                typeOfCustomer="commercialContact"
                sectionHeader={this.getContactLabel('commercialContact')}
                required={!isYielderProvider}
                onChangeFunc={this.contactInfoChange}
                telephoneNumberLimit={true}
                hasConnectWiseSync={hasConnectWiseSync}
                isYielderProvider={isYielderProvider}
                isVenecoOrganization={isVenecoOrganization}
              />

              <ContactInfo
                config={technicalContact}
                ref={(ref) => (this.technicalContactRef = ref)}
                typeOfCustomer={'technicalContact'}
                sectionHeader={this.getContactLabel('technicalContact')}
                onChangeFunc={this.contactInfoChange}
                onSameDataFunc={this.onSameData}
                updateQuotationProperty={this.updateQuotationProperty}
                useTechnicalContactForOrder={currentQuotation.useTechnicalContactForOrder}
                checked={contactDataCopy}
                telephoneNumberLimit={false}
                hasConnectWiseSync={hasConnectWiseSync}
                isYielderProvider={isYielderProvider}
                isVenecoOrganization={isVenecoOrganization}
              />

              {!isYielderProvider &&
                <ContactInfo
                    config={projectManager}
                    ref={(ref) => (this.projectmanagerContactRef = ref)}
                    typeOfCustomer="projectManager"
                    sectionHeader={this.getContactLabel('projectManager')}
                    onChangeFunc={this.contactInfoChange}
                    onSameDataFunc={this.onSameDataTechnical}
                    checked={contactDataCopyTechnical}
                    telephoneNumberLimit={false}
                    hasConnectWiseSync={hasConnectWiseSync}
                    isYielderProvider={isYielderProvider}
                    isVenecoOrganization={isVenecoOrganization}
                />
              }

              <React.Fragment>
                <ContactInfo
                  config={financialContact}
                  ref={(ref) => (this.financialContactRef = ref)}
                  typeOfCustomer="financialContact"
                  sectionHeader={this.getContactLabel('financialContact')}
                  onChangeFunc={this.contactInfoChange}
                  handleChange={this.updateContactInfoAdditionalProperties}
                  ibanValue={selectedCustomer.iban}
                  btwNumber={selectedCustomer.btwNumber}
                  bicCode={selectedCustomer.bicCode}
                  telephoneNumberLimit={true}
                  hasConnectWiseSync={hasConnectWiseSync}
                  isYielderProvider={isYielderProvider}
                  isVenecoOrganization={isVenecoOrganization}
                />
              </React.Fragment>
            </div>

            <div className={"flex-space-between"}>
              <div className="Forms">
                <div>
                  <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">
                          assignment
                      </i>
                      info:
                    </span>{' '}
                    Project gegevens
                  </h1>
                </div>

                <div>
                  <Input
                    s={12}
                    ref="contractDuration"
                    name="contractDuration"
                    type="select"
                    label="Contractduur *"
                    onChange={this.updateQuotationProperty}
                    value={currentQuotation.contractDuration}
                    multiple={false}
                    required
                    className="validate"
                  >
                    <option value=""/>
                    {this.generateContractDurationOptions(availableContractDurations)}
                  </Input>

                  <Input
                    s={12}
                    ref="quotationValidity"
                    name="quotationValidity"
                    type="select"
                    label="Geldigheid offerte"
                    onChange={this.updateQuotationProperty}
                    value={currentQuotation.quotationValidity}
                    multiple={false}
                  >
                    <option value=""/>
                    {quotationValidityDurations.map((x) => (
                      <option value={x}>{x} {x === 1 ? 'dag' : 'dagen'}</option>
                    ))}
                    <option value="end_of_month">Einde v.d. maand</option>
                    <option value="end_of_quarter">Einde kwartaal</option>
                    <option value="specific_validity_date">Selecteer datum</option>
                  </Input>
                  {hasSpecificValidityDate &&
                  <div className="col input-field s6 m3">
                    <DatePicker
                      name="specific_date"
                      id={"specific_date"}
                      selected={Date.parse(currentQuotation.specificValidityDate)}
                      onChange={this.onChangeSpecificValidityDate}
                      onChangeRaw={e => e.preventDefault()}
                      dateFormat="yyyy-MM-dd"
                      autoComplete="off"
                      popperPlacement="bottom"
                      popperModifiers={{
                        preventOverflow: {
                          enabled: false
                        },
                        hide: {
                          enabled: false
                        }
                      }}
                    />
                    <label htmlFor="specific_date">Selecteer datum</label>
                  </div>
                  }
                  {isSalesValueUser &&
                  <Input
                    s={12}
                    required
                    ref="inflationCorrection"
                    name="inflationCorrection"
                    type="select"
                    label="Inflatiecorrectie"
                    onChange={this.updateQuotationProperty}
                    value={currentQuotation.inflationCorrection}
                    multiple={false}
                  >
                    <option value="default-option">Geen inflatiecorrectie</option>
                    <option value="mobile">Alleen mobiel</option>
                    <option value="voice">Alleen voip</option>
                    <option value="access">Alleen verbindingen</option>
                    <option value="mobile-voice">Mobiel en Voip</option>
                    <option value="mobile-access">Mobiel en verbindingen</option>
                    <option value="voice-access">Voip en verbindingen</option>
                    <option value="mobile-voice-access">Mobiel, voip en verbindingen</option>
                  </Input>
                  }
                </div>
              </div>
            </div>

            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  Toelichting/gespreksverslag
                </h1>
              </div>

              <div className={'row'}>
                <div className="input-field col s12">
            <textarea
              ref="report"
              name="report"
              onChange={this.updateQuotationProperty}
              className="validate"
              maxLength="1750"
              value={currentQuotation.report}
            />
                </div>
              </div>
            </div>

            {isSalesValueUser &&
            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  Wilsverklaring Nummerportabiliteit (Alle velden zijn verplicht)
                  <p>Let op: Lees onderstaande 3 zinnen voor en laat de klant akoord geven</p>
                </h1>
              </div>

              <div className="row">
                <div className="col s12">
                  <p>U machtigt KPN om bij uw huidige telefonieaanbieder uw telefoonabonnement en de daarin gekoppelde
                    diensten op te zeggen met behoud van de zojuist besproken telefoonnummer(s).</p>
                  <div className="col s12">
                    <input name='firstAgreement' id='firstAgreement' type='checkbox'
                           checked={currentQuotation.firstAgreement}
                           onChange={this.updateQuotationProperty}/>
                    <label className={(!currentQuotation.firstAgreement ? 'text-danger' : '')} htmlFor="firstAgreement">Gaat
                      u hiermee akkoord?</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <p>Bent u bevoegd om namens {selectedCustomer.name} een KPN EEN order te plaatsen en verklaart u de
                    juiste
                    gegevens
                    doorgegeven te hebben?</p>
                  <div className="col s12">
                    <input name='secondAgreement' id='secondAgreement' type='checkbox'
                           checked={currentQuotation.secondAgreement}
                           onChange={this.updateQuotationProperty}/>
                    <label className={(!currentQuotation.secondAgreement ? 'text-danger' : '')}
                           htmlFor="secondAgreement">Ja,
                      dat is correct.</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <p>Indien u het lopende contract met uw huidige provider vroegtijdig beëindigd brengt uw huidige
                    provider hiervoor mogelijk een afkoopsom in rekening. U bent zelf verantwoordelijk voor uw
                    contracttermijn en kosten bij de huidige provider.</p>
                  <div className="col s12">
                    <input name='thirdAgreement' id='thirdAgreement' type='checkbox'
                           checked={currentQuotation.thirdAgreement}
                           onChange={this.updateQuotationProperty}/>
                    <label className={(!currentQuotation.thirdAgreement ? 'text-danger' : '')} htmlFor="thirdAgreement">Gaat
                      u hiermee akkoord?</label>
                  </div>
                </div>
              </div>
            </div>
            }

            {isSalesValueUser &&
            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  Aanbiedingen en acties KPN (Minimaal 1 optie verplicht)
                </h1>
              </div>

              <div className="row">
                <div className="col s12">
                  <p>Wilt u op de hoogte gehouden worden van aanbiedingen en acties van KPN?</p>

                  <div className="col s12 m3">
                    <input name='contactEmail' id='contactEmail' type='checkbox'
                           checked={currentQuotation.contactEmail}
                           onChange={this.updateQuotationProperty}/>
                    <label htmlFor="contactEmail" className={(this.hasDanger() ? 'text-danger' : '')}>via E-mail</label>
                  </div>

                  <div className="col s12 m3">
                    <input name='contactPhone' id='contactPhone' type='checkbox'
                           checked={currentQuotation.contactPhone}
                           onChange={this.updateQuotationProperty}/>
                    <label htmlFor="contactPhone" className={(this.hasDanger() ? 'text-danger' : '')}>via
                      Telefoon</label>
                  </div>

                  <div className="col s12 m3">
                    <input name='contactSms' id='contactSms' type='checkbox'
                           checked={currentQuotation.contactSms}
                           onChange={this.updateQuotationProperty}/>
                    <label htmlFor="contactSms" className={(this.hasDanger() ? 'text-danger' : '')}>via SMS</label>
                  </div>

                  <div className="col s12 m3">
                    <input name='contactNone' id='contactNone' type='checkbox'
                           checked={currentQuotation.contactNone}
                           onChange={this.updateQuotationProperty}/>
                    <label htmlFor="contactNone" className={(this.hasDanger() ? 'text-danger' : '')}>Klant heeft geen
                      interesse</label>
                  </div>
                </div>
              </div>
            </div>
            }

            {isSalesValueUser &&
            <div className={'Forms'}>
              <div>
                <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      business
                  </i>
                  info:
              </span>{' '}
                  Overstapservice
                </h1>
              </div>

              <div className="row">
                <div className="col s12">
                  <p>
                    Is er een overstapservice benodigd voor deze order? <br/>
                    <i>(Notitie voor adviseur: de klant heeft reeds een KPN-product (CADSL, Kleinzakelijk, OPIB, ZADSL,
                      PSTN/ISDN) en stapt over naar KPN EEN.)</i>
                  </p>

                  <input
                    id="transferService1"
                    name="transferService"
                    type="radio"
                    value="1"
                    onChange={this.updateQuotationProperty}
                    checked={currentQuotation.transferService === 1}
                    className='with-gap'
                  />
                  <label htmlFor='transferService1'
                         className={(currentQuotation.transferService === null ? 'text-danger' : '')}>Ja</label>

                  &nbsp;&nbsp;

                  <input
                    id="transferService0"
                    name="transferService"
                    type="radio"
                    value="0"
                    onChange={this.updateQuotationProperty}
                    checked={currentQuotation.transferService === 0}
                    className='with-gap'
                  />
                  <label htmlFor='transferService0'
                         className={(currentQuotation.transferService === null ? 'text-danger' : '')}>Nee</label>

                </div>
              </div>

              {currentQuotation.transferService === 0 &&
              <div className="row">
                <div className="col s12">
                  <p>Reden:</p>

                  <p>
                    <input
                      id="transferServiceReason1"
                      name="transferServiceReason"
                      type="radio"
                      value="1"
                      onChange={this.updateQuotationProperty}
                      checked={currentQuotation.transferServiceReason === 1}
                    />
                    <label htmlFor='transferServiceReason1'
                           className={(this.hasInvalidTransferReason() ? 'text-danger' : '')}>
                      De klant heft haar eigen product op <br/>
                      <i>(Notitie voor adviseur: benadruk nog extra aan de klant: U maakt geen gebruik van onze
                        overstapservice en u heeft gekozen om zelf uw huidige producten op te heffen.)</i>
                    </label>
                  </p>

                  <p>
                    <input
                      id="transferServiceReason2"
                      name="transferServiceReason"
                      type="radio"
                      value="2"
                      onChange={this.updateQuotationProperty}
                      checked={currentQuotation.transferServiceReason === 2}
                    />
                    <label htmlFor='transferServiceReason2'
                           className={(this.hasInvalidTransferReason() ? 'text-danger' : '')}>
                      De klant wenst haar huidige product(en) te behouden. <br/>
                      <i>(Notitie voor adviseur: benadruk nog extra naar de klant: welke producten de klant
                        behoudt.)</i>
                    </label>
                  </p>

                  <p>
                    <input
                      id="transferServiceReason3"
                      name="transferServiceReason"
                      type="radio"
                      value="3"
                      onChange={this.updateQuotationProperty}
                      checked={currentQuotation.transferServiceReason === 3}
                    />
                    <label htmlFor='transferServiceReason3'
                           className={(this.hasInvalidTransferReason() ? 'text-danger' : '')}>
                      De klant stapt over van een andere provider naar KPN. <br/>
                      <i>(Notitie voor adviseur: benadruk nog extra naar de klant: hierdoor bent u zelf verantwoordelijk
                        voor
                        het opheffen van eventuele producten waar u geen gebruik meer van wenst te maken.)</i>
                    </label>
                  </p>

                  <p>
                    <input
                      id="transferServiceReason4"
                      name="transferServiceReason"
                      type="radio"
                      value="4"
                      onChange={this.updateQuotationProperty}
                      checked={currentQuotation.transferServiceReason === 4}
                    />
                    <label htmlFor='transferServiceReason4'
                           className={(this.hasInvalidTransferReason() ? 'text-danger' : '')}>
                      Klant is starter en heeft geen oude producten/diensten.
                    </label>
                  </p>
                </div>
              </div>
              }
            </div>
            }

            {extraDigitalSigningGroups.map((customGroup, index) =>
              <div className={'Forms'}>
                <DigitalSigningCustomGroups
                  digitalCustomGroup={customGroup}
                  digitalCustomFields={allDigitalSigningFields}
                  key={index}
                  excludesDigitalSigning={true}
                />
              </div>
            )}

            {oldQuotationDialog &&
            <GeneralDialog
              dialogState={oldQuotationDialog}
              headerText={`LET OP: Deze offerte is ouder dan ${validity}`}
              rightButtonAction={this.closeOldQuotationDialog}
              rightButtonText={"Ok, ik heb dit begrepen"}
            />
            }

            {dataRetrievalDialog &&
            <GeneralDialog
              dialogState={dataRetrievalDialog}
              closeHandler={this.closeDataRetrievalDialog}
              headerText={'Een momentje geduld aub….'}
              dialogBodyContent={(
                <DefaultDialogBody
                  text='We zijn nog druk bezig alle bestaande orders op te halen. Je kunt gewoon door met je offerte, maar alle bestaande producten zijn misschien nog niet geladen. De melding bovenin je scherm en op de offerte overzicht verdwijnt wanneer dit klaar staat.'/>
              )}
              rightButtonAction={this.closeDataRetrievalDialog}
              rightButtonText={'Begrepen'}
            />
            }

            {technicalContactDialog &&
            <GeneralDialog
              dialogState={technicalContactDialog}
              closeHandler={this.closeTechnicalContactDialog}
              headerText={'Technische contactgegevens ontbreken!'}
              dialogBodyContent={(
                <DefaultDialogBody
                  text='Vul alle velden in voor de technische contactpersoon terwijl u de optie heeft om deze te gebruiken voor het geselecteerde bestelproces.'/>
              )}
              rightButtonAction={this.closeTechnicalContactDialog}
              rightButtonText={'Ok, ik heb dit begrepen'}
            />
            }

            {presetDiscountChangeDialog &&
            <GeneralDialog
              dialogState={presetDiscountChangeDialog}
              closeHandler={this.closePresetDiscountChangeDialog}
              headerText={'Let op: Prijsverschil aangetroffen'}
              dialogBodyContent={(
                <DefaultDialogBody text='Er heeft intussen een wijziging plaatsgevonden in de Netwerkacties van Odido'/>
              )}
              leftButtonAction={this.closePresetDiscountChangeDialog}
              leftButtonText={'Niet wijzigen'}
              leftButtonLeft={true}
              rightButtonAction={this.refreshPresetDiscounts}
              rightButtonText={'Korting verversen'}
            />
            }

            <ChangeAddressDialog
              dialogOpened={changeAddressDialog}
              onSave={this.saveLocationDetails}
              selectedCustomer={selectedCustomer}
              updateCompanyInfo={this.updateCompanyInfo}
              fieldNotFilled={fieldNotFilled}
            />

            {changeKvkDialog &&
            <GeneralDialog
              dialogState={changeKvkDialog}
              headerText="KvK nummer verplicht"
              dialogBodyContentRaw={(
                <DialogBody>
                  <div style={{marginBottom: '3rem'}}>
                    Om een offerte te kunnen maken, dient het kvk nummer te worden opgegeven. Voer hieronder de kvk
                    nummer in van {selectedCustomer.name}
                  </div>

                  <form>
                    <div className={'row'}>
                      <div className="input-field col s12 m12">
                        <input
                          type="text"
                          required
                          ref={(ref) => (this.kvk = ref)}
                          name="kvk"
                          value={selectedCustomer.kvk || ''}
                          onChange={this.updateCompanyInfo}
                          minLength="8"
                          maxLength="8"
                          pattern="[0-9]{8}"
                          placeholder="8 cijfers"
                          className="validate company"
                        />

                        <label htmlFor="kvk">KvK nummer *</label>
                      </div>
                    </div>
                  </form>
                </DialogBody>
              )}
              rightButtonAction={this.closeChangeKvkDialog}
              rightButtonText="Opslaan"
            />
            }

            <GeneralDialog
              dialogState={existingQuotationsDialog}
              closeHandler={this.closeExistingQuotationsDialog}
              headerText={currentQuotation.type === quotationType.TYPE_VERLENGEN ?
                'Let op: Offertes gevonden met dezelfde IRMA ID' :
                'Let op: Offertes gevonden met dezelfde klantnaam'
              }
              dialogBodyContent={(
                <DefaultDialogBody
                  text={existingQuotationsDialog ? this.createExistingQuotationMessage(currentQuotation) : ''}/>
              )}
            />

            <GeneralDialog
              dialogState={existingOpportunitiesDialog}
              style={{overflowY: 'visible'}}
              headerText="Opportunities"
              dialogBodyContent={(
                <Input
                  s={12}
                  type="select"
                  name="selectedCwOpportunity"
                  label="Synchroniseer met deze ConnectWise opportunity"
                  onChange={this.onCwOpportunitySelect}
                  value={selectedCwOpportunity ? selectedCwOpportunity.id : null}
                  className="validate"
                  multiple={false}
                  required
                >
                  <option value={null} key={'no-selection'}>Kies opportunity</option>
                  {existingOpportunities.map((opportunity) => <option key={opportunity.name}
                                                                      value={opportunity.id}>{opportunity.name}</option>)}
                </Input>
              )}
              rightButtonAction={() => this.setSelectedQuotationForOpportunity()}
              rightButtonText={'Opslaan'}
            />

            {connectWiseBlockingDialog && (
              <GeneralDialog
                dialogState={connectWiseBlockingDialog}
                closeHandler={this.closeConnectWiseBlockingDialog}
                headerText={'Geen opportunities beschikbaar'}
                dialogBodyContent={(
                  <DefaultDialogBody text='
                  Op dit moment zijn er geen opportunities beschikbaar voor de geselecteerde klant.
                  Maak de opportunity aan in ConnectWise en probeer het opnieuw.'
                  />
                )}
                rightButtonAction={this.closeConnectWiseBlockingDialog}
                rightButtonText={'Begrepen'}
              />
            )}

            <GeneralDialog
              dialogState={presetDiscountsInvalid}
              closeHandler={this.closePresetDiscountsDialog}
              headerText={'Netwerkactiekorting fout'}
              dialogBodyContent={(
                <DefaultDialogBody
                  text='U heeft niet genoeg mandaat om de netwerkactiekortingen toe te passen. Neem contact op met sales Support om dit om te lossen.'/>
              )}
              rightButtonAction={this.closePresetDiscountsDialog}
              rightButtonText={'Begrepen'}
            />

            <GeneralDialog
              width={1000}
              dialogState={showPriceChanged && !oldQuotationDialog}
              closeHandler={this.hidePriceChanged}
              headerText='In uw offerte bevindt zich een product met een verouderde prijs of naam.'
              dialogBodyContent={(
                <DefaultDialogBody text='Wilt u de gehele offerte bijwerken met de meest recente prijzen?'/>
              )}
              leftButtonAction={this.hidePriceChanged}
              leftButtonText={'Nee, laat offerte ongewijzigd'}
              leftButtonLeft={true}
              centerButtonAction={this.showProductsChangesList}
              centerButtonText={'Nee, toon de verschillen'}
              centerButtonLeft={true}
              rightButtonAction={this.refreshQuotationPrices}
              rightButtonText={'Ja, werk alle producten bij'}
            />

            <GeneralDialog
              width={1000}
              dialogState={enableSalesValueModal.enabled}
              closeHandler={this.closeSalesValueModal}
              headerText='Syncen naar SalesForce niet mogelijk.'
              dialogBodyContent={(
                <DefaultDialogBody text={enableSalesValueModal.bodyMessage}/>
              )}
              rightButtonText={'Begrepen'}
              rightButtonAction={this.closeSalesValueModal}
            />

            <GeneralDialog
              dialogState={showChanges}
              closeHandler={this.closeChangesDialog}
              headerText="Changes made:"
              dialogBodyContent={(
                <table>
                  <thead>
                  <tr>
                    <th>Naam</th>
                  </tr>
                  </thead>

                  <tbody>
                  {
                    currentQuotation.changes && currentQuotation.changes.length && currentQuotation.changes.map(el => {
                      return (
                        <tr>
                          <td>{el.product}</td>
                        </tr>)
                    })
                  }
                  </tbody>
                </table>
              )}
              rightButtonAction={this.refreshQuotationPrices}
              rightButtonText={'Ja, werk alle producten bij'}
              leftButtonAction={this.closeChangesDialog}
              leftButtonText={'Ok'}
              leftButtonLeft={true}
            />

            <GeneralDialog
              dialogState={showSecondaryIRMASearch}
              style={{overflowY: 'visible'}}
              headerText="IRMA Klant"
              dialogBodyContent={(
                <Input
                  s={12}
                  type="select"
                  name="selectedIrmaCustomer"
                  label="Selecteer de IRMA-klant"
                  onChange={this.onSelectedIrmaCustomer}
                  value={selectedIrmaCustomer}
                  className="validate"
                  multiple={false}
                  required
                >
                  <option value={null} key={'no-selection'}>Kies klant</option>
                  {secondaryIrmaResults && secondaryIrmaResults.map((irmaCustomer) => {
                    return (
                      <option key={irmaCustomer.name} value={irmaCustomer.apiId}>
                        {irmaCustomer.name} - {irmaCustomer.apiId}
                      </option>
                    );
                  })}
                </Input>
              )}
              rightButtonAction={() => this.onSaveIrmaCustomer()}
              rightButtonText={'Opslaan'}
            />


            <GeneralDialog
              dialogState={isInactiveCustomProductsDialog}
              headerText="Inactieve partner producten gevonden"
              headerIcon="edit_location"
              closeHandler={this.closeInactiveCustomProductsDialog}
              dialogBodyContent={
                this.createInactiveCustomProductForm()
              }
            />
          </fieldset>
        }
      </form>
    );
  }
}

const mapStateToProps = ({customer, quotation, authentication, locations, organizations, digitalSigningGroups, digitalSigningFields, warnings}) => {
  return {
    customer,
    quotation,
    authentication,
    locations,
    organizations,
    digitalSigningGroups,
    digitalSigningFields,
    warnings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    infoActions: bindActionCreators(infoActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    accessActions: bindActionCreators(accessActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    leadActions: bindActionCreators(leadActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
    digitalSigningGroupsActions: bindActionCreators(digitalSigningGroupsActions, dispatch),
    digitalSigningFieldActions: bindActionCreators(digitalSigningFieldActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfoStep);
