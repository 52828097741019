import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  orderingTables: [],
  order: {},
  selectedTelephoneNumber: null,
  selectedVamoProduct: null,
  selectedHostedVoiceNumber: {},
  selectedAccessOptions: {},
  selectedProducts: [],
  orderContainVoipFlexiblePortingNumbers: false,
  hostedVoiceNumberErrors: [],
  accessOptionsErrors: [],
  orderLoading: false,
  orderMessage: '',
  statusSaveMatchingData: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getOrderingState:
      return {
        ...state,
        orderingTables: action.payload.orderingTables,
        order: action.payload.order,
        orderMessage: ''
      };
    case actionTypes.sendOrderLoading:
      return {
        ...state,
        orderLoading: action.loading
      };
    case actionTypes.updateOrderingStateMessage:
      return {
        ...state,
        orderMessage: action.message
      };
    case actionTypes.updateOrderingState:
      return {
        ...state,
        order: action.payload,
        orderLoading: false,
        orderMessage: '',
      };
    case actionTypes.getSelectedProducts:
      return {
        ...state,
        selectedProducts: action.payload
      };
    case actionTypes.setOrderContainVoipFlexiblePortingNumbers:
      return {
        ...state,
        orderContainVoipFlexiblePortingNumbers: action.payload
      };
    case actionTypes.getOrderingTelephoneNumber:
      return {
        ...state,
        selectedTelephoneNumber: action.payload
      };
    case actionTypes.getVamoProductSelection:
      return {
        ...state,
        selectedVamoProduct: action.payload
      };
    case actionTypes.saveVamoProductData:
      return {
        ...state,
        selectedVamoProduct: action.payload
      }
    case actionTypes.saveOrderingTelephoneNumber:
      return {
        ...state,
        selectedTelephoneNumber: action.payload.telephoneNumber,
      };
    case actionTypes.getOrderingHostedVoiceNumber:
      return {
        ...state,
        selectedHostedVoiceNumber: action.response,
        hostedVoiceNumberErrors: [],
      };
    case actionTypes.saveOrderingHostedVoiceNumber:
      return {
        ...state,
        selectedHostedVoiceNumber: action.hostedVoiceNumber,
        hostedVoiceNumberErrors: [],
      };
    case actionTypes.saveOrderingHostedVoiceNumberFail:
      return {
        ...state,
        hostedVoiceNumberErrors: action.message
      };
    case actionTypes.getOrderingAccessOptions:
      return {
        ...state,
        selectedAccessOptions: action.response,
        accessOptionsErrors: []
      };
    case actionTypes.saveOrderingAccessOptionsFail:
      return {
        ...state,
        accessOptionsErrors: action.message
      };
    case actionTypes.saveMatchingAddons:
      return {
        ...state,
        statusSaveMatchingData: action.payload
      };
    default:
      return state;
  }
}
